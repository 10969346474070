export default [
  //    - Services list
  {
    path: "/services/generic",
    name: "foravila-global-services-list",
    component: () =>
      import("@/views/global-services/list/GlobalServicesList.vue"),
    meta: {
      pageTitle: "Llistat de serveis genèrics",
      breadcrumb: [{ text: "Serveis" }, { text: "Genèrics", active: true }],
    },
  },
];
