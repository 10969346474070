import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    promotions: [],
    promotion: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    promotions(state) {
      return state.promotions;
    },
    promoCodes(state, getters) {
      return getters.promotions.filter(
        (promotion) => promotion.category === "PROMO_CODE"
      );
    },
    discounts(state, getters) {
      return getters.promotions.filter(
        (promotion) => promotion.category !== "PROMO_CODE"
      );
    },
    promotion(state) {
      return state.promotion;
    },
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_PROMOTIONS(state, payload) {
      state.promotions = payload;
    },
    SET_PROMOTION(state, payload) {
      state.promotion = payload;
    },
    ADD_PROMOTION(state, promotion) {
      state.promotions.push(promotion);
    },
    UPDATE_PROMOTION(state, promotion) {
      state.promotion = promotion;
      const promotionIndex = state.promotions.findIndex(
        (d) => d.uuid === promotion.uuid
      );
      if (promotionIndex !== -1) {
        state.promotions.splice(promotionIndex, 1, promotion);
      }
    },
    REMOVE_PROMOTION(state, promotionUuid) {
      state.promotion = null;
      const promotionIndex = state.promotions.findIndex(
        (d) => d.uuid === promotionUuid
      );
      if (promotionIndex !== -1) {
        state.promotions.splice(promotionIndex, 1);
      }
    },
  },
  actions: {
    fetchPromotions({ commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/discounts?";
      if ("pagination" in payload) url += `pagination=${payload.pagination}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            commit("SET_PROMOTIONS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: log error to Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchPromotion({ commit }, promotionUuid) {
      commit("SET_LOADING", true);

      return new Promise((resolve, reject) => {
        axios
          .get(`/discounts/${promotionUuid}`)
          .then((response) => {
            commit("SET_PROMOTION", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    addPromotion({ commit }, promotion) {
      return new Promise((resolve, reject) => {
        axios
          .post("/discounts", promotion)
          .then((response) => {
            if (response.status === 201) {
              commit("SET_PROMOTION", response.data);
              commit("ADD_PROMOTION", response.data);
              resolve(response.data);
            } else {
              reject(new Error("Problem while adding the promotion."));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updatePromotion({ commit }, promotion) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/discounts/${promotion.uuid}`, promotion, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_PROMOTION", response.data);
              resolve(response.data);
            } else {
              reject(
                new Error("An error occurred while updating the promotion")
              );
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deletePromotion({ commit }, promotionUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/discounts/${promotionUuid}`)
          .then((response) => {
            commit("REMOVE_PROMOTION", promotionUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
