import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    providers: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    providers(state) {
      return state.providers;
    },
  },
  mutations: {
    SET_LOADING_PROVIDERS(state, payload) {
      state.loading = payload;
    },
    SET_PROVIDERS(state, payload) {
      state.providers = payload;
    },
    ADD_PROVIDER(state, provider) {
      state.providers.push(provider);
    },
    UPDATE_PROVIDER(state, provider) {
      const providerIndex = state.providers.findIndex(
        (p) => p.uuid === provider.uuid
      );
      state.providers.splice(providerIndex, 1, provider);
    },
    REMOVE_PROVIDER(state, providerUuid) {
      const providerIndex = state.providers.findIndex(
        (p) => p.uuid === providerUuid
      );
      state.providers.splice(providerIndex, 1);
    },
  },
  actions: {
    fetchProviders({ commit }, payload = { pagination: false, sort: "asc" }) {
      commit("SET_LOADING_PROVIDERS", true);

      let url = "/providers?";
      if ("pagination" in payload) url += `pagination=${payload.pagination}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            commit("SET_PROVIDERS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING_PROVIDERS", false));
      });
    },
    addProvider({ commit }, provider) {
      return new Promise((resolve, reject) => {
        axios
          .post("/providers", provider)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_PROVIDER", response.data);
              resolve(response);
            } else {
              reject(new Error("Problem while adding the provider."));
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateProvider({ commit }, provider) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/providers/${provider.uuid}`, provider, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_PROVIDER", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteProvider({ commit }, providerUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/providers/${providerUuid}`)
          .then((response) => {
            commit("REMOVE_PROVIDER", providerUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
