export default {
  namespaced: true,
  state: {
    loading: false,
    agencyToClientSelectedPendingInvoiceLines: [],
    agencyToOwnerSelectedPendingInvoiceLines: [],
    ownerSettlementSelectedPendingInvoiceLines: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    agencyToClientSelectedPendingInvoiceLines(state) {
      return state.agencyToClientSelectedPendingInvoiceLines;
    },
    agencyToOwnerSelectedPendingInvoiceLines(state) {
      return state.agencyToOwnerSelectedPendingInvoiceLines;
    },
    ownerSettlementSelectedPendingInvoiceLines(state) {
      return state.ownerSettlementSelectedPendingInvoiceLines;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.agencyToClientSelectedPendingInvoiceLines = [];
      state.agencyToOwnerSelectedPendingInvoiceLines = [];
      state.ownerSettlementSelectedPendingInvoiceLines = [];
    },
    ADD_SELECTED_AGENCY_TO_CLIENT_INVOICE_LINES(state, invoiceLines) {
      state.agencyToClientSelectedPendingInvoiceLines = invoiceLines;
    },
    ADD_SELECTED_AGENCY_TO_OWNER_INVOICE_LINES(state, invoiceLines) {
      state.agencyToOwnerSelectedPendingInvoiceLines = invoiceLines;
    },
    ADD_SELECTED_OWNER_SETTLEMENT_INVOICE_LINES(state, invoiceLines) {
      state.ownerSettlementSelectedPendingInvoiceLines = invoiceLines;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    saveAgencyToClientSelectedPendingInvoiceLines({ commit }, invoiceLines) {
      commit("ADD_SELECTED_AGENCY_TO_CLIENT_INVOICE_LINES", invoiceLines);
    },
    saveAgencyToOwnerSelectedPendingInvoiceLines({ commit }, invoiceLines) {
      commit("ADD_SELECTED_AGENCY_TO_OWNER_INVOICE_LINES", invoiceLines);
    },
    saveOwnerSettlementSelectedPendingInvoiceLines({ commit }, invoiceLines) {
      commit("ADD_SELECTED_OWNER_SETTLEMENT_INVOICE_LINES", invoiceLines);
    },
  },
};
