import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loadingAccommodation: false,
    accommodation: null,
    accommodationPictures: [],
    accommodationVideos: [],
    accommodationAmenities: [],
  },
  getters: {
    loadingAccommodation(state) {
      return state.loadingAccommodation;
    },
    accommodation(state) {
      return state.accommodation;
    },
    accommodationPictures(state) {
      return state.accommodationPictures;
    },
    accommodationVideos(state) {
      return state.accommodationVideos;
    },
    accommodationAmenities(state) {
      return state.accommodationAmenities;
    },
  },
  mutations: {
    RESET(state) {
      state.loadingAccommodation = false;
      state.accommodation = null;
      state.accommodationPictures = [];
      state.accommodationVideos = [];
      state.accommodationAmenities = [];
    },
    SET_LOADING_ACCOMMODATION(state, payload) {
      state.loadingAccommodation = payload;
    },
    SET_ACCOMMODATION(state, payload) {
      state.accommodation = payload;
    },
    SET_ACCOMMODATION_LOCATION(state, payload) {
      if (state.accommodation) {
        state.accommodation.location = payload.location;
      }
    },
    SET_ACCOMMODATION_PICTURES(state, payload) {
      state.accommodationPictures = payload;
    },
    SORT_ACCOMMODATION_PICTURES(state) {
      state.accommodationPictures.sort((a, b) => {
        if (a.priority > b.priority) return 1;
        if (b.priority > a.priority) return -1;
        return 0;
      });
    },
    ADD_ACCOMMODATION_PICTURE(state, picture) {
      state.accommodationPictures.push(picture);
    },
    UPDATE_ACCOMMODATION_PICTURE(state, picture) {
      const pictureIndex = state.accommodationPictures.findIndex(
        (p) => p.uuid === picture.uuid
      );
      state.accommodationPictures.splice(pictureIndex, 1, picture);
    },
    REMOVE_ACCOMMODATION_PICTURE(state, pictureUuid) {
      const index = state.accommodationPictures.findIndex(
        (x) => x.uuid === pictureUuid
      );
      state.accommodationPictures.splice(index, 1);
    },
    ADD_ACCOMMODATION_DESCRIPTION(state, description) {
      if (!state.accommodation.descriptions)
        state.accommodation.descriptions = [];
      state.accommodation.descriptions.push(description);
    },
    UPDATE_ACCOMMODATION_DESCRIPTION(state, description) {
      const descriptionIndex = state.accommodation.descriptions.findIndex(
        (d) => d.uuid === description.uuid
      );
      state.accommodation.descriptions.splice(descriptionIndex, 1, description);
    },
    REMOVE_ACCOMMODATION_DESCRIPTION(state, descriptionUuid) {
      const descriptionIndex = state.accommodation.descriptions.findIndex(
        (d) => d.uuid === descriptionUuid
      );
      state.accommodation.descriptions.splice(descriptionIndex, 1);
    },
    SET_ACCOMMODATION_AMENITIES(state, payload) {
      state.accommodationAmenities = payload;
    },
    ADD_ACCOMMODATION_AMENITY(state, amenity) {
      state.accommodationAmenities.push(amenity);
    },
    UPDATE_ACCOMMODATION_AMENITY(state, amenity) {
      const amenityIndex = state.accommodationAmenities.findIndex(
        (a) => a.id === amenity.id
      );
      state.accommodationAmenities.splice(amenityIndex, 1, amenity);
    },
    REMOVE_ACCOMMODATION_AMENITY(state, amenityId) {
      const amenityIndex = state.accommodationAmenities.findIndex(
        (a) => a.id === amenityId
      );
      state.accommodationAmenities.splice(amenityIndex, 1);
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchAccommodation({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/real_estate_accommodations/${accommodationUuid}`)
          .then((response) => {
            commit("SET_ACCOMMODATION", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION", false));
      });
    },
    updateAccommodation({ commit }, accommodation) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `/real_estate_accommodations/${accommodation.uuid}`,
            accommodation,
            {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              commit("SET_ACCOMMODATION", response.data);
              commit(
                "realEstateAccommodations/UPDATE_ACCOMMODATION",
                response.data,
                { root: true }
              );
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    createAccommodation({ commit }, accommodation) {
      return new Promise((resolve, reject) => {
        axios
          .post("/real_estate_accommodations", accommodation)
          .then((response) => {
            if (response.status === 201) {
              commit(
                "realEstateAccommodations/ADD_ACCOMMODATION",
                response.data,
                { root: true }
              );
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    deleteAccommodation({ commit }, accommodationUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/real_estate_accommodations/${accommodationUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit(
                "realEstateAccommodations/REMOVE_ACCOMMODATION",
                accommodationUuid,
                { root: true }
              );
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error(
                "An error occurred while trying to delete de accommodation"
              )
            )
          );
      });
    },
    updateAccommodationLocation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        if (payload.location) {
          axios
            .patch(`/locations/${payload.location.uuid}`, payload.location, {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            })
            .then((response) => {
              if (response.status === 200) {
                commit("SET_ACCOMMODATION_LOCATION", {
                  location: response.data,
                });
                commit(
                  "realEstateAccommodations/UPDATE_ACCOMMODATION_LOCATION",
                  {
                    accommodation: payload.accommodation,
                    location: response.data,
                  },
                  { root: true }
                );
                resolve(response);
              } else {
                reject(response);
              }
            })
            .catch((error) => reject(error));
        }
      });
    },
    fetchAccommodationPictures({ commit }, accommodationUuid) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/real_estate_accommodations/${accommodationUuid}/pictures?pagination=false`
          )
          .then((response) => {
            commit("SET_ACCOMMODATION_PICTURES", response.data["hydra:member"]);
            commit("SORT_ACCOMMODATION_PICTURES");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addAccommodationPictures({ commit }, payload) {
      return new Promise((resolve, reject) => {
        if (payload.pictures.length > 0) {
          const successfulRequestsCount = 0;
          const promises = [];

          payload.pictures.forEach((picture) => {
            promises.push(
              axios
                .post("/real_estate_accommodation_pictures", {
                  accommodationUuid: payload.accommodationUuid,
                  pictures: [picture],
                })
                .then((response) => {
                  if (response.status === 201) {
                    response.data.createdPictures.forEach((createdPicture) => {
                      commit("ADD_ACCOMMODATION_PICTURE", createdPicture);
                    });
                  }
                })
            );
          });

          Promise.all(promises).then(() => {
            if (successfulRequestsCount === payload.pictures.length) {
              resolve({
                message: "All pictures successfully added",
              });
            } else {
              reject(
                new Error("Some pictures has been not added, please try again")
              );
            }
          });
        } else {
          reject(new Error("There must be at least one picture to add"));
        }
      });
    },
    updateAccommodationPicturesOrder({ commit }, pictures) {
      return new Promise((resolve, reject) => {
        if (pictures.length > 0) {
          const promises = [];

          let successfulRequestsCount = 0;

          pictures.forEach((picture, index) => {
            // TODO: update the cover picture on the real-estate-accommodations store

            promises.push(
              axios
                .patch(
                  `/pictures/${picture.uuid}`,
                  {
                    priority: index,
                    cover: index === 0,
                  },
                  {
                    headers: {
                      "Content-Type": "application/merge-patch+json",
                    },
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    commit("UPDATE_ACCOMMODATION_PICTURE", response.data);
                    successfulRequestsCount += 1;
                  }
                })
            );
          });

          Promise.all(promises)
            .then(() => {
              if (successfulRequestsCount === pictures.length) {
                commit("SORT_ACCOMMODATION_PICTURES");
                resolve();
              } else {
                reject(new Error("Not all the pictures have been updated"));
              }
            })
            .catch((error) => reject(error));
        } else {
          reject(new Error("There must be at least one picture to update"));
        }
      });
    },
    updateAccommodationPictureStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `/pictures/${payload.pictureUuid}`,
            { active: payload.status },
            {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_ACCOMMODATION_PICTURE", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to udpate de picture")
            )
          );
      });
    },
    deleteAccommodationPicture({ commit }, pictureUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/pictures/${pictureUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_ACCOMMODATION_PICTURE", pictureUuid);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete de picture")
            )
          );
      });
    },
    deleteAccommodationPictures({ commit }, picturesUuids) {
      return new Promise((resolve, reject) => {
        if (picturesUuids.length > 0) {
          const promises = [];
          let successfulRequestsCount = 0;
          picturesUuids.forEach((pictureUuid) => {
            promises.push(
              axios.delete(`/pictures/${pictureUuid}`).then((response) => {
                if (response.status === 204) {
                  successfulRequestsCount += 1;
                  commit("REMOVE_ACCOMMODATION_PICTURE", pictureUuid);
                }
              })
            );
          });

          Promise.all(promises).then(() => {
            if (successfulRequestsCount === picturesUuids.length) {
              resolve({
                message: "All pictures successfully removed",
              });
            } else {
              reject(
                new Error(
                  "Not all the selected pictures have been removed, try it again"
                )
              );
            }
          });
        } else {
          reject(new Error("There are no pictures to remove"));
        }
      });
    },
    updateAccommodationDescriptions({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        const promises = [];
        let successfulRequestsCount = 0;

        // Find the current descriptions that need to be deleted (they are not in the descriptions array received as a parameter)
        if (state.accommodation.descriptions?.length > 0) {
          state.accommodation.descriptions.forEach((currentDescription) => {
            const foundIndex = payload.descriptions.findIndex(
              (d) => d.uuid === currentDescription.uuid
            );
            if (foundIndex === -1) {
              promises.push(
                axios
                  .delete(
                    `/real_estate_accommodation_descriptions/${currentDescription.uuid}`
                  )
                  .then((response) => {
                    if (response.status === 204) {
                      commit(
                        "REMOVE_ACCOMMODATION_DESCRIPTION",
                        currentDescription.uuid
                      );
                      successfulRequestsCount += 1;
                    }
                  })
              );
            }
          });
        }

        // Update or create the rest of descriptions received as a parameter
        payload.descriptions.forEach((description) => {
          if (description.new) {
            promises.push(
              axios
                .post("/real_estate_accommodation_descriptions", {
                  uuid: description.uuid,
                  accommodation: payload.accommodation["@id"],
                  language: description.language,
                  title: description.title,
                  content: description.content,
                  scope: description.scope,
                })
                .then((response) => {
                  if (response.status === 201) {
                    commit("ADD_ACCOMMODATION_DESCRIPTION", response.data);
                    successfulRequestsCount += 1;
                  }
                })
            );
          } else {
            promises.push(
              axios
                .patch(
                  `/real_estate_accommodation_descriptions/${description.uuid}`,
                  {
                    language: description.language,
                    title: description.title,
                    content: description.content,
                    scope: description.scope,
                  },
                  {
                    headers: {
                      "Content-Type": "application/merge-patch+json",
                    },
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    commit("UPDATE_ACCOMMODATION_DESCRIPTION", response.data);
                    successfulRequestsCount += 1;
                  }
                })
            );
          }
        });

        Promise.all(promises)
          .then(() => {
            if (successfulRequestsCount >= payload.descriptions.length) {
              resolve();
            } else {
              reject(new Error("Not all the descriptions have been updated"));
            }
          })
          .catch((error) => reject(error));
      });
    },
    fetchAccommodationAmenities({ commit }, accommodationUuid) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/real_estate_accommodations/${accommodationUuid}/amenities?pagination=false`
          )
          .then((response) => {
            commit(
              "SET_ACCOMMODATION_AMENITIES",
              response.data["hydra:member"]
            );
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateAccommodationAmenities(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.accommodationUuid && payload.amenities) {
          axios
            .post("/update_real_estate_accommodation_amenities", {
              accommodationUuid: payload.accommodationUuid,
              amenities: payload.amenities,
            })
            .then(() => resolve())
            .catch(() =>
              reject(
                new Error(
                  "An error occurred while updating the accommodation amenities"
                )
              )
            );
        } else {
          reject(new Error("Missing mandatory parameters"));
        }
      });
    },
  },
};
