export default [
  //    - Promotions list
  {
    path: "/promotions",
    name: "foravila-promotions-list",
    component: () => import("@/views/promotions/list/PromotionsList.vue"),
    meta: {
      pageTitle: "Llistat de promocions",
      breadcrumb: [{ text: "Promocions" }, { text: "Llistat", active: true }],
    },
  },
  //    - New promotion
  {
    path: "/promotions/new",
    name: "foravila-new-promotion",
    component: () =>
      import("@/views/promotions/new-promotion/NewPromotion.vue"),
    meta: {
      pageTitle: "Nova promoció",
      breadcrumb: [{ text: "Promocions" }, { text: "Nova", active: true }],
    },
  },
  //    - Promotion view
  {
    path: "/promotions/:promotionUuid",
    name: "foravila-promotion-view",
    component: () => import("@/views/promotions/promotion/PromotionView.vue"),
    meta: {
      pageTitle: "Detalls de la promoció",
      breadcrumb: [{ text: "Promocions" }, { text: "Detalls", active: true }],
    },
  },
];
