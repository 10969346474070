// Agency info
export const AGENCY_CODE = "FORAVILA";
export const AGENCY_NAME = "Foravila Rentals";
export const AGENCY_LEGAL_NAME = "FORA-VILA SA POBLA S.L.";
export const AGENCY_LEGAL_ADDRESS =
  "Carrer Madò Buades 12, 07420 Sa Pobla, Illes Balears, Espanya";
export const AGENCY_CIF = "B57825994";
export const AGENCY_PHONE = "+34971541247";
export const AGENCY_CONTACT_NAME = "GABRIEL NADAL PORQUER CRESPÍ";
export const AGENCY_WEBSITE = "https://www.foravilarentals.com";
export const AGENCY_GUESTS_INTRANET_LOGIN =
  "https://guests.foravilarentals.com/login";
export const AGENCY_GUESTS_INTRANET_REGISTER =
  "https://guests.foravilarentals.com/online-checkin";
export const AGENCY_OWNERS_INTRANET_LOGIN =
  "https://propietaris.foravilarentals.com/login";
export const AGENCY_OWNERS_INTRANET_REGISTER =
  "https://propietaris.foravilarentals.com/register";
export const AGENCY_24H_ASSISTANCE_NUMBER = "+34 694 420 702";
export const OTAS_WITH_SELF_MANAGED_PAYMENTS = [
  "AIRBNB",
  "ATRAVEO",
  "LOCASUN",
  "TRIPADVISOR",
  "HOMETOGO",
  "HOLIDU",
];

// TPV config
export const TPV_BASE_LINK = "https://tpv.foravilarentals.com";

// Providers config
export const DEFAULT_SUPERMARKET_PROVIDER_ID_NUMBER = "B57782625";
export const DEFAULT_TRANSFER_PROVIDER_ID_NUMBER = "B81886855";

// Booking policies
export const DEFAULT_BOOKING_POLICY_UUID =
  "9f482e42-df7c-4716-ad17-86b01051568a";

// Accounting details
export const AGENCY_TO_OWNER_INVOICE_VAT_PERCENTAGE = 21;
export const AGENCY_TO_CLIENT_INVOICE_VAT_PERCENTAGE = 10;
export const AGENCY_INVOICE_NUMBERING_LENGTH = 13;
export const AGENCY_CLIENT_INVOICES_SERIES = "FC";
export const AGENCY_OWNER_INVOICES_SERIES = "FP";
