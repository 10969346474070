import Vue from "vue";
import VueRouter from "vue-router";
import { canNavigate } from "@/libs/acl/routeProtection";
import store from "@/store";
import useBasicAuth from "@/auth/basic/useBasicAuth";

// Foravila Routes
import security from "./items/security";
import home from "./items/home";
import accommodations from "./items/accommodations";
import realEstateAccommodations from "./items/real-estate-accommodations";
import realEstateClients from "./items/real-estate-clients";
import bookings from "./items/bookings";
import clients from "./items/clients";
import globalServices from "./items/global-services";
import transfers from "./items/transfers";
import owners from "./items/owners";
import providers from "./items/providers";
import contracts from "./items/contracts";
import bookingPolicies from "./items/booking-policies";
import promotions from "./items/promotions";
import services from "./items/services";
import rules from "./items/rules";
import cities from "./items/cities";
import incidences from "./items/incidences";
import places from "./items/places";
import settings from "./items/settings";
import administration from "./items/administration";
import accounting from "./items/accounting";
import tools from "./items/tools";
import whatsapp from "./items/whatsapp";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // Foravila routes
    ...security,
    ...home,
    ...accommodations,
    ...realEstateAccommodations,
    ...realEstateClients,
    ...bookings,
    ...incidences,
    ...clients,
    ...globalServices,
    ...transfers,
    ...owners,
    ...providers,
    ...contracts,
    ...bookingPolicies,
    ...promotions,
    ...services,
    ...rules,
    ...cities,
    ...places,
    ...administration,
    ...accounting,
    ...tools,
    ...whatsapp,
    ...settings,
    // Default/Error route
    {
      path: "*",
      redirect: "not-found",
    },
  ],
});

router.beforeEach((to, _, next) => {
  if (to?.meta?.mustBeLoggedOut) {
    // If the user must be logged out to access to the route,
    // log out and continue to the route
    useBasicAuth.logout();
    useBasicAuth.removeUser();
    next();
  } else {
    // In the rest of the routes, check if the user is logged in,
    // otherwhise redirect to the login page
    useBasicAuth
      .checkLoggedIn()
      .then((loggedIn) => {
        if (!loggedIn) {
          next({ name: "foravila-login" });
          return;
        }
        if (!canNavigate(to)) {
          next({ name: "not-authorized" });
          return;
        }
        next();
      })
      .catch(() => {
        next({ name: "foravila-login" });
      });
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove the splash screen
  store.dispatch("app/setAppLoading", false);
});

export default router;
