import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    user: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.user = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchUser({ commit }, userUuid) {
      if (!userUuid) return null;
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .get(`/users/${userUuid}`)
          .then((response) => {
            commit("SET_USER", response.data);
            resolve();
          })
          .catch((error) => {
            // TODO: log the error with Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    updateUser({ commit }, user) {
      if (!user || !user.uuid) return null;
      return new Promise((resolve, reject) => {
        axios
          .patch(`/users/${user.uuid}`, user, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_USER", response.data);
              localStorage.setItem(
                "foravilaAdminUserData",
                JSON.stringify(response.data)
              );
              resolve(response.data);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            // TODO: log the error with Sentry
            reject(error);
          });
      });
    },
    deleteUser({ commit }, userUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/${userUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("SET_USER", null);
              resolve();
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete the user")
            )
          );
      });
    },
  },
};
