export default [
  //    - BookingPolicies list
  {
    path: "/booking-policies",
    name: "foravila-booking-policies-list",
    component: () =>
      import("@/views/booking-policies/list/BookingPoliciesList.vue"),
    meta: {
      pageTitle: "Llistat de polítiques de reserva",
      breadcrumb: [{ text: "Polítiques" }, { text: "Llistat", active: true }],
    },
  },
];
