import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    promotion: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    promotion(state) {
      return state.promotion;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.promotion = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_PROMOTION(state, payload) {
      state.promotion = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchPromotion({ commit }, promotionUuid) {
      commit("SET_LOADING", true);

      return new Promise((resolve, reject) => {
        axios
          .get(`/discounts/${promotionUuid}`)
          .then((response) => {
            commit("SET_PROMOTION", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    addPromotion({ commit }, promotion) {
      return new Promise((resolve, reject) => {
        axios
          .post("/discounts", promotion)
          .then((response) => {
            if (response.status === 201) {
              commit("SET_PROMOTION", response.data);
              resolve(response.data);
            } else {
              reject(new Error("Problem while adding the promotion."));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updatePromotion({ commit }, promotion) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/discounts/${promotion.uuid}`, promotion, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_PROMOTION", response.data);
              resolve(response.data);
            } else {
              reject(
                new Error("An error occurred while updating the promotion")
              );
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deletePromotion({ commit }, promotionUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/discounts/${promotionUuid}`)
          .then((response) => {
            commit("SET_PROMOTION", null);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addAccommodations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `/discounts/${payload.promotionUuid}`,
            { accommodations: payload.accommodations },
            {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((response) => {
            commit("SET_PROMOTION", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
