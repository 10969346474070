import { $themeBreakpoints } from "@themeConfig";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    loading: false,
    pageTitle: null,
    pageSubtitle: null,
    loadingSearchResults: false,
    searchResults: {
      accommodations: {
        key: "name",
        data: [],
      },
      bookings: {
        key: "localizator",
        data: [],
      },
    },
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
    isMobile: (state, getters) =>
      ["xs", "sm"].includes(getters.currentBreakPoint),
    loading: (state) => state.loading,
    pageTitle: (state) => state.pageTitle,
    pageSubtitle: (state) => state.pageSubtitle,
    isDev: () => process.env.NODE_ENV === "development",
    loadingSearchResults: (state) => state.loadingSearchResults,
    searchResults: (state) => state.searchResults,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_LOADING(state, val) {
      state.loading = val;
    },
    SET_PAGE_TITLE(state, val) {
      state.pageTitle = val;
    },
    SET_PAGE_SUBTITLE(state, val) {
      state.pageSubtitle = val;
    },
    SET_SEARCH_RESULTS(state, val) {
      state.searchResults = val;
    },
    SET_LOADING_SEARCH_RESULTS(state, loading) {
      state.loadingSearchResults = loading;
    },
    SET_SEARCH_BOOKING_RESULTS(state, results) {
      state.searchResults.bookings.data = results;
    },
    SET_SEARCH_ACCOMMODATIONS_RESULTS(state, results) {
      state.searchResults.accommodations.data = results;
    },
  },
  actions: {
    setAppLoading(context, loading) {
      const appLoading = document.getElementById("loading-bg");
      if (!appLoading) return;
      if (loading) appLoading.style.display = "block";
      else appLoading.style.display = "none";
    },
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
    setPageTitle({ commit }, title) {
      commit("SET_PAGE_TITLE", title);
    },
    setPageSubtitle({ commit }, subtitle) {
      commit("SET_PAGE_SUBTITLE", subtitle);
    },
    fetchSearchResults({ commit, dispatch }, searchQuery) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING_SEARCH_RESULTS", true);
        const promises = [];

        promises.push(
          dispatch(
            "bookings/fetchFilteredBookings",
            {
              pagination: true,
              itemsPerPage: 20,
              searchText: searchQuery || "",
            },
            {
              root: true,
            }
          ).then((bookings) => {
            commit(
              "SET_SEARCH_BOOKING_RESULTS",
              bookings
                .sort((a, b) => {
                  if (new Date(a.date) > new Date(b.date)) return -1;
                  if (new Date(b.date) > new Date(a.date)) return 1;
                  return 0;
                })
                .map((booking) => ({
                  localizator: booking.localizator || null,
                  client: booking.client?.fullName || null,
                  accommodation: booking.accommodation?.name || null,
                  checkin: formatDateStringToDate(booking.checkin),
                  checkout: formatDateStringToDate(booking.checkout),
                  source: booking.source,
                  cancelled: booking.cancelled,
                  route: {
                    name: "foravila-booking-view",
                    params: { localizator: booking.localizator },
                  },
                }))
            );
          })
        );

        promises.push(
          dispatch(
            "accommodations/fetchAccommodations",
            {
              pagination: true,
              name: searchQuery || "",
            },
            {
              root: true,
            }
          ).then((response) => {
            const accommodations = response.data["hydra:member"];
            commit(
              "SET_SEARCH_ACCOMMODATIONS_RESULTS",
              accommodations
                .filter((accommodation) => !!accommodation.active)
                .sort((a, b) => {
                  if (a.name > b.name) return 1;
                  if (b.name > a.name) return -1;
                  return 0;
                })
                .map((accommodation) => ({
                  name: accommodation.name || null,
                  city: accommodation.location?.city || null,
                  image: accommodation.coverPicture?.url,
                  route: {
                    name: "foravila-accommodation-view",
                    params: {
                      accommodationUuid: accommodation.uuid,
                    },
                  },
                }))
            );
          })
        );

        Promise.all(promises)
          .then(() => resolve())
          .catch((error) => reject(error))
          .finally(() => commit("SET_LOADING_SEARCH_RESULTS", false));
      });
    },
  },
};
