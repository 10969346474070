export default [
  //    - Contracts list
  {
    path: "/contracts",
    name: "foravila-contracts-list",
    component: () => import("@/views/contracts/list/ContractsList.vue"),
    meta: {
      pageTitle: "Llistat de contractes",
      breadcrumb: [{ text: "Contractes" }, { text: "Llistat", active: true }],
    },
  },
  //    - New contract
  {
    path: "/contracts/new",
    name: "foravila-new-contract",
    component: () => import("@/views/contracts/new-contract/AddContract.vue"),
    meta: {
      pageTitle: "Nou contracte",
      breadcrumb: [{ text: "Contractes" }, { text: "Nou", active: true }],
    },
  },
  //    - Contract view
  {
    path: "/contracts/:contractUuid",
    name: "foravila-contract-view",
    component: () => import("@/views/contracts/contract/ContractView.vue"),
    meta: {
      pageTitle: "Detalls del contracte",
      breadcrumb: [{ text: "Contractes" }, { text: "Contracte", active: true }],
    },
  },
];
