import axios from "@/axios";
// eslint-disable-next-line no-unused-vars
import { formatDateYmd } from "@/utils/methods";

export default {
  namespaced: true,
  state: {
    loading: false,
    datesLock: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    datesLock(state) {
      return state.datesLock;
    },
    days(state) {
      if (
        !state.datesLock ||
        !state.datesLock.startDate ||
        !state.datesLock.endDate
      )
        return null;

      const dt1 = new Date(state.datesLock.startDate);
      const dt2 = new Date(state.datesLock.endDate);
      return (
        Math.floor(
          (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
            Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
            (1000 * 60 * 60 * 24)
        ) + 1
      );
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.datesLock = null;
    },
    SET_LOADING_DATES_LOCK(state, payload) {
      state.loading = payload;
    },
    SET_DATES_LOCK(state, payload) {
      state.datesLock = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchDatesLock({ commit }, datesLockUuid) {
      commit("SET_LOADING_DATES_LOCK", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/dates_locks/${datesLockUuid}`)
          .then((response) => {
            commit("SET_DATES_LOCK", response.data);
            // The promise returns the dates lock data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_DATES_LOCK", false));
      });
    },
    removeDatesLock({ commit }, datesLockUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/dates_locks/${datesLockUuid}`)
          .then((response) => {
            if (response.status === 204) commit("SET_DATES_LOCK", null);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
