export default [
  //    - Providers list
  {
    path: "/tools/tpv-generator",
    name: "foravila-tools-tpv-generator",
    component: () => import("@/views/tools/TPVGenerator.vue"),
    meta: {
      pageTitle: "Generador d'enllaços TPV",
      breadcrumb: [{ text: "Eines" }, { text: "Generador TPV", active: true }],
    },
  },
];
