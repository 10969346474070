export default [
  //    - Providers list
  {
    path: "/providers",
    name: "foravila-providers-list",
    component: () => import("@/views/providers/list/ProvidersList.vue"),
    meta: {
      pageTitle: "Llistat de proveïdors",
      breadcrumb: [{ text: "Proveïdors" }, { text: "Llistat", active: true }],
    },
  },
  //    - New provider
  {
    path: "/providers/new",
    name: "foravila-new-provider",
    component: () => import("@/views/providers/provider/NewProvider.vue"),
    meta: {
      pageTitle: "Nou proveïdor",
      breadcrumb: [{ text: "Proveïdors" }, { text: "Nou", active: true }],
    },
  },
  //    - Provider view
  {
    path: "/providers/:providerUuid",
    name: "foravila-provider-view",
    component: () => import("@/views/providers/provider/ProviderView.vue"),
  },
];
