export default [
  //    - Accommodations list
  {
    path: "/accommodations",
    name: "foravila-accommodations-list",
    component: () =>
      import("@/views/accommodations/list/AccommodationsList.vue"),
    meta: {
      pageTitle: "Llistat d'allotjaments",
      breadcrumb: [{ text: "Allotjaments" }, { text: "Llistat", active: true }],
    },
  },
  //    - Add accommodation
  {
    path: "/accommodations/add",
    name: "foravila-add-accommodation",
    component: () =>
      import("@/views/accommodations/new-accommodation/AddAccommodation.vue"),
    meta: {
      pageTitle: "Afegeix un allotjament",
      breadcrumb: [{ text: "Allotjaments" }, { text: "Afegeix", active: true }],
    },
  },
  //    - New accommodation
  {
    path: "/accommodations/new",
    name: "foravila-new-accommodation",
    component: () =>
      import("@/views/accommodations/new-accommodation/NewAccommodation.vue"),
    meta: {
      pageTitle: "Nou allotjament",
      breadcrumb: [{ text: "Allotjaments" }, { text: "Nou", active: true }],
    },
  },
  //    - Import accommodation
  {
    path: "/accommodations/import",
    name: "foravila-import-accommodation",
    component: () =>
      import(
        "@/views/accommodations/new-accommodation/ImportAccommodation.vue"
      ),
    meta: {
      pageTitle: "Importa un allotjament",
      breadcrumb: [{ text: "Allotjaments" }, { text: "Importa", active: true }],
    },
  },
  //    - Accommodation view
  {
    path: "/accommodations/:accommodationUuid",
    name: "foravila-accommodation-view",
    component: () =>
      import("@/views/accommodations/accommodation/AccommodationView.vue"),
    meta: {
      pageTitle: "Detalls de l'allotjament",
      breadcrumb: [
        { text: "Allotjaments" },
        { text: "Allotjament", active: true },
      ],
    },
  },
  //    - Empty accommodations list
  {
    path: "/empty-accommodations",
    name: "foravila-accommodations-empty-list",
    component: () =>
      import("@/views/accommodations/empty-list/EmptyAccommodationsList.vue"),
    meta: {
      pageTitle: "Llistat d'allotjaments buits",
      breadcrumb: [{ text: "Allotjaments" }, { text: "Buits", active: true }],
    },
  },
];
