export default [
  //    - Clients list
  {
    path: "/clients",
    name: "foravila-clients-list",
    component: () => import("@/views/clients/list/ClientsList.vue"),
    meta: {
      pageTitle: "Llistat de clients",
      breadcrumb: [{ text: "Clients" }, { text: "Llistat", active: true }],
    },
  },
  //    - New client
  {
    path: "/clients/new",
    name: "foravila-new-client",
    component: () => import("@/views/clients/new-client/NewClient.vue"),
    meta: {
      pageTitle: "Nou client",
      breadcrumb: [{ text: "Clients" }, { text: "Nou", active: true }],
    },
  },
  //    - Client view
  {
    path: "/clients/:clientUuid",
    name: "foravila-client-view",
    component: () => import("@/views/clients/client/ClientView.vue"),
    meta: {
      pageTitle: "Detalls del client",
      breadcrumb: [{ text: "Clients" }, { text: "Client", active: true }],
    },
  },
];
