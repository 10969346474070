import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    invoice: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    invoice(state) {
      return state.invoice;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.invoice = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_INVOICE(state, payload) {
      state.invoice = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchInvoice({ commit }, invoiceUuid) {
      return new Promise((resolve, reject) => {
        if (!invoiceUuid) reject(new Error("No invoiceUuid"));

        commit("SET_LOADING", true);
        axios
          .get(`/invoices/${invoiceUuid}`)
          .then((response) => {
            commit("SET_INVOICE", response.data);
            // The promise returns the invoice data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
  },
};
