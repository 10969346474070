import axios from "@/axios";
import { dateDiff } from "@/utils/methods";

export default {
  namespaced: true,
  state: {
    loading: false,
    bookingsByBookingDate: [],
    bookingsByCheckinDate: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    bookingsAreLoaded(state) {
      return (
        !!state.bookingsByBookingDate.length &&
        !!state.bookingsByCheckinDate.length
      );
    },
    bookingsByBookingDate(state) {
      return state.bookingsByBookingDate;
    },
    bookingsByCheckinDate(state) {
      return state.bookingsByCheckinDate;
    },
    billingSummaryByBookingDate(state) {
      if (!state.bookingsByBookingDate.length) return [];

      const summary = [];

      state.bookingsByBookingDate.forEach((booking) => {
        const bookingYear = parseInt(booking.date.substring(0, 4));
        const bookingMonth = parseInt(booking.date.substring(5, 7));

        const yearIndex = summary.findIndex(
          (yearItem) => yearItem.year === bookingYear
        );

        if (yearIndex !== -1) {
          // year already exists in array

          // check if the months already exists in the existing year
          const monthIndex = summary[yearIndex].months.findIndex(
            (monthItem) => monthItem.month === bookingMonth
          );

          if (monthIndex !== -1) {
            // month already exists in year array, add the booking price
            summary[yearIndex].months[monthIndex].pvp += booking.pvpPrice || 0;
            summary[yearIndex].months[monthIndex].profit +=
              booking.profitPrice || 0;
          } else {
            // month doesn't exist in year array, add it and assign the current booking price
            summary[yearIndex].months.push({
              month: bookingMonth,
              pvp: booking.pvpPrice || 0,
              profit: booking.profitPrice || 0,
            });
          }
        } else {
          // year doesn't exist in array

          summary.push({
            year: bookingYear,
            months: [],
          });

          for (let i = 1; i <= 12; i++) {
            summary[summary.length - 1].months.push({
              month: i,
              pvp: bookingMonth === i ? booking.pvpPrice || 0 : 0,
              profit: bookingMonth === i ? booking.profitPrice || 0 : 0,
            });
          }
        }
      });

      return summary;
    },
    billingSummaryByCheckinDate(state) {
      if (!state.bookingsByCheckinDate.length) return [];

      const summary = [];

      state.bookingsByCheckinDate.forEach((booking) => {
        const checkinYear = parseInt(booking.checkin.substring(0, 4));
        const checkinMonth = parseInt(booking.checkin.substring(5, 7));

        const yearIndex = summary.findIndex(
          (summaryItem) => summaryItem.year === checkinYear
        );

        if (yearIndex !== -1) {
          // year already exists in array, check if the month exists in the year
          const monthIndex = summary[yearIndex].months.findIndex(
            (monthItem) => monthItem.month === checkinMonth
          );

          if (monthIndex !== -1) {
            // month already exists in year array
            summary[yearIndex].months[monthIndex].pvp += booking.pvpPrice || 0;
            summary[yearIndex].months[monthIndex].profit +=
              booking.profitPrice || 0;
          } else {
            // month doesn't exist in year array
            summary[yearIndex].months.push({
              month: checkinMonth,
              pvp: booking.pvpPrice || 0,
              profit: booking.profitPrice || 0,
            });
          }
        } else {
          // year doesn't exist in array, add it
          summary.push({
            year: checkinYear,
            months: [],
          });

          // Init the array of months of the new year that has been added to the summary
          const recentlyPushedYear = summary[summary.length - 1];
          for (let i = 1; i <= 12; i++) {
            recentlyPushedYear.months.push({
              month: i,
              pvp: checkinMonth === i ? booking.pvpPrice || 0 : 0,
              profit: checkinMonth === i ? booking.profitPrice || 0 : 0,
            });
          }
        }
      });

      return summary;
    },
    bookingAdvanceTimeByBookingDate(state, getters) {
      if (!getters.bookingsByBookingDate.length) {
        return null;
      }

      // Initialize an object to store total advance time and count for each year and month
      const result = {};

      getters.bookingsByBookingDate.forEach((booking) => {
        const bookingDate = new Date(booking.date.slice(0, 10));
        const checkinDate = new Date(booking.checkin.slice(0, 10));
        const advanceTime = dateDiff(bookingDate, checkinDate);

        const year = bookingDate.getFullYear();
        const month = bookingDate.getMonth() + 1; // Months are 0-based

        if (!result[year]) {
          result[year] = {
            1: { totalAdvanceTime: 0, count: 0 },
            2: { totalAdvanceTime: 0, count: 0 },
            3: { totalAdvanceTime: 0, count: 0 },
            4: { totalAdvanceTime: 0, count: 0 },
            5: { totalAdvanceTime: 0, count: 0 },
            6: { totalAdvanceTime: 0, count: 0 },
            7: { totalAdvanceTime: 0, count: 0 },
            8: { totalAdvanceTime: 0, count: 0 },
            9: { totalAdvanceTime: 0, count: 0 },
            10: { totalAdvanceTime: 0, count: 0 },
            11: { totalAdvanceTime: 0, count: 0 },
            12: { totalAdvanceTime: 0, count: 0 },
          };
        }

        result[year][month].totalAdvanceTime += advanceTime;
        result[year][month].count++;
      });

      // Calculate average time for each year and month
      for (const year in result) {
        for (const month in result[year]) {
          const count = result[year][month]?.count || 0;
          const totalAdvanceTime = result[year][month]?.totalAdvanceTime || 0;
          const avgAdvanceTime = count ? totalAdvanceTime / count : 0;
          result[year][month] = {
            bookingsCount: count,
            averageTime: Math.round(avgAdvanceTime),
          };
        }
      }

      return result;
    },
    bookingAdvanceTimeByCheckinDate(state, getters) {
      if (!getters.bookingsByCheckinDate.length) {
        return null;
      }

      // Initialize an object to store total advance time and count for each year and month
      const result = {};

      getters.bookingsByCheckinDate.forEach((booking) => {
        const bookingDate = new Date(booking.date.slice(0, 10));
        const checkinDate = new Date(booking.checkin.slice(0, 10));
        const advanceTime = dateDiff(bookingDate, checkinDate);

        const year = checkinDate.getFullYear();
        const month = checkinDate.getMonth() + 1; // Months are 0-based

        if (!result[year]) {
          result[year] = {
            1: { totalAdvanceTime: 0, count: 0 },
            2: { totalAdvanceTime: 0, count: 0 },
            3: { totalAdvanceTime: 0, count: 0 },
            4: { totalAdvanceTime: 0, count: 0 },
            5: { totalAdvanceTime: 0, count: 0 },
            6: { totalAdvanceTime: 0, count: 0 },
            7: { totalAdvanceTime: 0, count: 0 },
            8: { totalAdvanceTime: 0, count: 0 },
            9: { totalAdvanceTime: 0, count: 0 },
            10: { totalAdvanceTime: 0, count: 0 },
            11: { totalAdvanceTime: 0, count: 0 },
            12: { totalAdvanceTime: 0, count: 0 },
          };
        }

        result[year][month].totalAdvanceTime += advanceTime;
        result[year][month].count++;
      });

      // Calculate average time for each year and month
      for (const year in result) {
        for (const month in result[year]) {
          const count = result[year][month]?.count || 0;
          const totalAdvanceTime = result[year][month]?.totalAdvanceTime || 0;
          const avgAdvanceTime = count ? totalAdvanceTime / count : 0;
          result[year][month] = {
            bookingsCount: count,
            averageTime: Math.round(avgAdvanceTime),
          };
        }
      }

      return result;
    },
    directBookingsPercentageByBookingDate(state, getters) {
      if (!getters.bookingsByBookingDate.length) {
        return null;
      }

      // Initialize an object to store direct bookings count and total bookings count for each year and month
      const result = {};

      getters.bookingsByBookingDate.forEach((booking) => {
        const bookingDate = new Date(booking.date.slice(0, 10));
        const year = bookingDate.getFullYear();
        const month = bookingDate.getMonth() + 1; // Months are 0-based

        if (!result[year]) {
          result[year] = {
            1: { directBookingsCount: 0, totalBookingsCount: 0 },
            2: { directBookingsCount: 0, totalBookingsCount: 0 },
            3: { directBookingsCount: 0, totalBookingsCount: 0 },
            4: { directBookingsCount: 0, totalBookingsCount: 0 },
            5: { directBookingsCount: 0, totalBookingsCount: 0 },
            6: { directBookingsCount: 0, totalBookingsCount: 0 },
            7: { directBookingsCount: 0, totalBookingsCount: 0 },
            8: { directBookingsCount: 0, totalBookingsCount: 0 },
            9: { directBookingsCount: 0, totalBookingsCount: 0 },
            10: { directBookingsCount: 0, totalBookingsCount: 0 },
            11: { directBookingsCount: 0, totalBookingsCount: 0 },
            12: { directBookingsCount: 0, totalBookingsCount: 0 },
          };
        }

        result[year][month].totalBookingsCount++;
        if (booking.source === "FORAVILA") {
          result[year][month].directBookingsCount++;
        }
      });

      // Calculate direct bookings % for each year and month
      for (const year in result) {
        for (const month in result[year]) {
          const totalBookingsCount =
            result[year][month]?.totalBookingsCount || 0;
          const directBookingsCount =
            result[year][month]?.directBookingsCount || 0;
          result[year][month] = {
            totalBookingsCount,
            directBookingsCount,
            directBookingsPercentage:
              totalBookingsCount > 0
                ? parseFloat(
                    ((directBookingsCount / totalBookingsCount) * 100).toFixed(
                      2
                    )
                  )
                : 0,
          };
        }
      }

      return result;
    },
    directBookingsPercentageByCheckinDate(state, getters) {
      if (!getters.bookingsByCheckinDate.length) {
        return null;
      }

      // Initialize an object to store direct bookings count and total bookings count for each year and month
      const result = {};

      getters.bookingsByCheckinDate.forEach((booking) => {
        const checkinDate = new Date(booking.checkin.slice(0, 10));
        const year = checkinDate.getFullYear();
        const month = checkinDate.getMonth() + 1; // Months are 0-based

        if (!result[year]) {
          result[year] = {
            1: { directBookingsCount: 0, totalBookingsCount: 0 },
            2: { directBookingsCount: 0, totalBookingsCount: 0 },
            3: { directBookingsCount: 0, totalBookingsCount: 0 },
            4: { directBookingsCount: 0, totalBookingsCount: 0 },
            5: { directBookingsCount: 0, totalBookingsCount: 0 },
            6: { directBookingsCount: 0, totalBookingsCount: 0 },
            7: { directBookingsCount: 0, totalBookingsCount: 0 },
            8: { directBookingsCount: 0, totalBookingsCount: 0 },
            9: { directBookingsCount: 0, totalBookingsCount: 0 },
            10: { directBookingsCount: 0, totalBookingsCount: 0 },
            11: { directBookingsCount: 0, totalBookingsCount: 0 },
            12: { directBookingsCount: 0, totalBookingsCount: 0 },
          };
        }

        result[year][month].totalBookingsCount++;
        if (booking.source === "FORAVILA") {
          result[year][month].directBookingsCount++;
        }
      });

      // Calculate direct bookings % for each year and month
      for (const year in result) {
        for (const month in result[year]) {
          const totalBookingsCount =
            result[year][month]?.totalBookingsCount || 0;
          const directBookingsCount =
            result[year][month]?.directBookingsCount || 0;
          result[year][month] = {
            totalBookingsCount,
            directBookingsCount,
            directBookingsPercentage:
              totalBookingsCount > 0
                ? parseFloat(
                    ((directBookingsCount / totalBookingsCount) * 100).toFixed(
                      2
                    )
                  )
                : 0,
          };
        }
      }

      return result;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.bookings = [];
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_BOOKINGS_BY_BOOKING_DATE(state, payload) {
      state.bookingsByBookingDate = payload;
    },
    SET_BOOKINGS_BY_CHECKIN_DATE(state, payload) {
      state.bookingsByCheckinDate = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchBillingSummaryByBookingDate({ commit }) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        const currentYear = new Date().getFullYear();
        const twoYearsAgo = currentYear - 2;

        const startDate = `${twoYearsAgo}-01-01`;
        const endDate = `${currentYear}-12-31`;

        axios
          .get(
            `/bookings?pagination=false&fictitious=false&groups[]=homestats&status[]=CONFIRMED&status[]=COMPLETED&date[after]=${startDate}&date[before]=${endDate}`
          )
          .then((response) => {
            commit(
              "SET_BOOKINGS_BY_BOOKING_DATE",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() => {
            reject(
              new Error("fetchBillingSummaryByBookingDate axios request error")
            );
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchBillingSummaryByCheckinDate({ commit }) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        const currentYear = new Date().getFullYear();
        const twoYearsAgo = currentYear - 2;
        const nextYear = currentYear + 1;

        const startDate = `${twoYearsAgo}-01-01`;
        const endDate = `${nextYear}-12-31`;

        axios
          .get(
            `/bookings?pagination=false&fictitious=false&groups[]=homestats&status[]=CONFIRMED&status[]=COMPLETED&checkin[after]=${startDate}&checkin[before]=${endDate}`
          )
          .then((response) => {
            commit(
              "SET_BOOKINGS_BY_CHECKIN_DATE",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() => {
            reject(
              new Error("fetchBillingSummaryByCheckinDate axios request error")
            );
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
  },
};
