import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    invoices: [],
    invoiceNumberings: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    invoices(state) {
      return state.invoices;
    },
    invoiceNumberings(state) {
      return state.invoiceNumberings;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.invoices = [];
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_INVOICES(state, payload) {
      state.invoices = payload;
    },
    ADD_INVOICE(state, invoice) {
      state.invoices.push(invoice);
    },
    ADD_INVOICE_LINE(state, invoiceLine) {
      const foundInvoiceIndex = state.invoices.findIndex(
        (invoice) => invoice.uuid === invoiceLine.invoice?.uuid
      );
      if (foundInvoiceIndex === -1) return;

      const updatedInvoice = state.invoices[foundInvoiceIndex];
      if (!updatedInvoice) return;

      if (updatedInvoice.invoiceLines?.length) {
        updatedInvoice.invoiceLines.push(invoiceLine);
      } else {
        updatedInvoice.invoiceLines = [invoiceLine];
      }

      state.invoices.splice(foundInvoiceIndex, 1, updatedInvoice);
    },
    SET_INVOICE_NUMBERINGS(state, payload) {
      state.invoiceNumberings = payload;
    },
    ADD_INVOICE_NUMBERING(state, invoiceNumbering) {
      state.invoiceNumberings.push(invoiceNumbering);
    },
    UPDATE_INVOICE_NUMBERING(state, invoiceNumbering) {
      const invoiceNumberingIndex = state.invoiceNumberings.findIndex(
        (invNumb) => invNumb.uuid === invoiceNumbering.uuid
      );
      state.invoiceNumberings.splice(
        invoiceNumberingIndex,
        1,
        invoiceNumbering
      );
    },
    REMOVE_INVOICE_NUMBERING(state, invoiceNumberingUuid) {
      const invoiceNumberingIndex = state.invoiceNumberings.findIndex(
        (invNumb) => invNumb.uuid === invoiceNumberingUuid
      );
      state.invoiceNumberings.splice(invoiceNumberingIndex, 1);
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchInvoices({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .get(`/invoices`)
          .then((response) => {
            commit("SET_INVOICES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    addInvoice({ commit }, invoice) {
      return new Promise((resolve, reject) => {
        axios
          .post("/invoices", invoice)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_INVOICE", response.data);
              resolve(response);
            } else {
              reject(new Error("Problem while adding the invoice."));
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    fetchInvoiceNumberings({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .get(`/invoice_numberings`)
          .then((response) => {
            commit("SET_INVOICE_NUMBERINGS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchInvoiceNumberingBySeries(context, series) {
      return new Promise((resolve, reject) => {
        if (!series) reject(new Error("No series"));
        axios
          .get(`/invoice_numberings?series=${series}`)
          .then((response) => {
            if (
              response.status === 200 &&
              response.data["hydra:member"]?.length
            ) {
              resolve(response.data["hydra:member"][0]);
            } else {
              // TODO: log the error with Sentry
              reject(new Error("Axios get invoice_numberings error"));
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          });
      });
    },
    addInvoiceNumbering({ commit }, invoiceNumbering) {
      return new Promise((resolve, reject) => {
        axios
          .post("/invoice_numberings", invoiceNumbering)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_INVOICE_NUMBERING", response.data);
              resolve(response);
            } else {
              reject(new Error("Problem while adding the invoiceNumbering."));
              // TODO: Log error in Sentry
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateInvoiceNumbering({ commit }, invoiceNumbering) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `/invoice_numberings/${invoiceNumbering.uuid}`,
            invoiceNumbering,
            {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_INVOICE_NUMBERING", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
              // TODO: Log error in Sentry
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteInvoiceNumbering({ commit }, invoiceNumberingUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/invoice_numberings/${invoiceNumberingUuid}`)
          .then((response) => {
            // TODO: Check the status code
            commit("REMOVE_INVOICE_NUMBERING", invoiceNumberingUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
  },
};
