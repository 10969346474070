export default [
  //    - Clients list
  {
    path: "/real-estate/clients",
    name: "foravila-real-estate-clients-list",
    component: () =>
      import("@/views/real-estate-clients/list/RealEstateClientsList.vue"),
    meta: {
      pageTitle: "Llistat de clients d'immobiliària",
      breadcrumb: [{ text: "Clients" }, { text: "Llistat", active: true }],
    },
  },
  //    - New client
  {
    path: "/real-estate/clients/new",
    name: "foravila-new-real-estate-client",
    component: () =>
      import(
        "@/views/real-estate-clients/new-real-estate-client/NewRealEstateClient.vue"
      ),
    meta: {
      pageTitle: "Nou client d'immobiliària",
      breadcrumb: [{ text: "Clients" }, { text: "Nou", active: true }],
    },
  },
  //    - Client view
  {
    path: "/real-estate/clients/:clientUuid",
    name: "foravila-real-estate-client-view",
    component: () =>
      import(
        "@/views/real-estate-clients/real-estate-client/RealEstateClientView.vue"
      ),
    meta: {
      pageTitle: "Detalls del client d'immobiliària",
      breadcrumb: [{ text: "Clients" }, { text: "Client", active: true }],
    },
  },
];
