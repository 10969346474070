import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    clients: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    clients(state) {
      return state.clients;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.clients = [];
    },
    SET_LOADING_CLIENTS(state, payload) {
      state.loading = payload;
    },
    SET_CLIENTS(state, payload) {
      state.clients = payload;
    },
    SORT_CLIENTS_BY_NAME(state, order) {
      if (!order) return;
      switch (order.toUpperCase()) {
        case "DESC":
          state.clients.sort((a, b) => {
            if (a.fullName > b.fullName) return -1;
            if (b.fullName > a.fullName) return 1;
            return 0;
          });
          break;
        case "ASC":
        default:
          state.clients.sort((a, b) => {
            if (a.fullName > b.fullName) return 1;
            if (b.fullName > a.fullName) return -1;
            return 0;
          });
      }
    },
    ADD_CLIENT(state, client) {
      if (!client) return;
      state.clients.push(client);
    },
    UPDATE_CLIENT(state, client) {
      if (!state.clients.length || !client) return;
      const clientIndex = state.clients.findIndex(
        (c) => c.uuid === client.uuid
      );
      if (clientIndex === -1) return;
      state.clients.splice(clientIndex, 1, client);
    },
    REMOVE_CLIENT(state, clientUuid) {
      if (!clientUuid) return;
      const clientIndex = state.clients.findIndex((c) => c.uuid === clientUuid);
      if (clientIndex === -1) return;
      state.clients.splice(clientIndex, 1);
    },
    UPDATE_CLIENT_LOCATION(state, payload) {
      if (!state.clients.length) return;
      const clientIndex = state.clients.findIndex(
        (c) => c.uuid === payload.client.uuid
      );
      if (clientIndex === -1) return;
      state.clients[clientIndex].location = payload.location;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchClients({ commit }, payload = {}) {
      commit("SET_LOADING_CLIENTS", true);

      let url = "/real_estate_clients?";
      if ("pagination" in payload) url += `pagination=${payload.pagination}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_CLIENTS", response.data["hydra:member"]);
              if (payload.sort && typeof payload.sort === "string") {
                commit("SORT_CLIENTS_BY_NAME", payload.sort);
              }
              resolve(response);
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the clients",
                })
              );
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING_CLIENTS", false));
      });
    },
  },
};
