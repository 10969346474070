export default [
  //    - Bookings list
  {
    path: "/bookings",
    name: "foravila-bookings-list",
    component: () => import("@/views/bookings/list/BookingsList.vue"),
    meta: {
      pageTitle: "Llistat de reserves",
      breadcrumb: [{ text: "Reserves" }, { text: "Llistat", active: true }],
    },
  },
  //    - Bookings calendar
  {
    path: "/bookings/calendar",
    name: "foravila-bookings-calendar",
    component: () => import("@/views/bookings/calendar/BookingsCalendar.vue"),
    meta: {
      pageTitle: "Calendari de reserves",
      breadcrumb: [{ text: "Reserves" }, { text: "Calendari", active: true }],
    },
  },
  //    - Bookings planning
  {
    path: "/bookings/planning",
    name: "foravila-bookings-planning",
    component: () => import("@/views/bookings/planning/BookingsPlanning.vue"),
    meta: {
      pageTitle: "Planning de reserves",
      breadcrumb: [{ text: "Reserves" }, { text: "Planning", active: true }],
    },
  },
  //    - Cleanings planning
  {
    path: "/bookings/cleanings",
    name: "foravila-cleanings-planning",
    component: () => import("@/views/bookings/cleanings/CleaningsPlanning.vue"),
    meta: {
      pageTitle: "Planning de neteges",
      breadcrumb: [{ text: "Reserves" }, { text: "Netejes", active: true }],
    },
  },
  //    - Security deposits
  {
    path: "/bookings/security-deposits",
    name: "foravila-bookings-security-deposits",
    component: () =>
      import("@/views/bookings/security-deposits/SecurityDeposits.vue"),
    meta: {
      pageTitle: "Control de fiances",
      breadcrumb: [{ text: "Reserves" }, { text: "Fiances", active: true }],
    },
  },
  //    - Final payments
  {
    path: "/bookings/final-payments",
    name: "foravila-bookings-final-payments",
    component: () =>
      import("@/views/bookings/final-payments/FinalPayments.vue"),
    meta: {
      pageTitle: "Control de pagaments finals",
      breadcrumb: [
        { text: "Reserves" },
        { text: "Pagaments finals", active: true },
      ],
    },
  },
  //    - Fictitious
  {
    path: "/bookings/fictitious",
    name: "foravila-bookings-fictitious",
    component: () =>
      import("@/views/bookings/fictitious/FictitiousBookings.vue"),
    meta: {
      pageTitle: "Control de reserves fictícies",
      breadcrumb: [{ text: "Reserves" }, { text: "Fictícies", active: true }],
    },
  },
  //    - Add booking
  {
    path: "/bookings/add",
    name: "foravila-add-booking",
    component: () => import("@/views/bookings/new-booking/AddBooking.vue"),
    meta: {
      pageTitle: "Afegeix una reserva",
      breadcrumb: [{ text: "Reserves" }, { text: "Afegeix", active: true }],
    },
  },
  //    - New booking
  {
    path: "/bookings/new",
    name: "foravila-new-booking",
    component: () => import("@/views/bookings/new-booking/NewBooking.vue"),
    meta: {
      pageTitle: "Nova reserva",
      breadcrumb: [
        { text: "Reserves" },
        { text: "Afegeix" },
        { text: "Nova", active: true },
      ],
    },
  },
  //    - Import booking
  {
    path: "/bookings/import",
    name: "foravila-import-booking",
    component: () => import("@/views/bookings/new-booking/ImportBooking.vue"),
    meta: {
      pageTitle: "Importa una reserva",
      breadcrumb: [
        { text: "Reserves" },
        { text: "Afegeix" },
        { text: "Importa", active: true },
      ],
    },
  },
  //    - Import bookings
  {
    path: "/bookings/import-multiple",
    name: "foravila-import-bookings",
    component: () => import("@/views/bookings/new-booking/ImportBookings.vue"),
    meta: {
      pageTitle: "Importa múltiples reserves",
      breadcrumb: [
        { text: "Reserves" },
        { text: "Afegeix" },
        { text: "Importa múltiples", active: true },
      ],
    },
  },
  //    - Booking view (uuid)
  {
    path: "/bookings/:localizator",
    name: "foravila-booking-view",
    component: () => import("@/views/bookings/booking/BookingView.vue"),
    meta: {
      pageTitle: "Detalls de la reserva",
      breadcrumb: [{ text: "Reserves" }, { text: "Reserva", active: true }],
    },
  },
];
