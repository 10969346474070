import axios from "@/axios";
import { formatDateYmd } from "@/utils/methods";

export default {
  namespaced: true,
  state: {
    loading: false,
    contract: null,
    loadingServices: false,
    services: [],
    loadingProviders: false,
    providers: [],
    loadingOwnerRates: false,
    ownerRates: [],
    loadingBookings: false,
    bookings: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    contract(state) {
      return state.contract;
    },
    loadingServices(state) {
      return state.loadingServices;
    },
    services(state) {
      return state.services;
    },
    loadingProviders(state) {
      return state.loadingProviders;
    },
    providers(state) {
      return state.providers;
    },
    loadingOwnerRates(state) {
      return state.loadingOwnerRates;
    },
    ownerRates(state) {
      return state.ownerRates;
    },
    loadingBookings(state) {
      return state.loadingBookings;
    },
    bookings(state) {
      return state.bookings;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.contract = null;
      state.loadingServices = false;
      state.services = [];
      state.loadingProviders = false;
      state.providers = [];
      state.loadingOwnerRates = false;
      state.ownerRates = [];
      state.loadingBookings = false;
      state.bookings = [];
    },
    SET_LOADING_CONTRACT(state, payload) {
      state.loading = payload;
    },
    SET_CONTRACT(state, payload) {
      state.contract = payload;
    },
    SET_LOADING_CONTRACT_SERVICES(state, payload) {
      state.loadingServices = payload;
    },
    SET_CONTRACT_SERVICES(state, payload) {
      state.services = payload;
    },
    ADD_CONTRACT_SERVICE(state, service) {
      state.services.push(service);
    },
    UPDATE_CONTRACT_SERVICE(state, updatedService) {
      const updatedServices = state.services.map((service) =>
        service.uuid === updatedService.uuid ? updatedService : service
      );
      state.services = updatedServices;
    },
    REMOVE_CONTRACT_SERVICE(state, serviceUuid) {
      state.services = state.services.filter(
        (service) => service.uuid !== serviceUuid
      );
    },
    SET_LOADING_CONTRACT_PROVIDERS(state, payload) {
      state.loadingProviders = payload;
    },
    SET_CONTRACT_PROVIDERS(state, payload) {
      state.providers = payload;
    },
    ADD_CONTRACT_PROVIDER(state, provider) {
      state.providers.push(provider);
    },
    UPDATE_CONTRACT_PROVIDER(state, updatedProvider) {
      const updatedProviders = state.providers.map((provider) =>
        provider.uuid === updatedProvider.uuid ? updatedProvider : provider
      );
      state.providers = updatedProviders;
    },
    REMOVE_CONTRACT_PROVIDER(state, providerUuid) {
      state.providers = state.providers.filter(
        (provider) => provider.uuid !== providerUuid
      );
    },
    SET_LOADING_OWNER_RATES(state, payload) {
      state.loadingOwnerRates = payload;
    },
    SET_OWNER_RATES(state, payload) {
      state.ownerRates = payload;
    },
    SET_LOADING_BOOKINGS(state, payload) {
      state.loadingBookings = payload;
    },
    SET_BOOKINGS(state, payload) {
      state.bookings = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    async fetchContract({ commit }, contractUuid) {
      if (!contractUuid) {
        throw new Error("No contractUuid");
      }

      try {
        commit("SET_LOADING_CONTRACT", true);
        const response = await axios.get(`/contracts/${contractUuid}`);
        commit("SET_CONTRACT", response.data);
        return response.data;
        // eslint-disable-next-line no-useless-catch
      } catch (error) {
        // TODO: log the error with Sentry or other logging mechanism
        throw error;
      } finally {
        commit("SET_LOADING_CONTRACT", false);
      }
    },
    async createContract({ commit }, contract) {
      try {
        const response = await axios.post("/contracts", contract);

        if (response.status >= 200 && response.status < 300) {
          commit("SET_CONTRACT", response.data);
          commit("contracts/ADD_CONTRACT", response.data, { root: true });
          return response;
        } else {
          throw new Error(
            "The request was successful, but the received status was not in the expected 2xx range."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async updateContract({ commit }, contract) {
      try {
        const response = await axios.patch(
          `/contracts/${contract.uuid}`,
          contract,
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          }
        );

        if (response.status >= 200 && response.status < 300) {
          commit("SET_CONTRACT", response.data);
          commit("contracts/UPDATE_CONTRACT", response.data, { root: true });
          return response;
        } else {
          throw new Error(
            "The request was successful, but the received status was not in the expected 2xx range."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async deleteContract({ commit }, contractUuid) {
      try {
        const response = await axios.delete(`/contracts/${contractUuid}`);

        if (response.status === 204) {
          commit("SET_CONTRACT", null);
          return response;
        } else {
          throw new Error(
            "The request was successful, but the received status was not in the expected 204 (No Content) status code."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async addCoOwner(_, data) {
      try {
        const response = await axios.post("/add_contract_co_owner", data);

        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          throw new Error(
            "The request was successful, but the received status was not in the expected 2xx range."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async deleteCoOwner(_, data) {
      try {
        const response = await axios.post("/delete_contract_co_owner", data);

        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          throw new Error(
            "The request was successful, but the received status was not in the expected 2xx range."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async fetchServices({ commit }, contractUuid) {
      try {
        commit("SET_LOADING_CONTRACT_SERVICES", true);
        const response = await axios.get(
          `/contracts/${contractUuid}/services?pagination=false`
        );
        const contractServices = response.data["hydra:member"];
        commit("SET_CONTRACT_SERVICES", contractServices);
        return contractServices;
        // eslint-disable-next-line no-useless-catch
      } catch (error) {
        // TODO: Log error in Sentry or handle it gracefully
        throw error;
      } finally {
        commit("SET_LOADING_CONTRACT_SERVICES", false);
      }
    },
    async addService({ commit }, service) {
      try {
        const response = await axios.post("/contract_services", service);

        if (response.status >= 200 && response.status < 300) {
          commit("ADD_CONTRACT_SERVICE", response.data);
          return response;
        } else {
          throw new Error(
            "The request was successful, but the response code was not the expected 2xx range."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async updateService({ commit }, service) {
      try {
        const response = await axios.patch(
          `/contract_services/${service.uuid}`,
          service,
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          }
        );

        if (response.status >= 200 && response.status < 300) {
          commit("UPDATE_CONTRACT_SERVICE", response.data);
          return response;
        } else {
          throw new Error(
            "The request was successful, but the received status was not the expected 2xx range."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async deleteService({ commit }, serviceUuid) {
      try {
        const response = await axios.delete(
          `/contract_services/${serviceUuid}`
        );

        if (response.status >= 200 && response.status < 300) {
          commit("REMOVE_CONTRACT_SERVICE", serviceUuid);
          return response;
        } else {
          throw new Error(
            "The request was successful, but the received status was not the expected 2xx range."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async fetchProviders({ commit }, contractUuid) {
      try {
        commit("SET_LOADING_CONTRACT_PROVIDERS", true);
        const response = await axios.get(
          `/contracts/${contractUuid}/providers?pagination=false`
        );
        const contractProviders = response.data["hydra:member"];
        commit("SET_CONTRACT_PROVIDERS", contractProviders);
        return contractProviders;
        // eslint-disable-next-line no-useless-catch
      } catch (error) {
        // TODO: Log error in Sentry or handle it gracefully
        throw error;
      } finally {
        commit("SET_LOADING_CONTRACT_PROVIDERS", false);
      }
    },
    async addProvider({ commit }, provider) {
      try {
        const response = await axios.post("/contract_providers", provider);

        if (response.status >= 200 && response.status < 300) {
          commit("ADD_CONTRACT_PROVIDER", response.data);
          return response;
        } else {
          throw new Error(
            "The request was successful, but the response code was not the expected 2xx range."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async updateProvider({ commit }, provider) {
      try {
        const response = await axios.patch(
          `/contract_providers/${provider.uuid}`,
          provider,
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          }
        );

        if (response.status >= 200 && response.status < 300) {
          commit("UPDATE_CONTRACT_PROVIDER", response.data);
          return response;
        } else {
          throw new Error(
            "The request was successful, but the received status was not the expected 2xx range."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async deleteProvider({ commit }, providerUuid) {
      try {
        const response = await axios.delete(
          `/contract_providers/${providerUuid}`
        );

        if (response.status >= 200 && response.status < 300) {
          commit("REMOVE_CONTRACT_PROVIDER", providerUuid);
          return response;
        } else {
          throw new Error(
            "The request was successful, but the received status was not the expected 2xx range."
          );
        }
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(error.message);
      }
    },
    async fetchOwnerRates({ getters, commit }, contractUuid) {
      try {
        const { accommodation, startDate, endDate } = getters.contract || {};
        const startDateStr = startDate.slice(0, 10);
        const endDateStr = endDate.slice(0, 10);

        const accommodationUuid = accommodation?.uuid;

        commit("SET_LOADING_OWNER_RATES", true);
        const response = await axios.get(
          `/accommodations/${accommodationUuid}/rates?date[after]=${startDateStr}&date[before]=${endDateStr}&order[date]=asc&pagination=false`
        );
        const ownerRates = response.data["hydra:member"]
          .filter((accommodationRate) => accommodationRate.ownerPrice)
          .map((accommodationRate) => ({
            date: accommodationRate.date,
            price: accommodationRate.ownerPrice,
            bookingPolicy: accommodationRate.bookingPolicy || null,
          }));

        commit("SET_OWNER_RATES", ownerRates);
        // eslint-disable-next-line no-useless-catch
      } catch (error) {
        // TODO: Log error in Sentry or handle it gracefully
        throw error;
      } finally {
        commit("SET_LOADING_OWNER_RATES", false);
      }
    },
    async addOwnerRates(context, payload) {
      return new Promise((resolve, reject) => {
        const { accommodation, startDate, endDate, ownerPrice, bookingPolicy } =
          payload;

        if (
          accommodation &&
          startDate &&
          endDate &&
          ownerPrice &&
          bookingPolicy
        ) {
          axios
            .post("/accommodation_rates/owner_price_range", {
              accommodation,
              startDate: formatDateYmd(startDate),
              endDate: formatDateYmd(endDate),
              ownerPrice,
              bookingPolicy,
            })
            .then(() => resolve())
            .catch((error) => {
              reject(error);
              // TODO: Log error in Sentry
            });
        } else {
          reject(new Error("Missing mandatory parameters"));
        }
      });
    },
    async fetchBookings({ getters, commit }, contractUuid) {
      try {
        commit("SET_LOADING_BOOKINGS", true);

        const response = await axios.get(
          `/bookings?contract.uuid=${contractUuid}&pagination=false`
        );

        commit("SET_BOOKINGS", response.data["hydra:member"]);
        // eslint-disable-next-line no-useless-catch
      } catch (error) {
        // TODO: Log error in Sentry or handle it gracefully
        throw error;
      } finally {
        commit("SET_LOADING_BOOKINGS", false);
      }
    },
  },
};
