import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    contracts: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    contracts(state) {
      return state.contracts;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.contracts = [];
    },
    SET_LOADING_CONTRACTS(state, payload) {
      state.loading = payload;
    },
    SET_CONTRACTS(state, payload) {
      state.contracts = payload;
    },
    ADD_CONTRACT(state, contract) {
      if (!contract) return;
      state.contracts.push(contract);
    },
    UPDATE_CONTRACT(state, contract) {
      if (!state.contracts.length || !contract) return;
      const contractIndex = state.contracts.findIndex(
        (item) => item.uuid === contract.uuid
      );
      if (contractIndex === -1) return;
      state.contracts.splice(contractIndex, 1, contract);
    },
    REMOVE_CONTRACT(state, contractUuid) {
      if (!contractUuid) return;
      const contractIndex = state.contracts.findIndex(
        (item) => item.uuid === contractUuid
      );
      if (contractIndex === -1) return;
      state.contracts.splice(contractIndex, 1);
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchContracts({ commit }, payload = {}) {
      commit("SET_LOADING_CONTRACTS", true);

      let url = "/contracts?";
      if ("pagination" in payload) url += `pagination=${payload.pagination}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_CONTRACTS", response.data["hydra:member"]);
              resolve(response);
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the contracts",
                })
              );
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING_CONTRACTS", false));
      });
    },
  },
};
