export default [
  //    - Services list
  {
    path: "/services",
    name: "foravila-services-list",
    component: () => import("@/views/services/list/ServicesList.vue"),
    meta: {
      pageTitle: "Llistat de serveis",
      breadcrumb: [{ text: "Serveis" }, { text: "Llistat", active: true }],
    },
  },
  //    - New service
  {
    path: "/services/new",
    name: "foravila-new-service",
    component: () => import("@/views/services/service/NewService.vue"),
  },
  //    - Service view
  {
    path: "/services/:serviceUuid",
    name: "foravila-service-view",
    component: () => import("@/views/services/service/ServiceView.vue"),
  },
];
