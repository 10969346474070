import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    client: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    client(state) {
      return state.client;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.client = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CLIENT(state, payload) {
      state.client = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchClient({ commit }, clientUuid) {
      return new Promise((resolve, reject) => {
        if (!clientUuid) reject(new Error("No clientUuid"));

        commit("SET_LOADING", true);
        axios
          .get(`/real_estate_clients/${clientUuid}`)
          .then((response) => {
            commit("SET_CLIENT", response.data);
            // The promise returns the client data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    createClient({ commit }, client) {
      return new Promise((resolve, reject) => {
        axios
          .post("/real_estate_clients", client)
          .then((response) => {
            if (response.status === 201) {
              commit("SET_CLIENT", response.data);
              commit("realEstateClients/ADD_CLIENT", response.data, {
                root: true,
              });
              resolve(response.data);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateClient({ commit }, client) {
      if (!client || !client.uuid) return null;
      return new Promise((resolve, reject) => {
        axios
          .patch(`/real_estate_clients/${client.uuid}`, client, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_CLIENT", response.data);
              resolve(response.data);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            // TODO: log the error with Sentry
            reject(error);
          });
      });
    },
  },
};
