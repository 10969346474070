import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth from "./auth";
import billingProcess from "./billing-process";
import agencyInfo from "./agency-info";
import accommodation from "./accommodation";
import accommodations from "./accommodations";
import booking from "./booking";
import bookings from "./bookings";
import stats from "./stats";
import datesLock from "./datesLock";
import realEstateAccommodation from "./real-estate-accommodation";
import realEstateAccommodations from "./real-estate-accommodations";
import realEstateClient from "./real-estate-client";
import realEstateClients from "./real-estate-clients";
import clients from "./clients";
import client from "./client";
import clientPayments from "./client-payments";
import globalServices from "./global-services";
import transfers from "./transfers";
import owners from "./owners";
import owner from "./owner";
import bookingPolicies from "./booking-policies";
import promotion from "./promotion";
import promotions from "./promotions";
import services from "./services";
import rules from "./rules";
import providers from "./providers";
import contracts from "./contracts";
import contract from "./contract";
import newContract from "./new-contract";
import invoices from "./invoices";
import invoice from "./invoice";
import incidence from "./incidence";
import incidences from "./incidences";
import user from "./user";
import cities from "./cities";
import places from "./places";
import whatsapp from "./whatsapp";
import ledgerAccounts from "./ledger-accounts";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // Foravila Modules
    auth,
    agencyInfo,
    accommodation,
    accommodations,
    realEstateAccommodation,
    realEstateAccommodations,
    realEstateClient,
    realEstateClients,
    booking,
    bookings,
    billingProcess,
    datesLock,
    clients,
    client,
    clientPayments,
    globalServices,
    transfers,
    owners,
    owner,
    bookingPolicies,
    promotion,
    promotions,
    services,
    rules,
    providers,
    contracts,
    contract,
    newContract,
    invoices,
    invoice,
    incidence,
    incidences,
    stats,
    cities,
    places,
    whatsapp,
    ledgerAccounts,
    user,
    // Demo Modules
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
});
