import axios from "@/axios";
import { formatDateStringToDatabaseDate } from "@/utils/formatters";
import { formatDateYmd } from "@/utils/methods";

export default {
  namespaced: true,
  state: {
    loadingAccommodation: false,
    accommodation: null,
    loadingPictures: false,
    pictures: [],
    loadingVideos: false,
    videos: [],
    loadingBedrooms: false,
    bedrooms: [],
    loadingBathrooms: false,
    bathrooms: [],
    loadingAmenities: false,
    amenities: [],
    loadingAvailabilities: false,
    availabilities: [],
    loadingRates: false,
    rates: [],
    loadingDatesLocks: false,
    datesLocks: [],
    loadingBookings: false,
    bookings: [],
    loadingServices: false,
    services: [],
    loadingReviews: false,
    reviews: [],
    loadingContract: false,
    contract: null,
    loadingOwner: false,
    owner: null,
    loadingFaqs: false,
    faqs: [],
  },
  getters: {
    loadingAccommodation(state) {
      return state.loadingAccommodation;
    },
    accommodation(state) {
      return state.accommodation;
    },
    details(state) {
      return state.accommodation?.details || null;
    },
    loadingOwner(state) {
      return state.loadingAccommodation || state.loadingOwner;
    },
    owner(state) {
      if (state.owner) return state.owner;
      return state.accommodation?.currentOwner || null;
    },
    loadingPictures(state) {
      return state.loadingPictures;
    },
    pictures(state) {
      return state.pictures;
    },
    loadingVideos(state) {
      return state.loadingVideos;
    },
    videos(state) {
      return state.videos;
    },
    loadingPicturesOrVideos(state) {
      return state.loadingPictures || state.loadingVideos;
    },
    loadingBedrooms(state) {
      return state.loadingBedrooms;
    },
    bedrooms(state) {
      return state.bedrooms;
    },
    loadingBathrooms(state) {
      return state.loadingBathrooms;
    },
    bathrooms(state) {
      return state.bathrooms;
    },
    loadingBedroomsOrBathrooms(state) {
      return state.loadingBedrooms || state.loadingBathrooms;
    },
    loadingAmenities(state) {
      return state.loadingAmenities;
    },
    amenities(state) {
      return state.amenities;
    },
    loadingAvailabilities(state) {
      return state.loadingAvailabilities;
    },
    availabilities(state) {
      return state.availabilities;
    },
    loadingRates(state) {
      return state.loadingRates;
    },
    rates(state) {
      return state.rates;
    },
    loadingAvailabilitiesOrRates(state) {
      return state.loadingAvailabilities || state.loadingRates;
    },
    loadingDatesLocks(state) {
      return state.loadingDatesLocks;
    },
    datesLocks(state) {
      return state.datesLocks;
    },
    loadingBookings(state) {
      return state.loadingBookings;
    },
    bookings(state) {
      return state.bookings;
    },
    loadingServices(state) {
      return state.loadingServices;
    },
    services(state) {
      return state.services;
    },
    mandatoryServices(state, getters) {
      return getters.services.filter(
        (accommodationService) => accommodationService.mandatory
      );
    },
    optionalServices(state, getters) {
      return getters.services.filter(
        (accommodationService) => !accommodationService.mandatory
      );
    },
    loadingReviews(state) {
      return state.loadingReviews;
    },
    reviews(state) {
      return state.reviews;
    },
    loadingContract(state) {
      return state.loadingContract;
    },
    contract(state) {
      return state.contract;
    },
    loadingFaqs(state) {
      return state.loadingFaqs;
    },
    faqs(state) {
      return state.faqs;
    },
  },
  mutations: {
    RESET(state) {
      state.loadingAccommodation = false;
      state.accommodation = null;
      state.loadingPictures = false;
      state.pictures = [];
      state.loadingVideos = false;
      state.videos = [];
      state.loadingBedrooms = false;
      state.bedrooms = [];
      state.loadingBathrooms = false;
      state.bathrooms = [];
      state.loadingAmenities = false;
      state.amenities = [];
      state.loadingAvailabilities = false;
      state.availabilities = [];
      state.loadingRates = false;
      state.rates = [];
      state.loadingDatesLocks = false;
      state.datesLocks = [];
      state.loadingBookings = false;
      state.bookings = [];
      state.loadingServices = false;
      state.services = [];
      state.loadingReviews = false;
      state.reviews = [];
      state.loadingContract = false;
      state.contract = null;
      state.loadingOwner = false;
      state.owner = null;
      state.loadingFaqs = false;
      state.faqs = [];
    },
    SET_LOADING_ACCOMMODATION(state, payload) {
      state.loadingAccommodation = payload;
    },
    SET_ACCOMMODATION(state, payload) {
      state.accommodation = payload;
    },
    SET_ACCOMMODATION_LOCATION(state, payload) {
      if (state.accommodation) {
        state.accommodation.location = payload.location;
      }
    },
    SET_LOADING_ACCOMMODATION_PICTURES(state, payload) {
      state.loadingPictures = payload;
    },
    SET_ACCOMMODATION_PICTURES(state, payload) {
      state.pictures = payload;
    },
    SORT_ACCOMMODATION_PICTURES(state) {
      state.pictures.sort((a, b) => {
        if (a.priority > b.priority) return 1;
        if (b.priority > a.priority) return -1;
        return 0;
      });
    },
    ADD_ACCOMMODATION_PICTURE(state, picture) {
      state.pictures.push(picture);
    },
    UPDATE_ACCOMMODATION_PICTURE(state, picture) {
      const pictureIndex = state.pictures.findIndex(
        (p) => p.uuid === picture.uuid
      );
      state.pictures.splice(pictureIndex, 1, picture);
    },
    REMOVE_ACCOMMODATION_PICTURE(state, pictureUuid) {
      const index = state.pictures.findIndex((x) => x.uuid === pictureUuid);
      state.pictures.splice(index, 1);
    },
    ADD_ACCOMMODATION_DESCRIPTION(state, description) {
      if (!state.accommodation.descriptions)
        state.accommodation.descriptions = [];
      state.accommodation.descriptions.push(description);
    },
    UPDATE_ACCOMMODATION_DESCRIPTION(state, description) {
      const descriptionIndex = state.accommodation.descriptions.findIndex(
        (d) => d.uuid === description.uuid
      );
      state.accommodation.descriptions.splice(descriptionIndex, 1, description);
    },
    REMOVE_ACCOMMODATION_DESCRIPTION(state, descriptionUuid) {
      const descriptionIndex = state.accommodation.descriptions.findIndex(
        (d) => d.uuid === descriptionUuid
      );
      state.accommodation.descriptions.splice(descriptionIndex, 1);
    },
    SET_LOADING_ACCOMMODATION_BEDROOMS(state, payload) {
      state.loadingBedrooms = payload;
    },
    SET_ACCOMMODATION_BEDROOMS(state, payload) {
      state.bedrooms = payload;
    },
    ADD_ACCOMMODATION_BEDROOM(state, bedroom) {
      state.bedrooms.push(bedroom);
      state.accommodation.bedroomsNumber += 1;
    },
    UPDATE_ACCOMMODATION_BEDROOM(state, bedroom) {
      const bedroomIndex = state.bedrooms.findIndex(
        (b) => b.uuid === bedroom.uuid
      );
      state.bedrooms.splice(bedroomIndex, 1, bedroom);
    },
    REMOVE_ACCOMMODATION_BEDROOM(state, bedroomUuid) {
      const index = state.bedrooms.findIndex((x) => x.uuid === bedroomUuid);
      state.bedrooms.splice(index, 1);
      state.accommodation.bedroomsNumber -= 1;
    },
    REMOVE_ACCOMMODATION_BED(state, payload) {
      const bedroomIndex = state.bedrooms.findIndex(
        (bedroom) => bedroom.uuid === payload.bedroomUuid
      );
      const bedIndex = state.bedrooms[bedroomIndex].beds.findIndex(
        (bed) => bed.uuid === payload.bedUuid
      );
      state.bedrooms[bedroomIndex].beds.splice(bedIndex, 1);
    },
    SET_LOADING_ACCOMMODATION_BATHROOMS(state, payload) {
      state.loadingBathrooms = payload;
    },
    SET_ACCOMMODATION_BATHROOMS(state, payload) {
      state.bathrooms = payload;
    },
    ADD_ACCOMMODATION_BATHROOM(state, bathroom) {
      state.bathrooms.push(bathroom);
      state.accommodation.bathroomsNumber += 1;
    },
    UPDATE_ACCOMMODATION_BATHROOM(state, bathroom) {
      const bathroomIndex = state.bathrooms.findIndex(
        (b) => b.uuid === bathroom.uuid
      );
      state.bathrooms.splice(bathroomIndex, 1, bathroom);
    },
    REMOVE_ACCOMMODATION_BATHROOM(state, bathroomUuid) {
      const index = state.bathrooms.findIndex((x) => x.uuid === bathroomUuid);
      state.bathrooms.splice(index, 1);
      state.accommodation.bathroomsNumber -= 1;
    },
    SET_LOADING_ACCOMMODATION_AMENITIES(state, payload) {
      state.loadingAmenities = payload;
    },
    SET_ACCOMMODATION_AMENITIES(state, payload) {
      state.amenities = payload;
    },
    ADD_ACCOMMODATION_AMENITY(state, amenity) {
      state.amenities.push(amenity);
    },
    UPDATE_ACCOMMODATION_AMENITY(state, amenity) {
      const amenityIndex = state.amenities.findIndex(
        (a) => a.id === amenity.id
      );
      state.amenities.splice(amenityIndex, 1, amenity);
    },
    REMOVE_ACCOMMODATION_AMENITY(state, amenityId) {
      const amenityIndex = state.amenities.findIndex((a) => a.id === amenityId);
      state.amenities.splice(amenityIndex, 1);
    },
    SET_LOADING_ACCOMMODATION_AVAILABILITIES(state, payload) {
      state.loadingAvailabilities = payload;
    },
    SET_ACCOMMODATION_AVAILABILITIES(state, payload) {
      state.availabilities = payload;
    },
    ADD_ACCOMMODATION_AVAILABILITY(state, availability) {
      state.availabilities.push(availability);
      state.availabilities.sort((a, b) => {
        if (new Date(a.date) > new Date(b.date)) return 1;
        if (new Date(b.date) > new Date(a.date)) return -1;
        return 0;
      });
    },
    UPDATE_ACCOMMODATION_AVAILABILITY(state, availability) {
      const availabilityIndex = state.availabilities.findIndex(
        (a) => a.uuid === availability.uuid
      );
      state.availabilities.splice(availabilityIndex, 1, availability);
    },
    SET_LOADING_ACCOMMODATION_RATES(state, payload) {
      state.loadingRates = payload;
    },
    SET_ACCOMMODATION_RATES(state, payload) {
      state.rates = payload;
    },
    ADD_ACCOMMODATION_RATE(state, rate) {
      state.rates.push(rate);
    },
    UPDATE_ACCOMMODATION_RATE(state, rate) {
      const rateIndex = state.rates.findIndex((r) => r.uuid === rate.uuid);
      state.rates.splice(rateIndex, 1, rate);
    },
    SET_LOADING_ACCOMMODATION_DATES_LOCKS(state, payload) {
      state.loadingDatesLocks = payload;
    },
    SET_ACCOMMODATION_DATES_LOCKS(state, payload) {
      state.datesLocks = payload;
    },
    ADD_ACCOMMODATION_DATES_LOCK(state, datesLock) {
      state.datesLocks.push(datesLock);
    },
    UPDATE_ACCOMMODATION_DATES_LOCK(state, datesLock) {
      const datesLockIndex = state.datesLocks.findIndex(
        (dl) => dl.uuid === datesLock.uuid
      );
      state.datesLocks.splice(datesLockIndex, 1, datesLock);
    },
    REMOVE_ACCOMMODATION_DATES_LOCK(state, datesLockUuid) {
      const datesLockIndex = state.datesLocks.findIndex(
        (dl) => dl.uuid === datesLockUuid
      );
      state.datesLocks.splice(datesLockIndex, 1);
    },
    SET_LOADING_ACCOMMODATION_BOOKINGS(state, payload) {
      state.loadingBookings = payload;
    },
    SET_ACCOMMODATION_BOOKINGS(state, payload) {
      state.bookings = payload;
    },
    SET_LOADING_ACCOMMODATION_SERVICES(state, payload) {
      state.loadingServices = payload;
    },
    SET_ACCOMMODATION_SERVICES(state, payload) {
      state.services = payload;
    },
    ADD_ACCOMMODATION_SERVICE(state, service) {
      state.services.push(service);
    },
    UPDATE_ACCOMMODATION_SERVICE(state, service) {
      const serviceIndex = state.services.findIndex(
        (s) => s.uuid === service.uuid
      );
      state.services.splice(serviceIndex, 1, service);
    },
    REMOVE_ACCOMMODATION_SERVICE(state, serviceUuid) {
      const serviceIndex = state.services.findIndex(
        (s) => s.uuid === serviceUuid
      );
      state.services.splice(serviceIndex, 1);
    },
    SET_LOADING_ACCOMMODATION_REVIEWS(state, payload) {
      state.loadingReviews = payload;
    },
    SET_ACCOMMODATION_REVIEWS(state, payload) {
      state.reviews = payload;
    },
    ADD_ACCOMMODATION_REVIEW(state, review) {
      state.reviews.push(review);
    },
    UPDATE_ACCOMMODATION_REVIEW(state, review) {
      const reviewIndex = state.reviews.findIndex(
        (r) => r.uuid === review.uuid
      );
      state.reviews.splice(reviewIndex, 1, review);
    },
    REMOVE_ACCOMMODATION_REVIEW(state, reviewUuid) {
      const reviewIndex = state.reviews.findIndex((r) => r.uuid === reviewUuid);
      state.reviews.splice(reviewIndex, 1);
    },
    UPDATE_ACCOMMODATION_RATING(state) {
      let averageRating = 0;
      let rating = {
        facilities: 0,
        location: 0,
        cleaning: 0,
        communication: 0,
        checkin: 0,
        accuracy: 0,
      };
      let approvedRreviews = 0;

      state.reviews.forEach((review) => {
        if (review.approved) {
          approvedRreviews += 1;

          averageRating += review.averageRating;

          rating.facilities += review.rateFacilities;
          rating.location += review.rateLocation;
          rating.cleaning += review.rateCleaning;
          rating.communication += review.rateCommunication;
          rating.checkin += review.rateCheckin;
          rating.accuracy += review.rateAccuracy;
        }
      });

      if (approvedRreviews === 0) {
        averageRating = null;
        rating = null;
      } else {
        averageRating = parseFloat(
          (averageRating / approvedRreviews).toFixed(1)
        );

        rating.facilities = parseFloat(
          (rating.facilities / approvedRreviews).toFixed(1)
        );
        rating.location = parseFloat(
          (rating.location / approvedRreviews).toFixed(1)
        );
        rating.cleaning = parseFloat(
          (rating.cleaning / approvedRreviews).toFixed(1)
        );
        rating.communication = parseFloat(
          (rating.communication / approvedRreviews).toFixed(1)
        );
        rating.checkin = parseFloat(
          (rating.checkin / approvedRreviews).toFixed(1)
        );
        rating.accuracy = parseFloat(
          (rating.accuracy / approvedRreviews).toFixed(1)
        );
      }
      state.accommodation.averageRating = averageRating;
      state.accommodation.rating = rating;
    },
    SORT_ACCOMMODATION_REVIEWS_BY_POST_DATE(state, order) {
      state.reviews.sort((a, b) => {
        if (new Date(a.postedAt) > new Date(b.postedAt)) {
          if (order.toLowerCase() === "desc") return -1;
          return 1;
        }
        if (new Date(b.postedAt) > new Date(a.postedAt)) {
          if (order.toLowerCase() === "desc") return 1;
          return -1;
        }
        return 0;
      });
    },
    SET_LOADING_ACCOMMODATION_CONTRACT(state, payload) {
      state.loadingContract = payload;
    },
    SET_ACCOMMODATION_CONTRACT(state, payload) {
      state.contract = payload;
    },
    SET_LOADING_ACCOMMODATION_OWNER(state, payload) {
      state.loadingOwner = payload;
    },
    SET_ACCOMMODATION_OWNER(state, payload) {
      state.owner = payload;
      if (state.accommodation) state.accommodation.owner = payload;
    },
    SET_ACCOMMODATION_DETAILS(state, details) {
      if (state.accommodation) state.accommodation.details = details;
    },
    SET_LOADING_ACCOMMODATION_FAQS(state, payload) {
      state.loadingFaqs = payload;
    },
    SET_ACCOMMODATION_FAQS(state, payload) {
      state.faqs = payload;
    },
    ADD_ACCOMMODATION_FAQ(state, faq) {
      state.faqs.push(faq);
    },
    UPDATE_ACCOMMODATION_FAQ(state, updatedFaq) {
      const faqIndex = state.faqs.findIndex(
        (faq) => faq.uuid === updatedFaq.uuid
      );
      if (faqIndex === -1) return;
      state.faqs.splice(faqIndex, 1, updatedFaq);
    },
    REMOVE_ACCOMMODATION_FAQ(state, faqUuid) {
      const faqIndex = state.faqs.findIndex((faq) => faq.uuid === faqUuid);
      state.faqs.splice(faqIndex, 1);
    },
    ADD_ACCOMMODATION_FAQS_PICTURE(state, payload) {
      if (!payload.faq || !payload.picture) return;
      const faqIndex = state.faqs.findIndex((faq) => faq.uuid === payload.faq);
      if (faqIndex !== -1) state.faqs[faqIndex].pictures.push(payload.picture);
    },
    REMOVE_ACCOMMODATION_FAQ_PICTURE(state, payload) {
      if (!payload.faqUuid || !payload.pictureUuid) return;
      const faqIndex = state.faqs.findIndex(
        (faq) => faq.uuid === payload.faqUuid
      );
      if (faqIndex === -1) return;
      const pictureIndex = state.faqs[faqIndex].pictures.findIndex(
        (picture) => picture.uuid === payload.pictureUuid
      );
      if (pictureIndex === -1) return;
      state.faqs[faqIndex].pictures.splice(pictureIndex, 1);
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchAccommodation({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/accommodations/${accommodationUuid}`)
          .then((response) => {
            commit("SET_ACCOMMODATION", response.data);
            // The promise returns the accommodation data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION", false));
      });
    },
    updateAccommodation({ commit }, accommodation) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/accommodations/${accommodation.uuid}`, accommodation, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_ACCOMMODATION", response.data);
              commit("accommodations/UPDATE_ACCOMMODATION", response.data, {
                root: true,
              });
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    createAccommodation({ commit }, accommodation) {
      return new Promise((resolve, reject) => {
        axios
          .post("/accommodations", accommodation)
          .then((response) => {
            if (response.status === 201) {
              commit("accommodations/ADD_ACCOMMODATION", response.data, {
                root: true,
              });
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    importAccommodation({ commit }, channelManagerId) {
      return new Promise((resolve, reject) => {
        if (!channelManagerId) reject(new Error("Missing mandatory parameter"));

        const url = `/cm/import-accommodation/${channelManagerId}?services=true&amenities=true`;
        axios
          .post(url)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data.accommodation);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteAccommodation({ commit }, accommodationUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accommodations/${accommodationUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("accommodations/REMOVE_ACCOMMODATION", accommodationUuid, {
                root: true,
              });
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error(
                "An error occurred while trying to delete de accommodation"
              )
            )
          );
      });
    },
    updateAccommodationLocation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        if (payload.location) {
          axios
            .patch(`/locations/${payload.location.uuid}`, payload.location, {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            })
            .then((response) => {
              if (response.status === 200) {
                commit("SET_ACCOMMODATION_LOCATION", {
                  location: response.data,
                });
                commit(
                  "accommodations/UPDATE_ACCOMMODATION_LOCATION",
                  {
                    accommodation: payload.accommodation,
                    location: response.data,
                  },
                  { root: true }
                );
                resolve(response);
              } else {
                reject(response);
              }
            })
            .catch((error) => reject(error));
        }
      });
    },
    fetchAccommodationPictures({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_PICTURES", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/accommodations/${accommodationUuid}/pictures?pagination=false`)
          .then((response) => {
            commit("SET_ACCOMMODATION_PICTURES", response.data["hydra:member"]);
            commit("SORT_ACCOMMODATION_PICTURES");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log the error in Sentry
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_PICTURES", false));
      });
    },
    addAccommodationPictures({ commit }, payload) {
      return new Promise((resolve, reject) => {
        if (payload.pictures.length > 0) {
          let successfulRequestsCount = 0;
          const promises = [];

          payload.pictures.forEach((picture) => {
            promises.push(
              axios
                .post("/accommodation_pictures", {
                  accommodationUuid: payload.accommodationUuid,
                  pictures: [picture],
                })
                .then((response) => {
                  if (response.status === 201) {
                    successfulRequestsCount += 1;
                    response.data.createdPictures.forEach((createdPicture) => {
                      commit("ADD_ACCOMMODATION_PICTURE", createdPicture);
                    });
                  }
                })
            );
          });

          Promise.all(promises).then(() => {
            if (successfulRequestsCount === payload.pictures.length) {
              resolve({
                message: "All pictures successfully added",
              });
            } else {
              reject(
                new Error("Some pictures has been not added, please try again")
              );
            }
          });
        } else {
          reject(new Error("There must be at least one picture to add"));
        }
      });
    },
    updateAccommodationPicturesOrder({ commit }, pictures) {
      return new Promise((resolve, reject) => {
        if (pictures.length > 0) {
          const promises = [];

          let successfulRequestsCount = 0;

          pictures.forEach((picture, index) => {
            promises.push(
              axios
                .patch(
                  `/pictures/${picture.uuid}`,
                  {
                    priority: index,
                    cover: index === 0,
                  },
                  {
                    headers: {
                      "Content-Type": "application/merge-patch+json",
                    },
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    successfulRequestsCount += 1;
                    commit("UPDATE_ACCOMMODATION_PICTURE", response.data);
                  }
                })
            );
          });

          Promise.all(promises)
            .then(() => {
              if (successfulRequestsCount === pictures.length) {
                commit("SORT_ACCOMMODATION_PICTURES");
                resolve();
              } else {
                reject(new Error("Not all the pictures have been updated"));
              }
            })
            .catch((error) => reject(error));
        } else {
          reject(new Error("There must be at least one picture to update"));
        }
      });
    },
    updateAccommodationPictureStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `/pictures/${payload.pictureUuid}`,
            { active: payload.status },
            {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_ACCOMMODATION_PICTURE", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteAccommodationPicture({ commit }, pictureUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/pictures/${pictureUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_ACCOMMODATION_PICTURE", pictureUuid);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete de picture")
            )
          );
      });
    },
    deleteAccommodationPictures({ commit }, picturesUuids) {
      return new Promise((resolve, reject) => {
        if (picturesUuids.length > 0) {
          const promises = [];
          let successfulRequestsCount = 0;
          picturesUuids.forEach((pictureUuid) => {
            promises.push(
              axios.delete(`/pictures/${pictureUuid}`).then((response) => {
                if (response.status === 204) {
                  successfulRequestsCount += 1;
                  commit("REMOVE_ACCOMMODATION_PICTURE", pictureUuid);
                }
              })
            );
          });

          Promise.all(promises).then(() => {
            if (successfulRequestsCount === picturesUuids.length) {
              resolve({
                message: "All pictures successfully removed",
              });
            } else {
              reject(
                new Error(
                  "Not all the selected pictures have been removed, try it again"
                )
              );
            }
          });
        } else {
          reject(new Error("There are no pictures to remove"));
        }
      });
    },
    updateAccommodationDescriptions({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        const promises = [];
        let successfulRequestsCount = 0;

        // Find the current descriptions that need to be deleted (they are not in the descriptions array received as a parameter)
        if (state.accommodation.descriptions?.length > 0) {
          state.accommodation.descriptions.forEach((currentDescription) => {
            const foundIndex = payload.descriptions.findIndex(
              (d) => d.uuid === currentDescription.uuid
            );
            if (foundIndex === -1) {
              promises.push(
                axios
                  .delete(
                    `/accommodation_descriptions/${currentDescription.uuid}`
                  )
                  .then((response) => {
                    if (response.status === 204) {
                      commit(
                        "REMOVE_ACCOMMODATION_DESCRIPTION",
                        currentDescription.uuid
                      );
                      successfulRequestsCount += 1;
                    }
                  })
              );
            }
          });
        }

        // Update or create the rest of descriptions received as a parameter
        payload.descriptions.forEach((description) => {
          if (description.new) {
            promises.push(
              axios
                .post("/accommodation_descriptions", {
                  uuid: description.uuid,
                  accommodation: payload.accommodation["@id"],
                  language: description.language,
                  content: description.content,
                  scope: description.scope,
                })
                .then((response) => {
                  if (response.status === 201) {
                    commit("ADD_ACCOMMODATION_DESCRIPTION", response.data);
                    successfulRequestsCount += 1;
                  }
                })
            );
          } else {
            promises.push(
              axios
                .patch(
                  `/accommodation_descriptions/${description.uuid}`,
                  {
                    language: description.language,
                    title: description.title,
                    content: description.content,
                    scope: description.scope,
                  },
                  {
                    headers: {
                      "Content-Type": "application/merge-patch+json",
                    },
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    commit("UPDATE_ACCOMMODATION_DESCRIPTION", response.data);
                    successfulRequestsCount += 1;
                  }
                })
            );
          }
        });

        Promise.all(promises)
          .then(() => {
            if (successfulRequestsCount >= payload.descriptions.length) {
              resolve();
            } else {
              reject(new Error("Not all the descriptions have been updated"));
            }
          })
          .catch((error) => reject(error));
      });
    },
    fetchAccommodationBedrooms({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_BEDROOMS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/accommodations/${accommodationUuid}/bedrooms?pagination=false`)
          .then((response) => {
            commit("SET_ACCOMMODATION_BEDROOMS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_BEDROOMS", false));
      });
    },
    addAccommodationBedroom({ commit }, bedroom) {
      return new Promise((resolve, reject) => {
        axios
          .post("/accommodation_bedrooms", bedroom)
          .then((response) => {
            if (response.status === 201) {
              const newBedroom = response.data;
              newBedroom.beds = [];
              let successfulRequestsCount = 0;
              const promises = [];

              bedroom.beds.forEach((bed) => {
                promises.push(
                  axios
                    .post("/accommodation_beds", {
                      bedroom: newBedroom["@id"],
                      type: bed.type,
                      width: parseInt(bed.width, 10),
                      length: parseInt(bed.length, 10),
                      mattressType: bed.mattressType,
                      pillowType: bed.pillowType,
                    })
                    .then((bedResponse) => {
                      if (bedResponse.status === 201) {
                        successfulRequestsCount += 1;
                        newBedroom.beds.push(bedResponse.data);
                      }
                    })
                );
              });

              Promise.all(promises)
                .then(() => {
                  if (successfulRequestsCount === bedroom.beds.length) {
                    // Add bedroom to store
                    commit("ADD_ACCOMMODATION_BEDROOM", newBedroom);
                    resolve(response);
                  } else {
                    resolve({
                      message:
                        "The bedroom has been created but there's been an error while creating the beds",
                      status: 206,
                    });
                  }
                })
                .catch((error) => reject(error));
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateAccommodationBedroom({ commit }, bedroom) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/accommodation_bedrooms/${bedroom.uuid}`, bedroom, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const updatedBedroom = response.data;
              updatedBedroom.beds = [];
              let successfulRequestsCount = 0;
              const promises = [];

              bedroom.beds.forEach((bed) => {
                if (bed.uuid) {
                  // Bed already exists, update
                  promises.push(
                    axios
                      .patch(`/accommodation_beds/${bed.uuid}`, bed, {
                        headers: {
                          "Content-Type": "application/merge-patch+json",
                        },
                      })
                      .then((bedResponse) => {
                        if (bedResponse.status === 200) {
                          successfulRequestsCount += 1;
                          updatedBedroom.beds.push(bedResponse.data);
                        }
                      })
                  );
                } else {
                  // Bed does not exist, create
                  promises.push(
                    axios
                      .post("/accommodation_beds", {
                        bedroom: updatedBedroom["@id"],
                        ...bed,
                      })
                      .then((bedResponse) => {
                        if (bedResponse.status === 201) {
                          successfulRequestsCount += 1;
                          updatedBedroom.beds.push(bedResponse.data);
                        }
                      })
                  );
                }
              });

              Promise.all(promises)
                .then(() => {
                  if (successfulRequestsCount === bedroom.beds.length) {
                    // Add bedroom to store
                    commit("UPDATE_ACCOMMODATION_BEDROOM", updatedBedroom);
                    resolve(response);
                  } else {
                    resolve({
                      message:
                        "The bedroom has been updated but there's been an error while creating/updating the beds",
                      status: 206,
                    });
                  }
                })
                .catch((error) => {
                  reject(error);
                  // TODO: Log error in Sentry
                });
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteAccommodationBedroom({ commit }, bedroomUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accommodation_bedrooms/${bedroomUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_ACCOMMODATION_BEDROOM", bedroomUuid);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    fetchAccommodationBathrooms({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_BATHROOMS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/accommodations/${accommodationUuid}/bathrooms?pagination=false`
          )
          .then((response) => {
            commit(
              "SET_ACCOMMODATION_BATHROOMS",
              response.data["hydra:member"]
            );
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_BATHROOMS", false));
      });
    },
    addAccommodationBathroom({ commit }, bathroom) {
      return new Promise((resolve, reject) => {
        axios
          .post("/accommodation_bathrooms", bathroom)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_ACCOMMODATION_BATHROOM", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateAccommodationBathroom({ commit }, bathroom) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/accommodation_bathrooms/${bathroom.uuid}`, bathroom, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_ACCOMMODATION_BATHROOM", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteAccommodationBathroom({ commit }, bathroomUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accommodation_bathrooms/${bathroomUuid}`)
          .then((response) => {
            commit("REMOVE_ACCOMMODATION_BATHROOM", bathroomUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteAccommodationBed({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accommodation_beds/${payload.bedUuid}`)
          .then((response) => {
            commit("REMOVE_ACCOMMODATION_BED", payload);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAccommodationAmenities({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_AMENITIES", true);
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/accommodations/${accommodationUuid}/amenities?pagination=false`
          )
          .then((response) => {
            commit(
              "SET_ACCOMMODATION_AMENITIES",
              response.data["hydra:member"]
            );
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_AMENITIES", false));
      });
    },
    updateAccommodationAmenities(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.accommodationUuid && payload.amenities) {
          axios
            .post("/update_accommodation_amenities", {
              accommodationUuid: payload.accommodationUuid,
              amenities: payload.amenities,
            })
            .then(() => resolve())
            .catch((error) => {
              reject(error);
              // TODO: Log error in Sentry
            });
        } else {
          reject(
            new Error({
              message: "missing mandatory parameters",
              status: 500,
            })
          );
        }
      });
    },
    importAccommodationAvailabilities(context, accommodationSlug) {
      return new Promise((resolve, reject) => {
        if (!accommodationSlug)
          reject(new Error("Missing mandatory `slug` parameter"));

        axios
          .post(`/cm/import-availabilities/${accommodationSlug}`)
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAccommodationAvailabilities({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_AVAILABILITIES", true);
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/accommodations/${accommodationUuid}/availabilities?pagination=false`
          )
          .then((response) => {
            commit(
              "SET_ACCOMMODATION_AVAILABILITIES",
              response.data["hydra:member"]
            );
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          })
          .finally(() =>
            commit("SET_LOADING_ACCOMMODATION_AVAILABILITIES", false)
          );
      });
    },
    importAccommodationRates(context, accommodationSlug) {
      return new Promise((resolve, reject) => {
        if (!accommodationSlug)
          reject(new Error("Missing mandatory `slug` parameter"));

        axios
          .post(`/cm/import-rates/${accommodationSlug}`)
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchAccommodationRates({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_RATES", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/accommodations/${accommodationUuid}/rates?pagination=false`)
          .then((response) => {
            commit("SET_ACCOMMODATION_RATES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_RATES", false));
      });
    },
    setAccommodationRates(context, rateConfig) {
      return new Promise((resolve, reject) => {
        if (
          rateConfig.accommodation &&
          rateConfig.startDate &&
          rateConfig.endDate &&
          rateConfig.price &&
          rateConfig.ownerPrice &&
          rateConfig.minimumStay &&
          rateConfig.checkinDays &&
          rateConfig.checkoutDays &&
          rateConfig.bookingPolicy
        ) {
          axios
            .post("/accommodation_rates/range", {
              accommodation: rateConfig.accommodation,
              startDate: formatDateYmd(rateConfig.startDate),
              endDate: formatDateYmd(rateConfig.endDate),
              price: rateConfig.price,
              ownerPrice: rateConfig.ownerPrice,
              minimumStay: rateConfig.minimumStay,
              checkinDays: rateConfig.checkinDays,
              checkoutDays: rateConfig.checkoutDays,
              bookingPolicy: rateConfig.bookingPolicy,
            })
            .then(() => resolve())
            .catch((error) => {
              reject(error);
              // TODO: Log error in Sentry
            });
        } else {
          reject(new Error("Missing mandatory parameters"));
        }
      });
    },
    setAccommodationOwnerRates(context, rateConfig) {
      return new Promise((resolve, reject) => {
        if (
          rateConfig.accommodation &&
          rateConfig.startDate &&
          rateConfig.endDate &&
          rateConfig.ownerPrice
        ) {
          axios
            .post("/accommodation_rates/owner_price_range", {
              accommodation: rateConfig.accommodation,
              startDate: formatDateYmd(rateConfig.startDate),
              endDate: formatDateYmd(rateConfig.endDate),
              ownerPrice: rateConfig.ownerPrice,
            })
            .then(() => resolve())
            .catch((error) => {
              reject(error);
              // TODO: Log error in Sentry
            });
        } else {
          reject(new Error("Missing mandatory parameters"));
        }
      });
    },
    setAccommodationBookingPolicies(context, rateConfig) {
      return new Promise((resolve, reject) => {
        if (
          rateConfig.accommodation &&
          rateConfig.startDate &&
          rateConfig.endDate &&
          rateConfig.bookingPolicy
        ) {
          axios
            .post("/accommodation_rates/booking_policies_range", {
              accommodation: rateConfig.accommodation,
              startDate: formatDateYmd(rateConfig.startDate),
              endDate: formatDateYmd(rateConfig.endDate),
              bookingPolicy: rateConfig.bookingPolicy,
            })
            .then(() => resolve())
            .catch((error) => {
              reject(error);
              // TODO: Log error in Sentry
            });
        } else {
          reject(new Error("Missing mandatory parameters"));
        }
      });
    },
    fetchAccommodationDatesLocks({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_DATES_LOCKS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/accommodations/${accommodationUuid}/dates_locks?pagination=false`
          )
          .then((response) => {
            commit(
              "SET_ACCOMMODATION_DATES_LOCKS",
              response.data["hydra:member"]
            );
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          })
          .finally(() =>
            commit("SET_LOADING_ACCOMMODATION_DATES_LOCKS", false)
          );
      });
    },
    setAccommodationDatesLock(context, datesLockConfig) {
      return new Promise((resolve, reject) => {
        if (
          datesLockConfig.accommodation &&
          datesLockConfig.startDate &&
          datesLockConfig.endDate &&
          datesLockConfig.scope
        ) {
          axios
            .post("/dates_locks", {
              accommodation: datesLockConfig.accommodation,
              startDate: formatDateYmd(datesLockConfig.startDate),
              endDate: formatDateYmd(datesLockConfig.endDate),
              scope: datesLockConfig.scope,
              expiryDate: datesLockConfig.expiryDate
                ? formatDateYmd(datesLockConfig.expiryDate)
                : null,
            })
            .then(() => resolve())
            .catch((error) => {
              reject(error);
              // TODO: Log error in Sentry
            });
        } else {
          reject(new Error("Missing mandatory parameters"));
        }
      });
    },
    deleteAccommodationDatesLock({ commit }, datesLockUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/dates_locks/${datesLockUuid}`)
          .then((response) => {
            commit("REMOVE_ACCOMMODATION_DATES_LOCK", datesLockUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    fetchAccommodationBookings({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_BOOKINGS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/accommodations/${accommodationUuid}/bookings?pagination=false`)
          .then((response) => {
            commit("SET_ACCOMMODATION_BOOKINGS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_BOOKINGS", false));
      });
    },
    fetchServices({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_SERVICES", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/accommodations/${accommodationUuid}/services?pagination=false`)
          .then((response) => {
            commit("SET_ACCOMMODATION_SERVICES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_SERVICES", false));
      });
    },
    addService({ commit }, service) {
      return new Promise((resolve, reject) => {
        axios
          .post("/accommodation_services", service)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_ACCOMMODATION_SERVICE", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response code was not the expected"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateService({ commit }, service) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/accommodation_services/${service.uuid}`, service, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_ACCOMMODATION_SERVICE", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteService({ commit }, serviceUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accommodation_services/${serviceUuid}`)
          .then((response) => {
            commit("REMOVE_ACCOMMODATION_SERVICE", serviceUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    fetchAccommodationReviews({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_REVIEWS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/accommodations/${accommodationUuid}/reviews?pagination=false&order[postedAt]=desc`
          )
          .then((response) => {
            commit("SET_ACCOMMODATION_REVIEWS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_REVIEWS", false));
      });
    },
    addAccommodationReview({ commit, state }, review) {
      return new Promise((resolve, reject) => {
        axios
          .post("/reviews", review)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_ACCOMMODATION_REVIEW", response.data);
              commit("SORT_ACCOMMODATION_REVIEWS_BY_POST_DATE", "desc");
              if (state.accommodation) commit("UPDATE_ACCOMMODATION_RATING");
              resolve(response);
            } else {
              // TODO: Log error in Sentry
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateAccommodationReview({ commit, state }, review) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/reviews/${review.uuid}`, review, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_ACCOMMODATION_REVIEW", response.data);
              if (state.accommodation) commit("UPDATE_ACCOMMODATION_RATING");
              resolve(response);
            } else {
              reject(response);
              // TODO: Log error in Sentry
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteAccommodationReview({ commit, state }, reviewUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/reviews/${reviewUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_ACCOMMODATION_REVIEW", reviewUuid);
              if (state.accommodation) commit("UPDATE_ACCOMMODATION_RATING");
              resolve(response);
            } else {
              reject(response);
              // TODO: Log error in Sentry
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    fetchContract({ commit }, contractUuid) {
      commit("SET_LOADING_ACCOMMODATION_CONTRACT", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/contracts/${contractUuid}`)
          .then((response) => {
            commit("SET_ACCOMMODATION_CONTRACT", response.data);
            // The promise returns the accommodation data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_CONTRACT", false));
      });
    },
    addContract({ dispatch }, contractConfig) {
      return new Promise((resolve, reject) => {
        if (
          contractConfig.accommodation &&
          contractConfig.startDate &&
          contractConfig.endDate &&
          (contractConfig.owner ||
            (contractConfig.firstName &&
              contractConfig.idType &&
              contractConfig.idNumber))
        ) {
          axios
            .post("/add_contract", {
              accommodation: contractConfig.accommodation,
              startDate: formatDateStringToDatabaseDate(
                contractConfig.startDate
              ),
              endDate: formatDateStringToDatabaseDate(contractConfig.endDate),
              owner: contractConfig.owner,
              firstName: contractConfig.firstName,
              lastName: contractConfig.lastName,
              idType: contractConfig.idType,
              idNumber: contractConfig.idNumber,
              email: contractConfig.email,
            })
            .then(async (addContractResponse) => {
              await dispatch(
                "fetchContract",
                addContractResponse.data.uuid
              ).then((fetchContractResponse) => {
                dispatch("fetchOwner", fetchContractResponse.owner.uuid);
              });
              resolve();
            })
            .catch((error) => {
              reject(error);
              // TODO: Log error in Sentry
            });
        } else {
          reject(new Error("Missing mandatory parameters"));
        }
      });
    },
    fetchOwner({ commit }, ownerUuid) {
      commit("SET_LOADING_ACCOMMODATION_OWNER", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/owners/${ownerUuid}`)
          .then((response) => {
            commit("SET_ACCOMMODATION_OWNER", response.data);
            // The promise returns the accommodation data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_OWNER", false));
      });
    },
    createAccommodationDetails({ commit }, details) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/accommodation_details`, details)
          .then((response) => {
            if (response.status === 201) {
              commit("SET_ACCOMMODATION_DETAILS", response.data);
              resolve(response.data);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateAccommodationDetails({ commit }, details) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/accommodation_details/${details.uuid}`, details, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_ACCOMMODATION_DETAILS", response.data);
              resolve(response.data);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    fetchAccommodationFaqs({ commit }, accommodationUuid) {
      commit("SET_LOADING_ACCOMMODATION_FAQS", true);
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/accommodations/${accommodationUuid}/faqs?pagination=false&order[language]=asc`
          )
          .then((response) => {
            commit("SET_ACCOMMODATION_FAQS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATION_FAQS", false));
      });
    },
    addAccommodationFaq({ commit }, faq) {
      return new Promise((resolve, reject) => {
        axios
          .post("/accommodation_faqs", faq)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_ACCOMMODATION_FAQ", response.data);
              resolve(response.data);
            } else {
              // TODO: Log error in Sentry
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateAccommodationFaq({ commit }, faq) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/accommodation_faqs/${faq.uuid}`, faq, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_ACCOMMODATION_FAQ", response.data);
              resolve(response.data);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    addAccommodationFaqPictures({ commit }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload?.accommodationUuid) {
          reject(new Error("The accommodationUuid is mandatory"));
          return;
        }
        if (!payload?.faqUuid) {
          reject(new Error("The faqUuid is mandatory"));
          return;
        }
        if (!payload?.pictures?.length) {
          reject(new Error("There must be at least one picture to add"));
          return;
        }

        let successfulRequestsCount = 0;
        const promises = [];

        payload.pictures.forEach((picture) => {
          promises.push(
            axios
              .post("/accommodation_faq_pictures", {
                accommodationUuid: payload.accommodationUuid,
                faqUuid: payload.faqUuid,
                picture,
              })
              .then((response) => {
                if (response.status === 201) {
                  successfulRequestsCount += 1;
                  commit("ADD_ACCOMMODATION_FAQS_PICTURE", {
                    picture: response.data.picture,
                    faq: response.data.faq,
                  });
                }
              })
          );
        });

        Promise.all(promises).then(() => {
          if (successfulRequestsCount < payload.pictures.length) {
            reject(
              new Error("Some pictures has been not added, please try again")
            );
            return;
          }
          resolve({
            message: "All pictures successfully added",
          });
        });
      });
    },
    deleteAccommodationFaq({ commit }, faqUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accommodation_faqs/${faqUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_ACCOMMODATION_FAQ", faqUuid);
              resolve(response);
            } else {
              reject(response);
              // TODO: Log error in Sentry
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteAccommodationFaqPicture({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/pictures/${payload.pictureUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_ACCOMMODATION_FAQ_PICTURE", payload);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete de picture")
            )
          );
      });
    },
    // TODO: PER REVISAR
    async getAccommodationAvailabilities(context, accommodationUuid) {
      return axios
        .get(
          `/accommodations/${accommodationUuid}/availabilities?pagination=false&order[date]=asc`
        )
        .then((response) => response.data["hydra:member"])
        .catch(() => []);
    },
    updateAccommodationAvailability({ commit, getters }, availability) {
      return new Promise((resolve, reject) => {
        const foundAvailability = getters.accommodationAvailabilities.find(
          (av) => av.date.split("T")[0] === formatDateYmd(availability.date)
        );
        if (foundAvailability) {
          axios
            .patch(
              `/accommodations_availabilities/${foundAvailability.uuid}`,
              { available: availability.available },
              {
                headers: {
                  "Content-Type": "application/merge-patch+json",
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                commit("UPDATE_ACCOMMODATION_AVAILABILITY", response.data);
                resolve(response);
              }
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          // Availability not found, create it
          axios
            .post("/accommodations_availabilities", {
              accommodation: availability.accommodation,
              date: formatDateYmd(availability.date),
              available: availability.available,
            })
            .then((response) => {
              commit("ADD_ACCOMMODATION_AVAILABILITY", response.data);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    updateAccommodationAvailabilities(context, availabilitiesConfig) {
      return new Promise((resolve, reject) => {
        if (
          availabilitiesConfig.startDate &&
          availabilitiesConfig.endDate &&
          availabilitiesConfig.accommodation &&
          typeof availabilitiesConfig.available === "boolean"
        ) {
          axios
            .post("/accommodation_availabilities/range", {
              accommodation: availabilitiesConfig.accommodation,
              startDate: formatDateYmd(availabilitiesConfig.startDate),
              endDate: formatDateYmd(availabilitiesConfig.endDate),
              available: availabilitiesConfig.available,
            })
            .then(() => resolve())
            .catch((error) => reject(error));
        } else {
          reject(
            new Error({
              message: "missing mandatory parameters",
              status: 500,
            })
          );
        }
      });
    },
    async getAccommodationBookings(context, accommodationUuid) {
      return axios
        .get(`/accommodations/${accommodationUuid}/bookings?pagination=false`)
        .then((response) => response.data["hydra:member"])
        .catch(() => []);
    },
  },
};
