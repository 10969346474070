export default [
  {
    path: "/",
    name: "foravila-home",
    component: () => import("@/views/home/Home.vue"),
  },
  {
    path: "/stats",
    name: "foravila-stats",
    component: () => import("@/views/stats/GeneralStats.vue"),
  },
];
