export default {
  namespaced: true,
  state: {
    publicName: "Foravila Rentals",
    legalName: "Fora-Vila Sa Pobla S.L.",
    iban: "ES22 3058 4503 3827 2000 5150",
    swift: "CCRIES2A",
    bankName: "Cajamar Caja Rural",
  },
  getters: {
    publicName(state) {
      return state.publicName;
    },
    legalName(state) {
      return state.legalName;
    },
    iban(state) {
      return state.iban;
    },
    swift(state) {
      return state.swift;
    },
    bankName(state) {
      return state.bankName;
    },
  },
};
