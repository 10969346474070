import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    places: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    places(state) {
      return state.places;
    },
    cities(state) {
      return state.places
        .filter((place) => place.type === "CITY")
        .sort((a, b) => {
          if (a.slug > b.slug) return 1;
          if (b.slug > a.slug) return -1;
          return 0;
        });
    },
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_PLACES(state, payload) {
      state.places = payload;
    },
    ADD_PLACE(state, place) {
      state.places.push(place);
    },
    UPDATE_PLACE(state, updatedPlace) {
      const foundIndex = state.places.findIndex(
        (place) => place.uuid === updatedPlace.uuid
      );
      state.places.splice(foundIndex, 1, updatedPlace);
    },
    REMOVE_PLACE(state, placeUuid) {
      const foundIndex = state.places.findIndex(
        (place) => place.uuid === placeUuid
      );
      state.places.splice(foundIndex, 1);
    },
    UPDATE_PLACE_LOCATION(state, payload) {
      if (!state.places.length) return;
      const foundIndex = state.places.findIndex(
        (place) => place.uuid === payload.placeUuid
      );
      if (!foundIndex === -1) return;
      state.places[foundIndex].location = payload.location;
    },
  },
  actions: {
    fetchPlaces({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);

        let url = "/places?";
        if ("pagination" in payload) url += `pagination=${payload.pagination}`;

        axios
          .get(url)
          .then((response) => {
            commit("SET_PLACES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    addPlace({ commit }, place) {
      return new Promise((resolve, reject) => {
        axios
          .post("/places", place)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_PLACE", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one."
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updatePlace({ commit }, place) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/places/${place.uuid}`, place, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_PLACE", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one."
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deletePlace({ commit }, placeUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/places/${placeUuid}`)
          .then((response) => {
            commit("REMOVE_PLACE", placeUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updatePlaceLocation({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/locations/${payload.location.uuid}`, payload.location, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_PLACE_LOCATION", {
                placeUuid: payload.placeUuid,
                location: response.data,
              });
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
};
