import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    bookings: [],
    count: 0,
    itemsPerPage: 20,
    firstPage: 1,
    lastPage: 1,
    previousPage: null,
    nextPage: null,
    loadingPlanning: false,
    checkinsPlanning: [],
    checkoutsPlanning: [],
    requestedBookings: [],
    currentBookings: [],
    currentBookingsWithUnpaidServices: [],
    chargableSecurityDeposits: [],
    refundableSecurityDeposits: [],
    refundedSecurityDeposits: [],
    pendingFinalPayments: [],
    unsentPoliceReports: [],
    unsentHospedajesReports: [],
    unsentAeat179Reports: [],
    fictitiousBookings: [],
    bookingsFromFictitious: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    bookings(state) {
      return state.bookings;
    },
    count(state) {
      return state.count;
    },
    itemsPerPage(state) {
      return state.itemsPerPage;
    },
    firstPage(state) {
      return state.firstPage;
    },
    lastPage(state) {
      return state.lastPage;
    },
    previousPage(state) {
      return state.previousPage;
    },
    nextPage(state) {
      return state.nextPage;
    },
    loadingPlanning(state) {
      return state.loadingPlanning;
    },
    checkinsPlanning(state) {
      return state.checkinsPlanning;
    },
    checkoutsPlanning(state) {
      return state.checkoutsPlanning;
    },
    requestedBookings(state) {
      return state.requestedBookings;
    },
    currentBookings(state) {
      return state.currentBookings;
    },
    currentBookingsWithUnpaidServices(state) {
      return state.currentBookingsWithUnpaidServices;
    },
    chargableSecurityDeposits(state) {
      return state.chargableSecurityDeposits;
    },
    refundableSecurityDeposits(state) {
      return state.refundableSecurityDeposits;
    },
    refundedSecurityDeposits(state) {
      return state.refundedSecurityDeposits;
    },
    pendingFinalPayments(state) {
      return state.pendingFinalPayments;
    },
    unsentPoliceReports(state) {
      return state.unsentPoliceReports;
    },
    unsentHospedajesReports(state) {
      return state.unsentHospedajesReports;
    },
    unsentAeat179Reports(state) {
      return state.unsentAeat179Reports;
    },
    fictitiousBookings(state) {
      return state.fictitiousBookings;
    },
    bookingsFromFictitious(state) {
      return state.bookingsFromFictitious;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.bookings = [];
      state.count = 0;
      state.itemsPerPage = 20;
      state.firstPage = 1;
      state.lastPage = 1;
      state.previousPage = null;
      state.nextPage = null;
      state.loadingPlanning = false;
      state.checkinsPlanning = [];
      state.checkoutsPlanning = [];
      state.requestedBookings = [];
      state.currentBookings = [];
      state.currentBookingsWithUnpaidServices = [];
      state.chargableSecurityDeposits = [];
      state.refundableSecurityDeposits = [];
      state.refundedSecurityDeposits = [];
      state.pendingFinalPayments = [];
      state.unsentPoliceReports = [];
      state.unsentHospedajesReports = [];
      state.unsentAeat179Reports = [];
      state.fictitiousBookings = [];
      state.bookingsFromFictitious = [];
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_BOOKINGS(state, payload) {
      state.bookings = payload;
    },
    UPDATE_BOOKING(state, booking) {
      if (!state.bookings.length || !booking) return;
      const bookingIndex = state.bookings.findIndex(
        (item) => item.uuid === booking.uuid
      );
      if (bookingIndex === -1) return;
      state.bookings.splice(bookingIndex, 1, booking);
    },
    ADD_BOOKING(state, booking) {
      state.bookings.push(booking);
    },
    REMOVE_BOOKING(state, bookingUuid) {
      const bookingIndex = state.bookings.findIndex(
        (b) => b.uuid === bookingUuid
      );
      state.bookings.splice(bookingIndex, 1);
    },
    SET_COUNT(state, payload) {
      state.count = payload;
    },
    SET_ITEMS_PER_PAGE(state, payload) {
      state.itemsPerPage = payload;
    },
    SET_FIRST_PAGE(state, payload) {
      state.firstPage = payload;
    },
    SET_LAST_PAGE(state, payload) {
      state.lastPage = payload;
    },
    SET_PREVIOUS_PAGE(state, payload) {
      state.previousPage = payload;
    },
    SET_NEXT_PAGE(state, payload) {
      state.nextPage = payload;
    },
    SET_LOADING_PLANNING(state, payload) {
      state.loadingPlanning = payload;
    },
    SET_CHECKINS_PLANNING(state, payload) {
      state.checkinsPlanning = payload;
    },
    SET_CHECKOUTS_PLANNING(state, payload) {
      state.checkoutsPlanning = payload;
    },
    SET_REQUESTED_BOOKINGS(state, payload) {
      state.requestedBookings = payload;
    },
    SET_CURRENT_BOOKINGS(state, payload) {
      state.currentBookings = payload;
    },
    SET_CURRENT_BOOKINGS_WITH_UNPAID_SERVICES(state, payload) {
      state.currentBookingsWithUnpaidServices = payload;
    },
    SET_CHARGABLE_SECURITY_DEPOSITS(state, payload) {
      state.chargableSecurityDeposits = payload;
    },
    SET_REFUNDABLE_SECURITY_DEPOSITS(state, payload) {
      state.refundableSecurityDeposits = payload;
    },
    SET_REFUNDED_SECURITY_DEPOSITS(state, payload) {
      state.refundedSecurityDeposits = payload;
    },
    SET_PENDING_FINAL_PAYMENTS(state, payload) {
      state.pendingFinalPayments = payload;
    },
    SET_UNSENT_POLICE_REPORTS(state, payload) {
      state.unsentPoliceReports = payload;
    },
    SET_UNSENT_HOSPEDAJES_REPORTS(state, payload) {
      state.unsentHospedajesReports = payload;
    },
    SET_UNSENT_AEAT_179_REPORTS(state, payload) {
      state.unsentAeat179Reports = payload;
    },
    UPDATE_UNSENT_AEAT_179_REPORT_CLIENT(state, client) {
      const indexes = [];

      state.unsentAeat179Reports.forEach((report, index) => {
        if (report.client.uuid === client.uuid) indexes.push(index);
      });

      if (!indexes.length) return;

      indexes.forEach((index) => {
        state.unsentAeat179Reports[index].client = client;
      });
    },
    SET_FICTITIOUS_BOOKINGS(state, payload) {
      state.fictitiousBookings = payload;
    },
    SET_BOOKINGS_FROM_FICTITIOUS(state, payload) {
      state.bookingsFromFictitious = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchBookings({ state, commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/bookings?";
      if ("pagination" in payload) url += `&pagination=${payload.pagination}`;
      if ("itemsPerPage" in payload)
        url += `&itemsPerPage=${payload.itemsPerPage || state.itemsPerPage}`;
      if ("page" in payload) url += `&page=${payload.page}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_BOOKINGS", response.data["hydra:member"]);
              commit("SET_COUNT", response.data["hydra:totalItems"]);
              commit(
                "SET_FIRST_PAGE",
                response.data["hydra:view"]["hydra:first"]
              );
              commit(
                "SET_LAST_PAGE",
                response.data["hydra:view"]["hydra:last"]
              );
              commit(
                "SET_PREVIOUS_PAGE",
                response.data["hydra:view"]["hydra:previous"]
              );
              commit(
                "SET_NEXT_PAGE",
                response.data["hydra:view"]["hydra:next"]
              );
              resolve(response);
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the bookings",
                })
              );
            }
          })
          .catch((error) => {
            reject(error.response);
            // TODO: log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchFilteredBookings({ state, commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/filtered-bookings?";
      if ("pagination" in payload) url += `&pagination=${payload.pagination}`;
      if ("itemsPerPage" in payload)
        url += `&itemsPerPage=${payload.itemsPerPage || state.itemsPerPage}`;
      if ("page" in payload) url += `&page=${payload.page}`;
      if ("searchText" in payload) url += `&searchText=${payload.searchText}`;
      if ("sort" in payload && payload.sort.key && payload.sort.order) {
        url += `&sortKey=${payload.sort.key}&sortOrder=${payload.sort.order}`;
      } else {
        url += "&sortKey=date&sortOrder=desc";
      }

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_BOOKINGS", response.data["hydra:member"]);
              commit("SET_COUNT", response.data["hydra:totalItems"]);
              commit(
                "SET_FIRST_PAGE",
                response.data["hydra:view"]["hydra:first"]
              );
              commit(
                "SET_LAST_PAGE",
                response.data["hydra:view"]["hydra:last"]
              );
              commit(
                "SET_PREVIOUS_PAGE",
                response.data["hydra:view"]["hydra:previous"]
              );
              commit(
                "SET_NEXT_PAGE",
                response.data["hydra:view"]["hydra:next"]
              );
              resolve(response.data["hydra:member"]);
            } else {
              reject(new Error("Error while fetching all the bookings"));
            }
          })
          .catch((error) => {
            reject(error.response);
            // TODO: log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    setItemsPerPage({ commit }, payload) {
      commit("SET_ITEMS_PER_PAGE", payload);
    },
    fetchPlanning({ commit }, payload) {
      commit("SET_LOADING_PLANNING", true);

      return new Promise((resolve, reject) => {
        const promises = [];

        let checkinsUrl = "/bookings/checkins-planning?";
        let checkoutsUrl = "/bookings/checkouts-planning?";
        const urlParams = [];

        if (payload.days) urlParams.push(`days=${payload.days}`);
        if (payload.startDate) urlParams.push(`startDate=${payload.startDate}`);
        if (urlParams.length) {
          checkinsUrl += urlParams.join("&");
          checkoutsUrl += urlParams.join("&");
        }

        promises.push(
          axios.get(checkinsUrl).then((response) => {
            commit("SET_CHECKINS_PLANNING", response.data["hydra:member"]);
          })
        );

        promises.push(
          axios.get(checkoutsUrl).then((response) => {
            commit("SET_CHECKOUTS_PLANNING", response.data["hydra:member"]);
          })
        );

        Promise.all(promises)
          .then(() => resolve())
          .catch(() => reject(new Error("fetch planning error")))
          .finally(() => commit("SET_LOADING_PLANNING", false));
      });
    },
    importBooking({ commit }, bookingId) {
      return new Promise((resolve, reject) => {
        if (!bookingId) reject(new Error("Missing mandatory parameter"));

        const url = `/cm/import-booking/${bookingId}`;
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("ADD_BOOKING", response.data.booking);
              resolve(response.data.booking);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    importBookings(context, data) {
      return new Promise((resolve, reject) => {
        if (
          !data?.accommodationId &&
          !data?.startDate &&
          !data?.endDate &&
          !data?.localizators
        ) {
          reject(new Error("Missing mandatory parameters"));
        } else {
          const url = "/cm/import-bookings";
          axios
            .post(url, data)
            .then((response) => {
              if (response.status === 200) resolve();
              else reject(response);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    fetchRequestedBookings({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            "/bookings?pagination=false&groups[]=homesummary&status=REQUESTED"
          )
          .then((response) => {
            commit("SET_REQUESTED_BOOKINGS", response.data["hydra:member"]);
            resolve();
          })
          .catch(() => reject(new Error("axios fetchRequestedBookings error")));
      });
    },
    fetchCurrentBookings({ commit }) {
      return new Promise((resolve, reject) => {
        const today = new Date().toISOString().split("T")[0];

        axios
          .get(
            `/bookings?pagination=false&groups[]=homesummary&status[]=CONFIRMED&status[]=COMPLETED&checkin[before]=${today}&checkout[after]=${today}`
          )
          .then((response) => {
            commit("SET_CURRENT_BOOKINGS", response.data["hydra:member"]);
            resolve();
          })
          .catch(() => reject(new Error("axios fetchCurrentBookings error")));
      });
    },
    fetchNotFullyPaidCurrentBookings({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings/not-fully-paid-current-bookings?groups[]=homesummary`)
          .then((response) => {
            commit(
              "SET_CURRENT_BOOKINGS_WITH_UNPAID_SERVICES",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() =>
            reject(new Error("axios fetchNotFullyPaidCurrentBookings error"))
          );
      });
    },
    fetchBookingsWithChargableSecurityDeposit({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .get("/bookings/chargable-security-deposits")
          .then((response) => {
            commit(
              "SET_CHARGABLE_SECURITY_DEPOSITS",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() =>
            reject(
              new Error("axios fetchBookingsWithChargableSecurityDeposit error")
            )
          )
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchBookingsWithRefundableSecurityDeposits({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .get("/bookings/refundable-security-deposits")
          .then((response) => {
            commit(
              "SET_REFUNDABLE_SECURITY_DEPOSITS",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() =>
            reject(
              new Error(
                "axios fetchBookingsWithRefundableSecurityDeposits error"
              )
            )
          )
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchBookingsWithRefundedSecurityDeposits({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .get("/bookings/refunded-security-deposits")
          .then((response) => {
            commit(
              "SET_REFUNDED_SECURITY_DEPOSITS",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() =>
            reject(
              new Error("axios fetchBookingsWithRefundedSecurityDeposits error")
            )
          )
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchBookingsWithPendingFinalPayment({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .get("/bookings/pending-final-payments")
          .then((response) => {
            commit("SET_PENDING_FINAL_PAYMENTS", response.data["hydra:member"]);
            resolve();
          })
          .catch(() =>
            reject(
              new Error("axios fetchBookingsWithPendingFinalPayment error")
            )
          )
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchBookingsWithUnsentPoliceReport({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .get("/bookings/unsent-police-reports")
          .then((response) => {
            commit("SET_UNSENT_POLICE_REPORTS", response.data["hydra:member"]);
            resolve();
          })
          .catch(() =>
            reject(new Error("axios fetchBookingsWithUnsentPoliceReport error"))
          )
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchBookingsWithUnsentHospedajesReport({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .get("/bookings/unsent-hospedajes-reports")
          .then((response) => {
            commit(
              "SET_UNSENT_HOSPEDAJES_REPORTS",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() =>
            reject(
              new Error("axios fetchBookingsWithUnsentHospedajesReport error")
            )
          )
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchBookingsWithUnsentAeat179Report(
      { commit },
      payload = { startDate: null, endDate: null }
    ) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        commit("SET_UNSENT_AEAT_179_REPORTS", []);

        let url = "/bookings/unsent-aeat-179-reports?groups[]=aeat179&";
        const urlParams = [];

        if (payload.startDate) urlParams.push(`startDate=${payload.startDate}`);
        if (payload.endDate) urlParams.push(`endDate=${payload.endDate}`);
        if (urlParams.length) url += urlParams.join("&");

        axios
          .get(url)
          .then((response) => {
            commit(
              "SET_UNSENT_AEAT_179_REPORTS",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() =>
            reject(
              new Error("axios fetchBookingsWithUnsentAeat179Report error")
            )
          )
          .finally(() => commit("SET_LOADING", false));
      });
    },
    updateUnsentAeat179ReportClient({ commit }, clientDetails) {
      commit("UPDATE_UNSENT_AEAT_179_REPORT_CLIENT", clientDetails);
    },
    addLog({ commit, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/booking_logs", {
            ...payload.log,
            data: {
              ...payload.log.data,
              adminUser: rootGetters["auth/loggedUserEmail"],
            },
          })
          .then((response) => {
            if (response.status === 201) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    fetchFictitiousBookings({ state, commit }) {
      commit("SET_LOADING", true);

      return new Promise((resolve, reject) => {
        axios
          .get("/bookings?fictitious=true&pagination=false")
          .then((response) => {
            if (response.status === 200) {
              commit("SET_FICTITIOUS_BOOKINGS", response.data["hydra:member"]);
              resolve(response);
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the fictitious bookings",
                })
              );
            }
          })
          .catch((error) => {
            reject(error.response);
            // TODO: log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchBookingsFromFictitious({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/bookings?pagination=false&stats.receivedFromFictitious=true`)
          // &status[]=CONFIRMED&status[]=COMPLETED
          .then((response) => {
            commit(
              "SET_BOOKINGS_FROM_FICTITIOUS",
              response.data["hydra:member"]
            );
            resolve();
          })
          .catch(() =>
            reject(new Error("axios fetchBookingsFromFictitious error"))
          );
      });
    },
  },
};
