import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    services: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    services(state) {
      return state.services;
    },
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_SERVICES(state, payload) {
      state.services = payload;
    },
    ADD_SERVICE(state, service) {
      state.services.push(service);
    },
    UPDATE_SERVICE(state, service) {
      const serviceIndex = state.services.findIndex(
        (s) => s.uuid === service.uuid
      );
      state.services.splice(serviceIndex, 1, service);
    },
    REMOVE_SERVICE(state, serviceUuid) {
      const serviceIndex = state.services.findIndex(
        (s) => s.uuid === serviceUuid
      );
      state.services.splice(serviceIndex, 1);
    },
    ADD_SERVICE_DESCRIPTION(state, payload) {
      const serviceIndex = state.services.findIndex(
        (s) => s.uuid === payload.serviceUuid
      );
      state.services[serviceIndex].descriptions.push(
        payload.serviceDescription
      );
    },
    REMOVE_SERVICE_DESCRIPTION(state, payload) {
      const serviceIndex = state.services.findIndex(
        (s) => s.uuid === payload.serviceUuid
      );
      const serviceDescriptionIndex = state.services[
        serviceIndex
      ].descriptions.findIndex(
        (s) => s.uuid === payload.serviceDescriptionUuid
      );
      state.services[serviceIndex].descriptions.splice(
        serviceDescriptionIndex,
        1
      );
    },
  },
  actions: {
    fetchServices({ commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/services?";
      if ("pagination" in payload) url += `pagination=${payload.pagination}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            commit("SET_SERVICES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    addService({ commit }, service) {
      return new Promise((resolve, reject) => {
        axios
          .post("/services", service)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_SERVICE", response.data);
              resolve(response);
            } else {
              reject(new Error("Problem while adding the service."));
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateService({ commit }, service) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/services/${service.uuid}`, service, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_SERVICE", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteService({ commit }, serviceUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/services/${serviceUuid}`)
          .then((response) => {
            commit("REMOVE_SERVICE", serviceUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addServiceDescription({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/service_descriptions", {
            service: payload.service["@id"],
            language: payload.language,
            name: payload.name,
            description: payload.description,
          })
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_SERVICE_DESCRIPTION", {
                serviceUuid: payload.service.uuid,
                serviceDescription: response.data,
              });
              resolve(response);
            } else {
              reject(new Error("Problem while adding the service."));
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteServiceDescription({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/service_descriptions/${payload.serviceDescriptionUuid}`)
          .then((response) => {
            commit("REMOVE_SERVICE_DESCRIPTION", {
              serviceUuid: payload.serviceUuid,
              serviceDescriptionUuid: payload.serviceDescriptionUuid,
            });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
