export default [
  //    - Transfers list
  {
    path: "/services/transfers",
    name: "foravila-transfers-list",
    component: () => import("@/views/transfers/list/TransfersList.vue"),
    meta: {
      pageTitle: "Llistat de transfers",
      breadcrumb: [{ text: "Serveis" }, { text: "Transfers", active: true }],
    },
  },
  //    - New transfer
  {
    path: "/services/transfers/new",
    name: "foravila-new-transfer",
    component: () => import("@/views/transfers/new-transfer/NewTransfer.vue"),
    meta: {
      pageTitle: "Nou transfer",
      breadcrumb: [
        { text: "Serveis" },
        { text: "Transfers" },
        { text: "Nou", active: true },
      ],
    },
  },
  //    - Transfer view
  {
    path: "/services/transfers/:transferUuid",
    name: "foravila-transfer-view",
    component: () => import("@/views/transfers/transfer/TransferView.vue"),
    meta: {
      pageTitle: "Detalls del transfer",
      breadcrumb: [
        { text: "Serveis" },
        { text: "Transfers" },
        { text: "Detalls", active: true },
      ],
    },
  },
];
