export default [
  //    - Places list
  {
    path: "/places",
    name: "foravila-places-list",
    component: () => import("@/views/places/list/PlacesList.vue"),
    meta: {
      pageTitle: "Llistat de llocs",
      breadcrumb: [{ text: "Llocs" }, { text: "Llistat", active: true }],
    },
  },
  //    - New place
  {
    path: "/places/new",
    name: "foravila-new-place",
    component: () => import("@/views/places/place/NewPlace.vue"),
  },
  //    - Place view
  {
    path: "/places/:placeUuid",
    name: "foravila-place-view",
    component: () => import("@/views/places/place/PlaceView.vue"),
  },
];
