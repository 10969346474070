import axios from "@/axios";

function findAccount(accounts, code) {
  if (!accounts?.length) {
    return null;
  }
  return accounts.find((account) => account.code === code) || null;
}

export default {
  namespaced: true,
  state: {
    loading: false,
    accounts: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    accounts(state) {
      return state.accounts;
    },
    sales(state, getters) {
      const foundAccount = findAccount(getters.accounts, "SALES");
      return foundAccount;
    },
    vat(state, getters) {
      const foundAccount = findAccount(getters.accounts, "VAT");
      return foundAccount;
    },
    airbnb(state, getters) {
      const foundAccount = findAccount(getters.accounts, "AIRBNB");
      return foundAccount;
    },
    booking(state, getters) {
      const foundAccount = findAccount(getters.accounts, "BOOKING");
      return foundAccount;
    },
    vrbo(state, getters) {
      const foundAccount = findAccount(getters.accounts, "VRBO");
      return foundAccount;
    },
    atraveo(state, getters) {
      const foundAccount = findAccount(getters.accounts, "ATRAVEO");
      return foundAccount;
    },
    holidu(state, getters) {
      const foundAccount = findAccount(getters.accounts, "HOLIDU");
      return foundAccount;
    },
    hometogo(state, getters) {
      const foundAccount = findAccount(getters.accounts, "HOMETOGO");
      return foundAccount;
    },
    locasun(state, getters) {
      const foundAccount = findAccount(getters.accounts, "LOCASUN");
      return foundAccount;
    },
    tripadvisor(state, getters) {
      const foundAccount = findAccount(getters.accounts, "TRIPADVISOR");
      return foundAccount;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.accounts = [];
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_ACCOUNTS(state, payload) {
      state.accounts = payload;
    },
    ADD_ACCOUNT(state, account) {
      state.accounts.push(account);
    },
    UPDATE_ACCOUNT(state, updatedAccount) {
      const accountIndex = state.accounts.findIndex(
        (account) => account.uuid === updatedAccount.uuid
      );
      state.accounts.splice(accountIndex, 1, updatedAccount);
    },
    REMOVE_ACCOUNT(state, accountUuid) {
      const accountIndex = state.accounts.findIndex(
        (account) => account.uuid === accountUuid
      );
      state.accounts.splice(accountIndex, 1);
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    async fetchAccounts({ commit }) {
      commit("SET_LOADING", true);

      try {
        const response = await axios.get("/ledger_accounts");
        commit("SET_ACCOUNTS", response.data["hydra:member"]);
        return response;
        // eslint-disable-next-line no-useless-catch
      } catch (error) {
        // TODO: Log error with Sentry
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchAccountByCode({ commit }, code) {
      if (!code) {
        throw new Error("No code");
      }

      // eslint-disable-next-line no-useless-catch
      try {
        const response = await axios.get(`/ledger_accounts?code=${code}`);
        if (response.status === 200 && response.data["hydra:member"]?.length) {
          return response.data["hydra:member"][0];
        } else {
          const errorMessage = "No matching ledger account found";
          // Log the error with Sentry or any other error tracking service
          console.error(errorMessage);
          throw new Error(errorMessage);
        }
      } catch (error) {
        // TODO: Log error with Sentry
        throw error;
      }
    },
    async addAccount({ commit }, account) {
      try {
        const response = await axios.post("/ledger_accounts", account);
        if (response.status === 201) {
          commit("ADD_ACCOUNT", response.data);
          return response;
        } else {
          const errorMessage = "Problem while adding the account.";
          // Log the error with Sentry or any other error tracking service
          console.error(errorMessage);
          throw new Error(errorMessage);
        }
      } catch (error) {
        // Log the error with Sentry or any other error tracking service
        console.error("Error adding account:", error);
        throw error;
      }
    },
    async updateAccount({ commit }, account) {
      try {
        const response = await axios.patch(
          `/ledger_accounts/${account.uuid}`,
          account,
          {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          }
        );

        if (response.status === 200) {
          commit("UPDATE_ACCOUNT", response.data);
          return response;
        } else {
          const errorMessage =
            "The request was successful, but the received status was not the expected one";
          // Log the error with Sentry or any other error tracking service
          console.error(errorMessage);
          throw new Error(errorMessage);
        }
      } catch (error) {
        // Log the error with Sentry or any other error tracking service
        console.error("Error updating account:", error);
        throw error;
      }
    },
    async deleteAccount({ commit }, accountUuid) {
      try {
        const response = await axios.delete(`/ledger_accounts/${accountUuid}`);
        if (response.status === 204) {
          commit("REMOVE_ACCOUNT", accountUuid);
          return response;
        } else {
          const errorMessage =
            "The request was successful, but the received status was not the expected one";
          // Log the error with Sentry or any other error tracking service
          console.error(errorMessage);
          throw new Error(errorMessage);
        }
      } catch (error) {
        // Log the error with Sentry or any other error tracking service
        console.error("Error deleting account:", error);
        throw error;
      }
    },
  },
};
