import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    startDate: null,
    endDate: null,
    launchDiscountPercentage: null,
    launchDiscountDays: null,
    longStayDiscountPercentage: null,
    longStayDiscountDays: null,
    earlyBookingDiscountPercentage: null,
    earlyBookingDiscountDays: null,
    lastMinuteDiscountPercentage: null,
    lastMinuteDiscountDays: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    startDate(state) {
      return state.startDate;
    },
    endDate(state) {
      return state.endDate;
    },
    launchDiscountPercentage(state) {
      return state.launchDiscountPercentage;
    },
    launchDiscountDays(state) {
      return state.launchDiscountDays;
    },
    longStayDiscountPercentage(state) {
      return state.longStayDiscountPercentage;
    },
    longStayDiscountDays(state) {
      return state.longStayDiscountDays;
    },
    earlyBookingDiscountPercentage(state) {
      return state.earlyBookingDiscountPercentage;
    },
    earlyBookingDiscountDays(state) {
      return state.earlyBookingDiscountDays;
    },
    lastMinuteDiscountPercentage(state) {
      return state.lastMinuteDiscountPercentage;
    },
    lastMinuteDiscountDays(state) {
      return state.lastMinuteDiscountDays;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.startDate = null;
      state.endDate = null;
      state.launchDiscountPercentage = null;
      state.launchDiscountDays = null;
      state.longStayDiscountPercentage = null;
      state.longStayDiscountDays = null;
      state.earlyBookingDiscountPercentage = null;
      state.earlyBookingDiscountDays = null;
      state.lastMinuteDiscountPercentage = null;
      state.lastMinuteDiscountDays = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_START_DATE(state, payload) {
      state.startDate = payload;
    },
    SET_END_DATE(state, payload) {
      state.endDate = payload;
    },
    SET_LAUNCH_DISCOUNT_PERCENTAGE(state, payload) {
      state.launchDiscountPercentage = payload;
    },
    SET_LAUNCH_DISCOUNT_DAYS(state, payload) {
      state.launchDiscountDays = payload;
    },
    SET_LONG_STAY_DISCOUNT_PERCENTAGE(state, payload) {
      state.longStayDiscountPercentage = payload;
    },
    SET_LONG_STAY_DISCOUNT_DAYS(state, payload) {
      state.longStayDiscountDays = payload;
    },
    SET_EARLY_BOOKING_DISCOUNT_PERCENTAGE(state, payload) {
      state.earlyBookingDiscountPercentage = payload;
    },
    SET_EARLY_BOOKING_DISCOUNT_DAYS(state, payload) {
      state.earlyBookingDiscountDays = payload;
    },
    SET_LAST_MINUTE_DISCOUNT_PERCENTAGE(state, payload) {
      state.lastMinuteDiscountPercentage = payload;
    },
    SET_LAST_MINUTE_DISCOUNT_DAYS(state, payload) {
      state.lastMinuteDiscountDays = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    createContract({ state }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/contracts", {
            startDate: state.startDate,
            endDate: state.endDate,
          })
          .then((response) => {
            if (response.status === 201) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
  },
};
