export default [
  //    - Rules list
  {
    path: "/rules",
    name: "foravila-rules-list",
    component: () => import("@/views/rules/list/RulesList.vue"),
    meta: {
      pageTitle: "Llistat de normes",
      breadcrumb: [{ text: "Normes" }, { text: "Llistat", active: true }],
    },
  },
  //    - New rule
  {
    path: "/rules/new",
    name: "foravila-new-rule",
    component: () => import("@/views/rules/rule/NewRule.vue"),
  },
  //    - Rule view
  {
    path: "/rules/:ruleUuid",
    name: "foravila-rule-view",
    component: () => import("@/views/rules/rule/RuleView.vue"),
  },
];
