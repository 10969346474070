export default [
  {
    path: "/login",
    name: "foravila-login",
    component: () => import("@/views/security/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      mustBeLoggedOut: true,
    },
  },
  {
    path: "/logout",
    name: "foravila-logout",
    component: () => import("@/views/security/Logout.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/security/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      mustBeLoggedOut: true,
    },
  },
  {
    path: "/not-authorized",
    name: "not-authorized",
    component: () => import("@/views/security/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/not-found",
    name: "not-found",
    component: () => import("@/views/security/NotFound.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
];
