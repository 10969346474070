export default [
  //    - Incidences list
  {
    path: "/incidences",
    name: "foravila-incidences-list",
    component: () => import("@/views/incidences/list/IncidencesList.vue"),
    meta: {
      pageTitle: "Llistat de incidències",
      breadcrumb: [{ text: "Incidències" }, { text: "Llistat", active: true }],
    },
  },
  //    - Incidence view
  {
    path: "/incidences/:incidenceUuid",
    name: "foravila-incidence-view",
    component: () => import("@/views/incidences/incidence/IncidenceView.vue"),
    meta: {
      pageTitle: "Detalls de la incidència",
      breadcrumb: [
        { text: "Incidències" },
        { text: "Incidència", active: true },
      ],
    },
  },
  //    - New incidence
  {
    path: "/incidences/new",
    name: "foravila-new-incidence",
    component: () =>
      import("@/views/incidences/new-incidence/NewIncidence.vue"),
    meta: {
      pageTitle: "Nova incidència",
      breadcrumb: [{ text: "Incidències" }, { text: "Nova", active: true }],
    },
  },
];
