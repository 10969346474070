import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    conversations: [],
    conversation: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    conversations(state) {
      return state.conversations;
    },
    conversation(state) {
      return state.conversation;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.conversations = [];
      state.conversation = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CONVERSATION(state, payload) {
      state.conversation = payload;
    },
    SET_CONVERSATIONS(state, payload) {
      state.conversations = payload;
    },
    ADD_CONVERSATION(state, conversation) {
      if (!conversation) return;
      state.conversations.push(conversation);
    },
    UPDATE_CONVERSATION(state, conversation) {
      if (!conversation) return;
      state.conversation = conversation;
      if (!state.conversations.length) return;
      const conversationIndex = state.conversations.findIndex(
        (item) => item.uuid === conversation.uuid
      );
      if (conversationIndex === -1) return;
      state.conversations.splice(conversationIndex, 1, conversation);
    },
    REMOVE_CONVERSATION(state, conversationUuid) {
      if (!conversationUuid) return;
      const conversationIndex = state.conversations.findIndex(
        (item) => item.uuid === conversationUuid
      );
      if (conversationIndex === -1) return;
      state.conversations.splice(conversationIndex, 1);
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchConversations({ commit }, payload = { pagination: false }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);

        // const today = new Date().toISOString().split("T")[0];
        // let url = `/conversations?pickupDate[after]=${today}`;

        let url = `/whats_app_conversations?order[updatedAt]=desc`;
        if ("pagination" in payload) url += `&pagination=${payload.pagination}`;

        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_CONVERSATIONS", response.data["hydra:member"]);
              resolve(response);
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the conversations",
                })
              );
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchConversation({ commit }, conversationUuid) {
      return new Promise((resolve, reject) => {
        if (!conversationUuid) reject(new Error("No conversationUuid"));

        commit("SET_LOADING", true);
        axios
          .get(`/whats_app_conversations/${conversationUuid}`)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_CONVERSATION", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    updateConversation({ commit }, conversation) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .patch(
            `/whats_app_conversations/${conversation.uuid}`,
            conversation,
            {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_CONVERSATION", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    deleteConversation({ commit }, conversationUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/whats_app_conversations/${conversationUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_CONVERSATION", conversationUuid);
              resolve(response);
            } else
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
          })
          .catch(() =>
            reject(
              new Error(
                "An error occurred while trying to delete de accommodation"
              )
            )
          );
      });
    },
  },
};
