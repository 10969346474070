export default [
  //    - Police reports
  {
    path: "/administration/police-reports",
    name: "foravila-administration-police-reports",
    component: () =>
      import("@/views/administration/police-reports/PoliceReports.vue"),
    meta: {
      pageTitle: "Control d'informes policials",
      breadcrumb: [
        { text: "Administració" },
        { text: "Policia", active: true },
      ],
    },
  },
  //    - Hospejades reports
  {
    path: "/administration/hospedajes-reports",
    name: "foravila-administration-hospedajes-reports",
    component: () =>
      import("@/views/administration/hospedajes-reports/HospedajesReports.vue"),
    meta: {
      pageTitle: "Control d'hospedajes",
      breadcrumb: [
        { text: "Administració" },
        { text: "Hospedajes", active: true },
      ],
    },
  },
  //    - AEAT reports
  {
    path: "/administration/aeat-reports",
    name: "foravila-administration-aeat-reports",
    component: () =>
      import("@/views/administration/aeat-reports/AEATReports.vue"),
    meta: {
      pageTitle: "Control d'informes AEAT",
      breadcrumb: [{ text: "Administració" }, { text: "AEAT", active: true }],
    },
  },
];
