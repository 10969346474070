import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    rules: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    rules(state) {
      return state.rules;
    },
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_RULES(state, payload) {
      state.rules = payload;
    },
    ADD_RULE(state, rule) {
      state.rules.push(rule);
    },
    UPDATE_RULE(state, rule) {
      const ruleIndex = state.rules.findIndex((s) => s.uuid === rule.uuid);
      state.rules.splice(ruleIndex, 1, rule);
    },
    REMOVE_RULE(state, ruleUuid) {
      const ruleIndex = state.rules.findIndex((s) => s.uuid === ruleUuid);
      state.rules.splice(ruleIndex, 1);
    },
    ADD_RULE_DESCRIPTION(state, payload) {
      const ruleIndex = state.rules.findIndex(
        (s) => s.uuid === payload.ruleUuid
      );
      state.rules[ruleIndex].descriptions.push(payload.ruleDescription);
    },
    SET_RULE_ACCOMMODATIONS(state, payload) {
      const ruleIndex = state.rules.findIndex(
        (s) => s.uuid === payload.ruleUuid
      );
      state.rules[ruleIndex].accommodations = payload.accommodations;
    },
    REMOVE_RULE_DESCRIPTION(state, payload) {
      const ruleIndex = state.rules.findIndex(
        (s) => s.uuid === payload.ruleUuid
      );
      const ruleDescriptionIndex = state.rules[
        ruleIndex
      ].descriptions.findIndex((s) => s.uuid === payload.ruleDescriptionUuid);
      state.rules[ruleIndex].descriptions.splice(ruleDescriptionIndex, 1);
    },
    REMOVE_RULE_ACCOMMODATION(state, payload) {
      const ruleIndex = state.rules.findIndex(
        (s) => s.uuid === payload.ruleUuid
      );
      const ruleAccommodationIndex = state.rules[
        ruleIndex
      ].accommodations.findIndex(
        (s) => s.uuid === payload.accommodationRuleUuid
      );

      state.rules[ruleIndex].accommodations.splice(ruleAccommodationIndex, 1);
    },
  },
  actions: {
    fetchRules({ commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/rules?";
      if ("pagination" in payload) url += `pagination=${payload.pagination}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            commit("SET_RULES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    addRule({ commit }, rule) {
      return new Promise((resolve, reject) => {
        axios
          .post("/rules", rule)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_RULE", response.data);
              resolve(response);
            } else {
              reject(new Error("Problem while adding the rule."));
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateRule({ commit }, rule) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/rules/${rule.uuid}`, rule, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_RULE", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteRule({ commit }, ruleUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/rules/${ruleUuid}`)
          .then((response) => {
            commit("REMOVE_RULE", ruleUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addRuleDescription({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/rule_descriptions", {
            rule: payload.rule["@id"],
            language: payload.language,
            name: payload.name,
            description: payload.description,
          })
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_RULE_DESCRIPTION", {
                ruleUuid: payload.rule.uuid,
                ruleDescription: response.data,
              });
              resolve(response);
            } else {
              reject(new Error("Problem while adding the rule."));
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteRuleDescription({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/rule_descriptions/${payload.ruleDescriptionUuid}`)
          .then((response) => {
            commit("REMOVE_RULE_DESCRIPTION", {
              ruleUuid: payload.ruleUuid,
              ruleDescriptionUuid: payload.ruleDescriptionUuid,
            });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addRuleAccommodations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/multiple_rule_accommodations", {
            rule: payload.rule["@id"],
            accommodations: payload.accommodations,
          })
          .then((response) => {
            if (response.status === 201) {
              commit("SET_RULE_ACCOMMODATIONS", {
                ruleUuid: payload.rule.uuid,
                accommodations: response.data,
              });
              resolve(response.data);
            } else {
              reject(
                new Error("Problem while adding the rule's accommodations.")
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteRuleAccommodation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accommodation_rules/${payload.accommodationRuleUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_RULE_ACCOMMODATION", {
                ruleUuid: payload.ruleUuid,
                accommodationRuleUuid: payload.accommodationRuleUuid,
              });
              resolve(response);
            } else {
              reject(
                new Error("Problem while removing the rule's accommodation.")
              );
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
