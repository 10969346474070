import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    owner: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    owner(state) {
      return state.owner;
    },
    bookings(state, getters) {
      if (!getters.owner?.bookings?.length) {
        return [];
      }

      return getters.owner?.bookings;
    },
    comissionBookings(state, getters) {
      if (!getters.bookings?.length) {
        return [];
      }

      return getters.bookings.filter(
        (booking) => booking.contract?.billingType === "COMISSION"
      );
    },
    suburanceBookings(state, getters) {
      if (!getters.bookings?.length) {
        return [];
      }

      return getters.bookings.filter(
        (booking) => booking.contract?.billingType === "SUBURANCE"
      );
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.owner = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_OWNER(state, payload) {
      state.owner = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchOwner({ commit }, ownerUuid) {
      return new Promise((resolve, reject) => {
        if (!ownerUuid) reject(new Error("No ownerUuid"));

        commit("SET_LOADING", true);
        axios
          .get(`/owners/${ownerUuid}`)
          .then((response) => {
            commit("SET_OWNER", response.data);
            // The promise returns the owner data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    createOwner({ commit }, owner) {
      return new Promise((resolve, reject) => {
        axios
          .post("/owners", owner)
          .then((response) => {
            if (response.status === 201) {
              commit("owners/ADD_OWNER", response.data, { root: true });
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateOwner({ commit }, owner) {
      if (!owner || !owner.uuid) {
        return null;
      }

      return new Promise((resolve, reject) => {
        axios
          .patch(`/owners/${owner.uuid}`, owner, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_OWNER", response.data);
              resolve(response.data);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            // TODO: log the error with Sentry
            reject(error);
          });
      });
    },
  },
};
