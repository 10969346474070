import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    policies: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    policies(state) {
      return state.policies;
    },
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_POLICIES(state, payload) {
      state.policies = payload;
    },
    ADD_POLICY(state, policy) {
      state.policies.push(policy);
    },
    UPDATE_POLICY(state, policy) {
      const policyIndex = state.policies.findIndex(
        (p) => p.uuid === policy.uuid
      );
      if (policyIndex !== -1) state.policies.splice(policyIndex, 1, policy);
    },
    REMOVE_POLICY(state, policyUuid) {
      const policyIndex = state.policies.findIndex(
        (p) => p.uuid === policyUuid
      );
      if (policyIndex !== -1) state.policies.splice(policyIndex, 1);
    },
  },
  actions: {
    fetchPolicies({ commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/booking_policies?";
      if ("pagination" in payload) url += `pagination=${payload.pagination}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            commit("SET_POLICIES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: log error to Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchPolicy(context, policyUuid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/booking_policies/${policyUuid}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    addPolicy({ commit }, policy) {
      return new Promise((resolve, reject) => {
        axios
          .post("/booking_policies", policy)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_POLICY", response.data);
              resolve(response.data);
            } else {
              reject(new Error("Problem while adding the policy."));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updatePolicy({ commit }, policy) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/booking_policies/${policy.uuid}`, policy, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_POLICY", response.data);
              resolve(response.data);
            } else {
              reject(new Error("An error occurred while updating the policy"));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateDefaultPolicy({ getters, commit }, defaultPolicyUuid) {
      return new Promise((resolve, reject) => {
        let successfulRequestsCount = 0;
        const { policies } = getters;

        if (policies && policies.length > 0) {
          const promises = [];

          // Loop between the booking policies
          for (let i = 0; i < policies.length; i += 1) {
            // Copy of the current policy.
            const currentPolicy = policies[i];

            if (currentPolicy.uuid !== defaultPolicyUuid) {
              // It is not the selected default policy, update it as not default.
              promises.push(
                axios
                  .patch(
                    `/booking_policies/${currentPolicy.uuid}`,
                    { defaultPolicy: false },
                    {
                      headers: {
                        "Content-Type": "application/merge-patch+json",
                      },
                    }
                  )
                  // eslint-disable-next-line no-loop-func
                  .then((response) => {
                    if (response.status === 200) {
                      successfulRequestsCount += 1;
                      commit("UPDATE_POLICY", response.data);
                    }
                  })
              );
            }
          }

          Promise.all(promises).then(() => {
            if (successfulRequestsCount === policies.length - 1) {
              resolve({
                message: "All policies successfully updated",
              });
            } else {
              reject(
                new Error(
                  "Not all the selected policies have been updated, try it again"
                )
              );
            }
          });
        } else {
          reject(new Error("there are no policies to update"));
        }
      });
    },
    deletePolicy({ commit }, policyUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/booking_policies/${policyUuid}`)
          .then((response) => {
            commit("REMOVE_POLICY", policyUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // async assignPolicies(context, payload) {
    //   function fromatDate(date) {
    //     const d = new Date(date)
    //     let month = `${d.getMonth() + 1}`
    //     let day = `${d.getDate()}`
    //     const year = d.getFullYear()

    //     if (month.length < 2) month = `0${month}`
    //     if (day.length < 2) day = `0${day}`

    //     return [year, month, day].join('-')
    //   }

    //   const promise = new Promise(async (resolve, reject) => {
    //     if (
    //       payload.selectedAccommodations.length > 0
    //     && payload.startDate
    //     && payload.endDate
    //     ) {
    //       axios
    //         .post('/rates/assign-policy-to-range', {
    //           startDate: fromatDate(payload.startDate),
    //           endDate: fromatDate(payload.endDate),
    //           bookingPolicyId: payload.policy.id,
    //           accommodationsSlugs: payload.selectedAccommodations,
    //         })
    //         .then(() => resolve())
    //         .catch(() => {
    //           reject(new Error('An error occurred'))
    //         })
    //     } else {
    //       reject(new Error('selectedAccommodations is a mandatory parameter and it can not be empty'))
    //     }
    //   })

    //   return await promise
    // },
  },
};
