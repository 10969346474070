import axios from "@/axios";

const LOCAL_STORAGE_KEY = "foravilaAdminUserData";

export default {
  namespaced: true,
  getters: {
    loggedUserData() {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "{}");
    },
    isUserLoggedIn(state, getters) {
      return !!getters.loggedUserData.email;
    },
    loggedUserEmail(state, getters) {
      return getters.loggedUserData.email || null;
    },
    loggedUserRole(state, getters) {
      return getters.loggedUserData.role || null;
    },
    isAdmin(state, getters) {
      return ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"].includes(
        getters.loggedUserRole
      );
    },
    isManager(state, getters) {
      return ["ROLE_MANAGER"].includes(getters.loggedUserRole);
    },
  },
  actions: {
    loginBasic(context, loginInfo) {
      return new Promise((resolve, reject) => {
        axios
          .post("/login", {
            email: loginInfo.email,
            password: loginInfo.password,
          })
          .then((response) => {
            // If there's user data in response
            if (response.status === 204) {
              resolve(response);
            } else {
              reject(new Error({ message: "Email o Clau incorrectes" }));
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              reject(new Error({ message: "Email o Clau incorrectes" }));
            } else {
              reject(new Error(error));
            }
          });
      });
    },

    resetBasic({ dispatch }) {
      // Reset the userInfo on the local storage
      if (localStorage.getItem(LOCAL_STORAGE_KEY)) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }

      // Reset all the stores
      dispatch("resetAppData", null, { root: true });
    },

    checkLoggedIn() {
      return new Promise((resolve, reject) => {
        axios
          .get("/me")
          .then((response) => {
            if (response.status === 200) resolve(true);
            else resolve(false);
          })
          .catch((error) => reject(new Error(error)));
      });
    },

    logout({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/logout")
          .then((response) => {
            dispatch("resetBasic");
            resolve(response.data);
          })
          .catch((error) => reject(new Error(error)));
      });
    },
  },
};
