import axios from "@/axios";
import { formatDateObjectToDatabaseDate } from "@/utils/formatters";

export default {
  namespaced: true,
  state: {
    loading: false,
    payments: [],
    count: 0,
    itemsPerPage: 20,
    firstPage: 1,
    lastPage: 1,
    previousPage: null,
    nextPage: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    payments(state) {
      return state.payments;
    },
    count(state) {
      return state.count;
    },
    itemsPerPage(state) {
      return state.itemsPerPage;
    },
    firstPage(state) {
      return state.firstPage;
    },
    lastPage(state) {
      return state.lastPage;
    },
    previousPage(state) {
      return state.previousPage;
    },
    nextPage(state) {
      return state.nextPage;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.payments = [];
      state.count = 0;
      state.itemsPerPage = 20;
      state.firstPage = 1;
      state.lastPage = 1;
      state.previousPage = null;
      state.nextPage = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_PAYMENTS(state, payload) {
      state.payments = payload;
    },
    SET_COUNT(state, payload) {
      state.count = payload;
    },
    SET_ITEMS_PER_PAGE(state, payload) {
      state.itemsPerPage = payload;
    },
    SET_FIRST_PAGE(state, payload) {
      state.firstPage = payload;
    },
    SET_LAST_PAGE(state, payload) {
      state.lastPage = payload;
    },
    SET_PREVIOUS_PAGE(state, payload) {
      state.previousPage = payload;
    },
    SET_NEXT_PAGE(state, payload) {
      state.nextPage = payload;
    },
    UPDATE_PAYMENT(state, payment) {
      if (!state.payments.length || !payment) return;
      const paymentIndex = state.payments.findIndex(
        (c) => c.uuid === payment.uuid
      );
      if (paymentIndex === -1) return;
      state.payments.splice(paymentIndex, 1, payment);
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchPayments(
      { commit },
      payload = { pagination: false, minDate: null, maxDate: null }
    ) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);

        let url = "/client_payments?";

        // Set the url params
        const urlParams = [];

        // Set the pagination parameter
        urlParams.push(
          `pagination=${"pagination" in payload ? payload.pagination : true}`
        );

        // Set the minimum date
        if ("minDate" in payload)
          urlParams.push(`date[after]=${payload.minDate}+00:00:00`);

        // Set the maximum date
        if ("maxDate" in payload)
          urlParams.push(`date[before]=${payload.maxDate}+23:59:59`);

        // Set the order
        urlParams.push(`order[date]=asc`);

        if (urlParams.length) url += urlParams.join("&");

        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_PAYMENTS", response.data["hydra:member"]);
              resolve();
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the client payments",
                })
              );
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchFilteredPayments({ state, commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/filtered-client-payments?";
      if ("pagination" in payload) url += `&pagination=${payload.pagination}`;
      if ("itemsPerPage" in payload)
        url += `&itemsPerPage=${payload.itemsPerPage || state.itemsPerPage}`;
      if ("page" in payload) url += `&page=${payload.page}`;
      if ("searchText" in payload) url += `&searchText=${payload.searchText}`;
      if ("sort" in payload && payload.sort.key && payload.sort.order) {
        let sortKey = payload.sort.key;
        const sortOrder = payload.sort.order;
        if (sortKey === "sortDate") sortKey = "date";
        url += `&sortKey=${sortKey}&sortOrder=${sortOrder}`;
      } else {
        url += "&sortKey=date&sortOrder=desc";
      }

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_PAYMENTS", response.data["hydra:member"]);
              commit("SET_COUNT", response.data["hydra:totalItems"]);
              commit(
                "SET_FIRST_PAGE",
                response.data["hydra:view"]["hydra:first"]
              );
              commit(
                "SET_LAST_PAGE",
                response.data["hydra:view"]["hydra:last"]
              );
              commit(
                "SET_PREVIOUS_PAGE",
                response.data["hydra:view"]["hydra:previous"]
              );
              commit(
                "SET_NEXT_PAGE",
                response.data["hydra:view"]["hydra:next"]
              );
              resolve(response.data["hydra:member"]);
            } else {
              reject(new Error("Error while fetching all the bookings"));
            }
          })
          .catch((error) => {
            reject(error.response);
            // TODO: log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchVerifiedPayments(
      { commit },
      payload = { pagination: false, minDate: null, maxDate: null }
    ) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);

        let url = "/client_payments?";

        // Set the url params
        const urlParams = [];

        // Set the pagination parameter
        urlParams.push(
          `pagination=${"pagination" in payload ? payload.pagination : true}`
        );

        // Set the minimum date
        if ("minDate" in payload)
          urlParams.push(`verifiedAt[after]=${payload.minDate}+00:00:00`);

        // Set the maximum date
        if ("maxDate" in payload)
          urlParams.push(`verifiedAt[before]=${payload.maxDate}+23:59:59`);

        // Set the status to check
        urlParams.push(`status[]=VERIFIED`);
        urlParams.push(`status[]=ACCOUNTED`);

        // Set the method to check
        // TODO: ADD custom filters for not equal: https://github.com/api-platform/docs/blob/main/core/filters.md#creating-custom-filters
        // urlParams.push(`method[]=BANK_TRANSFER`);
        // urlParams.push(`method[]=CREDIT_CARD`);
        // urlParams.push(`method[]=VIRTUAL_CREDIT_CARD`);
        // urlParams.push(`method[]=CASH`);
        // urlParams.push(`method[]=PAYPAL`);
        // urlParams.push(`method[]=BIZUM`);
        // urlParams.push(`method[]=SOFORT`);
        // urlParams.push(`method[]=SOURCE_PAYMENT`);
        // urlParams.push(`method[]=OTHER`);

        // Set the order
        urlParams.push(`order[date]=asc`);

        if (urlParams.length) url += urlParams.join("&");

        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_PAYMENTS", response.data["hydra:member"]);
              resolve();
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the client payments",
                })
              );
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchUnverifiedPayments({ commit }, payload = { pagination: false }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);

        let url = "/client_payments?";

        // Set the url params
        const urlParams = [];

        // Set the pagination parameter
        urlParams.push(
          `pagination=${"pagination" in payload ? payload.pagination : true}`
        );

        // Set the status to check
        // TODO: ADD custom filters for not equal: https://github.com/api-platform/docs/blob/main/core/filters.md#creating-custom-filters
        urlParams.push(`status[]=SCHEDULED`);
        urlParams.push(`status[]=PRE_CONFIRMED`);
        urlParams.push(`status[]=CONFIRMED`);
        urlParams.push(`status[]=OTHER`);

        // Set the method to check
        // TODO: ADD custom filters for not equal: https://github.com/api-platform/docs/blob/main/core/filters.md#creating-custom-filters
        // urlParams.push(`method[]=BANK_TRANSFER`);
        // urlParams.push(`method[]=CREDIT_CARD`);
        // urlParams.push(`method[]=VIRTUAL_CREDIT_CARD`);
        // urlParams.push(`method[]=SECURITY_DEPOSIT`);
        // urlParams.push(`method[]=CASH`);
        // urlParams.push(`method[]=PAYPAL`);
        // urlParams.push(`method[]=BIZUM`);
        // urlParams.push(`method[]=SOFORT`);
        // urlParams.push(`method[]=SOURCE_PAYMENT`);
        // urlParams.push(`method[]=OTHER`);

        // Set the order
        urlParams.push(`order[date]=asc`);

        if (urlParams.length) url += urlParams.join("&");

        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_PAYMENTS", response.data["hydra:member"]);
              resolve();
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the client payments",
                })
              );
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchPendingVirtualCardPayments(
      { commit },
      payload = { pagination: false }
    ) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);

        let url = "/client_payments?";

        // Set the url params
        const urlParams = [];

        // Set the pagination parameter
        urlParams.push(
          `pagination=${"pagination" in payload ? payload.pagination : true}`
        );

        // Set the status to check
        urlParams.push(`status[]=PRE_CONFIRMED`);

        // Set the method to check
        urlParams.push(`method[]=VIRTUAL_CREDIT_CARD`);

        // Set today as max date
        const todayDate = formatDateObjectToDatabaseDate(new Date());
        urlParams.push(`date[before]=${todayDate}+23:59:59`);

        // Set the order
        urlParams.push(`order[date]=asc`);

        if (urlParams.length) url += urlParams.join("&");

        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_PAYMENTS", response.data["hydra:member"]);
              resolve();
            } else {
              reject(
                new Error({
                  status: response.status,
                  message:
                    "Error while fetching the virtual card pending client payments",
                })
              );
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    updatePayment({ commit }, payment) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/client_payments/${payment.uuid}`, payment, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_PAYMENT", response.data);
              resolve();
            } else {
              reject(response);
              // TODO: Log error in Sentry
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
  },
};
