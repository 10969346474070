export default [
  //    - Cities list
  {
    path: "/cities",
    name: "foravila-cities-list",
    component: () => import("@/views/cities/list/CitiesList.vue"),
    meta: {
      pageTitle: "Llistat de pobles",
      breadcrumb: [{ text: "Pobles" }, { text: "Llistat", active: true }],
    },
  },
  //    - New city
  {
    path: "/cities/new",
    name: "foravila-new-city",
    component: () => import("@/views/cities/city/NewCity.vue"),
  },
  //    - City view
  {
    path: "/cities/:cityUuid",
    name: "foravila-city-view",
    component: () => import("@/views/cities/city/CityView.vue"),
  },
];
