import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    incidence: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    incidence(state) {
      return state.incidence;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.incidence = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_INCIDENCE(state, payload) {
      state.incidence = payload;
    },
    ADD_COMMENT(state, incidenceComment) {
      state.incidence.comments.push(incidenceComment);
    },
    REMOVE_COMMENT(state, incidenceComment) {
      const commentIndex = state.incidence.comments.findIndex(
        (comment) => comment.uuid === incidenceComment.uuid
      );
      state.incidence.comments.splice(commentIndex, 1);
    },
    // ADD_COMMENT_PICTURE(state, payload) {
    //   if (!payload.comment || !payload.picture) return;
    //   const commentIndex = state.comments.findIndex((comment) => comment.uuid === payload.comment);
    //   if (commentIndex !== -1) state.faqs[faqIndex].pictures.push(payload.picture);
    // },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchIncidence({ commit }, incidenceUuid) {
      return new Promise((resolve, reject) => {
        if (!incidenceUuid) reject(new Error("No incidenceUuid"));

        commit("SET_LOADING", true);
        axios
          .get(`/booking_incidences/${incidenceUuid}`)
          .then((response) => {
            commit("SET_INCIDENCE", response.data);
            // The promise returns the incidence data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    updateIncidence({ commit }, incidence) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/booking_incidences/${incidence.uuid}`, incidence, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("SET_INCIDENCE", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    addComment({ commit }, incidenceComment) {
      return new Promise((resolve, reject) => {
        axios
          .post("/booking_incidence_comments", incidenceComment)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_COMMENT", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteComment({ commit }, comment) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/booking_incidence_comments/${comment.uuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_COMMENT", comment);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch(() =>
            reject(
              new Error("An error occurred while trying to delete the comment")
            )
          );
      });
    },
    // addCommentPictures({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     if (!payload?.incidenceUuid) {
    //       reject(new Error("The incidenceUuid is mandatory"));
    //       return;
    //     }
    //     if (!payload?.commentUuid) {
    //       reject(new Error("The commentUuid is mandatory"));
    //       return;
    //     }
    //     if (!payload?.pictures?.length) {
    //       reject(new Error("There must be at least one picture to add"));
    //       return;
    //     }

    //     let successfulRequestsCount = 0;
    //     const promises = [];

    //     payload.pictures.forEach((picture) => {
    //       promises.push(
    //         axios
    //           .post("/incidence_comment_pictures", {
    //             incidenceUuid: payload.incidenceUuid,
    //             commentUuid: payload.commentUuid,
    //             picture,
    //           })
    //           .then((response) => {
    //             if (response.status === 201) {
    //               successfulRequestsCount += 1;
    //               commit("ADD_COMMENT_PICTURE", {
    //                 picture: response.data.picture,
    //                 comment: response.data.comment,
    //               });
    //             }
    //           })
    //       );
    //     });

    //     Promise.all(promises).then(() => {
    //       if (successfulRequestsCount < payload.pictures.length) {
    //         reject(
    //           new Error("Some pictures has been not added, please try again")
    //         );
    //         return;
    //       }
    //       resolve({
    //         message: "All pictures successfully added",
    //       });
    //     });
    //   });
    // },
  },
};
