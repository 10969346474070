export default [
  //    - Owners list
  {
    path: "/owners",
    name: "foravila-owners-list",
    component: () => import("@/views/owners/list/OwnersList.vue"),
    meta: {
      pageTitle: "Llistat de propietaris",
      breadcrumb: [{ text: "Propietaris" }, { text: "Llistat", active: true }],
    },
  },
  //    - New owner
  {
    path: "/owners/new",
    name: "foravila-new-owner",
    component: () => import("@/views/owners/new-owner/NewOwner.vue"),
    meta: {
      pageTitle: "Nou propietari",
      breadcrumb: [{ text: "Propietaris" }, { text: "Nou", active: true }],
    },
  },
  //    - Owner view
  {
    path: "/owners/:ownerUuid",
    name: "foravila-owner-view",
    component: () => import("@/views/owners/owner/OwnerView.vue"),
    meta: {
      pageTitle: "Detalls del propietari",
      breadcrumb: [
        { text: "Propietaris" },
        { text: "Propietari", active: true },
      ],
    },
  },
];
