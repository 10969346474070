export default [
  //    - Real estate accommodations list
  {
    path: "/real-estate/accommodations",
    name: "foravila-real-estate-accommodations-list",
    component: () =>
      import(
        "@/views/real-estate-accommodations/list/RealEstateAccommodationsList.vue"
      ),
    meta: {
      pageTitle: "Allotjaments en venda",
      breadcrumb: [
        { text: "Inici", to: { name: "foravila-home" } },
        {
          text: "Allotjaments en venda",
          to: { name: "foravila-real-estate-accommodations-list" },
        },
        { text: "Llistat", active: true },
      ],
    },
  },
  //    - New accommodation
  {
    path: "/real-estate/accommodations/new",
    name: "foravila-new-real-estate-accommodation",
    component: () =>
      import(
        "@/views/real-estate-accommodations/new-real-estate-accommodation/NewRealEstateAccommodation.vue"
      ),
    meta: {
      pageTitle: "Nou allotjament en venda",
      breadcrumb: [
        { text: "Inici", to: { name: "foravila-home" } },
        {
          text: "Allotjaments en venda",
          to: { name: "foravila-real-estate-accommodations-list" },
        },
        { text: "Nou", active: true },
      ],
    },
  },
  //    - Real estate accommodation view
  {
    path: "/real-estate/accommodations/:accommodationUuid",
    name: "foravila-real-estate-accommodation-view",
    component: () =>
      import(
        "@/views/real-estate-accommodations/real-estate-accommodation/RealEstateAccommodationView.vue"
      ),
    meta: {
      pageTitle: "Allotjament en venda",
      breadcrumb: [
        { text: "Inici", to: { name: "foravila-home" } },
        {
          text: "Allotjaments en venda",
          to: { name: "foravila-real-estate-accommodations-list" },
        },
        { text: "Allotjament", active: true },
      ],
    },
  },
];
