import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    incidences: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    incidences(state) {
      return state.incidences;
    },
    openIncidences(state) {
      if (!state.incidences?.length) return [];
      return state.incidences.filter(
        (incidence) => incidence.status === "OPEN"
      );
    },
    pendingIncidences(state) {
      if (!state.incidences?.length) return [];
      return state.incidences.filter(
        (incidence) => incidence.status === "PENDING"
      );
    },
    closedIncidences(state) {
      if (!state.incidences?.length) return [];
      return state.incidences.filter(
        (incidence) => incidence.status === "CLOSED"
      );
    },
    activeIncidences(state) {
      if (!state.incidences?.length) return [];
      return state.incidences.filter((incidence) =>
        ["OPEN", "PENDING"].includes(incidence.status)
      );
    },
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_INCIDENCES(state, payload) {
      state.incidences = payload;
    },
    ADD_INCIDENCE(state, incidence) {
      state.incidences.push(incidence);
    },
    UPDATE_INCIDENCE(state, incidence) {
      const incidenceIndex = state.incidences.findIndex(
        (item) => item.uuid === incidence.uuid
      );
      state.incidences.splice(incidenceIndex, 1, incidence);
    },
    REMOVE_INCIDENCE(state, incidenceUuid) {
      const incidenceIndex = state.incidences.findIndex(
        (item) => item.uuid === incidenceUuid
      );
      state.incidences.splice(incidenceIndex, 1);
    },
  },
  actions: {
    fetchIncidences({ commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/booking_incidences?";

      const urlParams = [];
      if ("pagination" in payload)
        urlParams.push(`pagination=${payload.pagination}`);
      if ("status" in payload) {
        if (Array.isArray(payload.status)) {
          payload.status.forEach((status) => {
            urlParams.push(`status[]=${status}`);
          });
        } else {
          urlParams.push(`status=${payload.status}`);
        }
      }

      if (urlParams.length) url += urlParams.join("&");

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            commit("SET_INCIDENCES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    addIncidence({ commit }, incidence) {
      return new Promise((resolve, reject) => {
        axios
          .post("/booking_incidences", incidence)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_INCIDENCE", response.data);
              resolve(response);
            } else {
              reject(new Error("Problem while adding the incidence."));
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateIncidence({ commit }, incidence) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/booking_incidences/${incidence.uuid}`, incidence, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_INCIDENCE", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteIncidence({ commit }, incidenceUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/booking_incidences/${incidenceUuid}`)
          .then((response) => {
            commit("REMOVE_INCIDENCE", incidenceUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
