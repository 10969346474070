export default [
  //    - PAYMENTS: Daily cash
  {
    path: "/accounting/payments/daily-cash",
    name: "foravila-accounting-payments-daily-cash",
    component: () =>
      import("@/views/accounting/payments/daily-cash/DailyCash.vue"),
    meta: {
      pageTitle: "Tresoreria diària",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Pagaments" },
        { text: "Tresoreria diària", active: true },
      ],
    },
  },
  //    - PAYMENTS: All payments
  {
    path: "/accounting/payments",
    name: "foravila-accounting-payments-all",
    component: () =>
      import("@/views/accounting/payments/all-payments/AllPayments.vue"),
    meta: {
      pageTitle: "Llistat de pagaments",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Pagaments" },
        { text: "Llistat", active: true },
      ],
    },
  },
  //    - PAYMENTS: Verify payments
  {
    path: "/accounting/payments/verify",
    name: "foravila-accounting-payments-verify",
    component: () =>
      import("@/views/accounting/payments/verify-payments/VerifyPayments.vue"),
    meta: {
      pageTitle: "Verificació de pagaments",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Pagaments" },
        { text: "Verificació", active: true },
      ],
    },
  },
  //    - PAYMENTS: Virtual cards
  {
    path: "/accounting/payments/virtual-cards",
    name: "foravila-accounting-payments-virtual-cards",
    component: () =>
      import("@/views/accounting/payments/virtual-cards/VirtualCards.vue"),
    meta: {
      pageTitle: "Targetes virtuals",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Pagaments" },
        { text: "Targetes virtuals", active: true },
      ],
    },
  },
  //    - BILLING: pending
  {
    path: "/accounting/billing/pending",
    name: "foravila-accounting-billing-pending",
    component: () =>
      import("@/views/accounting/billing/pending/PendingBillingList.vue"),
    meta: {
      pageTitle: "Factures pendents",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Facturació" },
        { text: "Factures pendents", active: true },
      ],
    },
  },
  //    - BILLING: details
  {
    path: "/accounting/billing/:localizator",
    name: "foravila-accounting-billing-details",
    component: () =>
      import("@/views/accounting/billing/details/BillingDetails.vue"),
    meta: {
      pageTitle: "Facturació de reserva",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Facturació" },
        { text: "Reserva", active: true },
      ],
    },
  },
  //    - BILLING: preview
  {
    path: "/accounting/billing/:localizator/preview",
    name: "foravila-accounting-billing-preview",
    component: () =>
      import("@/views/accounting/billing/details/BillingDetailsPreview.vue"),
    meta: {
      pageTitle: "Facturació de reserva",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Facturació" },
        { text: "Reserva", active: true },
      ],
    },
  },
  //    - BILLING: invoices list
  {
    path: "/accounting/billing/invoices",
    name: "foravila-accounting-billing-invoices-list",
    component: () =>
      import("@/views/accounting/billing/invoices/InvoicesList.vue"),
    meta: {
      pageTitle: "Factures emeses",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Facturació" },
        { text: "Factures emeses", active: true },
      ],
    },
  },
  //    - BILLING: invoice details
  {
    path: "/accounting/billing/invoices/:invoiceUuid",
    name: "foravila-accounting-billing-invoice-details",
    component: () =>
      import("@/views/accounting/billing/invoice/InvoiceDetails.vue"),
    meta: {
      pageTitle: "Detalls de la factura",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Factures" },
        { text: "Factura", active: true },
      ],
    },
  },
  //    - CONFIG: global ledger accounts
  {
    path: "/accounting/global-ledger-accounts",
    name: "foravila-accounting-global-ledger-accounts",
    component: () =>
      import(
        "@/views/accounting/configuration/global-ledger-accounts/GlobalLedgerAccounts.vue"
      ),
    meta: {
      pageTitle: "Comptes comptables generals",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Configuració" },
        { text: "Comptes comptables generals", active: true },
      ],
    },
  },
  //    - CONFIG: global ledger accounts
  {
    path: "/accounting/accommodations-ledger-accounts",
    name: "foravila-accounting-accommodations-ledger-accounts",
    component: () =>
      import(
        "@/views/accounting/configuration/accommodations-ledger-accounts/AccommodationsLedgerAccounts.vue"
      ),
    meta: {
      pageTitle: "Comptes comptables d'allotjaments",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Configuració" },
        { text: "Comptes comptables d'allotjaments", active: true },
      ],
    },
  },
  //    - CONFIG: invoice numberings list
  {
    path: "/accounting/invoice-numberings",
    name: "foravila-accounting-invoice-numberings-list",
    component: () =>
      import(
        "@/views/accounting/configuration/invoice-numberings/InvoiceNumberingsList.vue"
      ),
    meta: {
      pageTitle: "Llistat de sèries de factura",
      breadcrumb: [
        { text: "Comptabilitat" },
        { text: "Configuració" },
        { text: "Sèries de factures", active: true },
      ],
    },
  },
];
