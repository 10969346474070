import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    cities: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    cities(state) {
      return state.cities;
    },
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CITIES(state, payload) {
      state.cities = payload;
    },
    ADD_CITY(state, city) {
      state.cities.push(city);
    },
    UPDATE_CITY(state, updatedCity) {
      const cityIndex = state.cities.findIndex(
        (city) => city.uuid === updatedCity.uuid
      );
      state.cities.splice(cityIndex, 1, updatedCity);
    },
    REMOVE_CITY(state, cityUuid) {
      const cityIndex = state.cities.findIndex(
        (city) => city.uuid === cityUuid
      );
      state.cities.splice(cityIndex, 1);
    },
  },
  actions: {
    fetchCities({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);

        let url = "/cities?";
        if ("pagination" in payload) url += `pagination=${payload.pagination}`;

        axios
          .get(url)
          .then((response) => {
            commit("SET_CITIES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    addCity({ commit }, city) {
      return new Promise((resolve, reject) => {
        axios
          .post("/cities", city)
          .then((response) => {
            if (response.status === 201) {
              commit("ADD_CITY", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one."
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    updateCity({ commit }, city) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/cities/${city.uuid}`, city, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_CITY", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one."
                )
              );
            }
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
    deleteCity({ commit }, cityUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/cities/${cityUuid}`)
          .then((response) => {
            commit("REMOVE_CITY", cityUuid);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // TODO: Log error in Sentry
          });
      });
    },
  },
};
