export default class BasicAuth {
  // Will be used by this service for making API calls
  axiosIns = null;

  localStorageIns = null;

  constructor(axiosIns, localStorage) {
    this.axiosIns = axiosIns;
    this.localStorageIns = localStorage;
  }

  login(...args) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post("/login", ...args)
        .then((response) => {
          if (response.status !== 204) {
            // Login error
            reject(new Error({ error: "Invalids credentials." }));
            return;
          }
          // Login success
          // ? The user url comes in the location header of the login response
          this.fetchUser(response.headers.location)
            .then((fetchUserResponse) => {
              const user = this.setUser(fetchUserResponse.data);
              resolve(user);
            })
            .catch((fetchUserError) => reject(fetchUserError));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  logout() {
    return this.axiosIns.post("/logout");
  }

  fetchUser(userUrl) {
    return this.axiosIns.get(userUrl);
  }

  setUser(user) {
    const newUser = { ...user };

    // Check the role and add the ability acording to it
    if (["ROLE_SUPER_ADMIN", "ROLE_ADMIN"].includes(user.role)) {
      newUser.ability = [
        {
          action: "manage",
          subject: "all",
        },
      ];
    } else if (user.role === "ROLE_MANAGER") {
      // TODO: update the ability for manager role
      newUser.ability = [
        {
          action: "manage",
          subject: "all",
        },
      ];
    }

    this.localStorageIns.setItem(
      "foravilaAdminUserData",
      JSON.stringify(newUser)
    );

    return newUser;
  }

  removeUser() {
    this.localStorageIns.removeItem("foravilaAdminUserData");
  }

  checkLoggedIn() {
    return new Promise((resolve) => {
      this.axiosIns
        .get("/me")
        .then((response) => {
          if (response.status === 200) resolve(true);
          else resolve(false);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }
}
