import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    accommodations: [],
    emptyAccommodations: [],
    count: 0,
    emptyCount: 0,
    itemsPerPage: 20,
    firstPage: 1,
    lastPage: 1,
    previousPage: null,
    nextPage: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    accommodations(state) {
      return state.accommodations;
    },
    emptyAccommodations(state) {
      return state.emptyAccommodations;
    },
    availableAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => accommodation.active
      );
    },
    unavailableAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => !accommodation.active
      );
    },
    count(state) {
      return state.count;
    },
    emptyCount(state) {
      return state.emptyCount;
    },
    itemsPerPage(state) {
      return state.itemsPerPage;
    },
    firstPage(state) {
      return state.firstPage;
    },
    lastPage(state) {
      return state.lastPage;
    },
    previousPage(state) {
      return state.previousPage;
    },
    nextPage(state) {
      return state.nextPage;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.accommodations = [];
      state.emptyAccommodations = [];
      state.count = 0;
      state.emptyCount = 0;
      state.itemsPerPage = 20;
      state.firstPage = 1;
      state.lastPage = 1;
      state.previousPage = null;
      state.nextPage = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_ACCOMMODATIONS(state, payload) {
      state.accommodations = payload;
    },
    SET_EMPTY_ACCOMMODATIONS(state, payload) {
      state.emptyAccommodations = payload;
    },
    SET_COUNT(state, payload) {
      state.count = payload;
    },
    SET_EMPTY_COUNT(state, payload) {
      state.emptyCount = payload;
    },
    SET_ITEMS_PER_PAGE(state, payload) {
      state.itemsPerPage = payload;
    },
    SET_FIRST_PAGE(state, payload) {
      state.firstPage = payload;
    },
    SET_LAST_PAGE(state, payload) {
      state.lastPage = payload;
    },
    SET_PREVIOUS_PAGE(state, payload) {
      state.previousPage = payload;
    },
    SET_NEXT_PAGE(state, payload) {
      state.nextPage = payload;
    },
    SORT_ACCOMMODATIONS_BY_NAME(state, order) {
      if (!order) return;
      switch (order.toUpperCase()) {
        case "DESC":
          state.accommodations.sort((a, b) => {
            if (a.name > b.name) return -1;
            if (b.name > a.name) return 1;
            return 0;
          });
          break;
        default:
          // ASC
          state.accommodations.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return -1;
            return 0;
          });
      }
    },
    SORT_ACCOMMODATIONS_BY_POST_DATE(state, order) {
      if (!order) return;
      switch (order.toUpperCase()) {
        case "DESC":
          state.accommodations.sort((a, b) => {
            if (new Date(a.createdAt) > new Date(b.createdAt)) return -1;
            if (new Date(b.createdAt) > new Date(a.createdAt)) return 1;
            return 0;
          });
          break;
        default:
          // ASC
          state.accommodations.sort((a, b) => {
            if (new Date(a.createdAt) > new Date(b.createdAt)) return 1;
            if (new Date(b.createdAt) > new Date(a.createdAt)) return -1;
            return 0;
          });
      }
    },
    UPDATE_ACCOMMODATION(state, accommodation) {
      if (state.accommodations.length > 0) {
        const accommodationIndex = state.accommodations.findIndex(
          (a) => a.uuid === accommodation.uuid
        );
        state.accommodations.splice(accommodationIndex, 1, accommodation);
      }
    },
    ADD_ACCOMMODATION(state, accommodation) {
      state.accommodations.push(accommodation);
    },
    REMOVE_ACCOMMODATION(state, accommodationUuid) {
      const accommodationIndex = state.accommodations.findIndex(
        (a) => a.uuid === accommodationUuid
      );
      state.accommodations.splice(accommodationIndex, 1);
    },
    UPDATE_ACCOMMODATION_LOCATION(state, payload) {
      if (state.accommodations.length > 0) {
        const accommodationIndex = state.accommodations.findIndex(
          (a) => a.uuid === payload.accommodation.uuid
        );
        state.accommodations[accommodationIndex].location = payload.location;
      }
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchAccommodations(
      { commit },
      payload = { pagination: false, sort: "asc" }
    ) {
      commit("SET_LOADING", true);

      let url = "/accommodations";

      // Set url parameters
      const urlParams = [];
      if ("pagination" in payload) {
        urlParams.push(`pagination=${payload.pagination}`);
      }
      if ("itemsPerPage" in payload) {
        urlParams.push(`itemsPerPage=${payload.itemsPerPage || 10}`);
      }
      if ("page" in payload) {
        urlParams.push(`page=${payload.page || 1}`);
      }
      if ("name" in payload) {
        urlParams.push(`name=${payload.name}`);
      }
      if ("groups" in payload) {
        urlParams.push(`groups[]=${payload.groups}`);
      }
      if (urlParams.length) {
        url = `${url}?${urlParams.join("&")}`;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_ACCOMMODATIONS", response.data["hydra:member"]);
              if (payload.sort && typeof payload.sort === "string") {
                commit("SORT_ACCOMMODATIONS_BY_NAME", payload.sort);
              }
              resolve(response);
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the accommodations",
                })
              );
            }
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchFilteredAccommodations({ state, commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/filtered-accommodations?";
      if ("pagination" in payload) url += `&pagination=${payload.pagination}`;
      if ("itemsPerPage" in payload)
        url += `&itemsPerPage=${payload.itemsPerPage || state.itemsPerPage}`;
      if ("page" in payload) url += `&page=${payload.page}`;
      if ("searchText" in payload) url += `&searchText=${payload.searchText}`;
      if ("sort" in payload && payload.sort.key && payload.sort.order) {
        url += `&sortKey=${payload.sort.key}&sortOrder=${payload.sort.order}`;
      } else {
        url += "&sortKey=date&sortOrder=desc";
      }

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_ACCOMMODATIONS", response.data["hydra:member"]);
              commit("SET_COUNT", response.data["hydra:totalItems"]);
              commit(
                "SET_FIRST_PAGE",
                response.data["hydra:view"]["hydra:first"]
              );
              commit(
                "SET_LAST_PAGE",
                response.data["hydra:view"]["hydra:last"]
              );
              commit(
                "SET_PREVIOUS_PAGE",
                response.data["hydra:view"]["hydra:previous"]
              );
              commit(
                "SET_NEXT_PAGE",
                response.data["hydra:view"]["hydra:next"]
              );
              resolve(response.data["hydra:member"]);
            } else {
              reject(new Error("Error while fetching the accommodations"));
            }
          })
          .catch((error) => {
            reject(error.response);
            // TODO: log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchFilteredEmptyAccommodations({ state, commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/filtered-empty-accommodations?";
      if ("pagination" in payload) url += `&pagination=${payload.pagination}`;
      if ("itemsPerPage" in payload)
        url += `&itemsPerPage=${payload.itemsPerPage || state.itemsPerPage}`;
      if ("page" in payload) url += `&page=${payload.page}`;
      if ("searchText" in payload) url += `&searchText=${payload.searchText}`;
      if ("sort" in payload && payload.sort.key && payload.sort.order) {
        url += `&sortKey=${payload.sort.key}&sortOrder=${payload.sort.order}`;
      } else {
        url += "&sortKey=date&sortOrder=desc";
      }

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_EMPTY_ACCOMMODATIONS", response.data["hydra:member"]);
              commit("SET_EMPTY_COUNT", response.data["hydra:totalItems"]);
              resolve(response.data["hydra:member"]);
            } else {
              reject(
                new Error("Error while fetching the empty accommodations")
              );
            }
          })
          .catch((error) => {
            reject(error.response);
            // TODO: log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
  },
};
