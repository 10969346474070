import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    transfers: [],
    transfer: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    transfers(state) {
      return state.transfers;
    },
    transfer(state) {
      return state.transfer;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.transfers = [];
      state.transfer = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_TRANSFER(state, payload) {
      state.transfer = payload;
    },
    SET_TRANSFERS(state, payload) {
      state.transfers = payload;
    },
    ADD_TRANSFER(state, transfer) {
      if (!transfer) return;
      state.transfers.push(transfer);
    },
    UPDATE_TRANSFER(state, transfer) {
      if (!transfer) return;
      state.transfer = transfer;
      if (!state.transfers.length) return;
      const transferIndex = state.transfers.findIndex(
        (item) => item.uuid === transfer.uuid
      );
      if (transferIndex === -1) return;
      state.transfers.splice(transferIndex, 1, transfer);
    },
    REMOVE_TRANSFER(state, transferUuid) {
      if (!transferUuid) return;
      const transferIndex = state.transfers.findIndex(
        (item) => item.uuid === transferUuid
      );
      if (transferIndex === -1) return;
      state.transfers.splice(transferIndex, 1);
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchTransfers({ commit }, payload = { pagination: false }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);

        // const today = new Date().toISOString().split("T")[0];
        // let url = `/transfers?pickupDate[after]=${today}`;

        let url = `/transfers?`;
        if ("pagination" in payload) url += `&pagination=${payload.pagination}`;

        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_TRANSFERS", response.data["hydra:member"]);
              resolve(response);
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the transfers",
                })
              );
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchTransfer({ commit }, transferUuid) {
      return new Promise((resolve, reject) => {
        if (!transferUuid) reject(new Error("No transferUuid"));

        commit("SET_LOADING", true);
        axios
          .get(`/transfers/${transferUuid}`)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_TRANSFER", response.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    createTransfer({ commit }, transferData) {
      return new Promise((resolve, reject) => {
        if (!transferData) reject(new Error("No transferData"));

        commit("SET_LOADING", true);
        axios
          .post("/admin_transfer", transferData)
          .then((response) => {
            if (response.status === 201) resolve(response);
            else reject(response);
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    updateTransfer({ commit }, transfer) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        axios
          .patch(`/transfers/${transfer.uuid}`, transfer, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("UPDATE_TRANSFER", response.data);
              resolve(response);
            } else {
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
            }
          })
          .catch((error) => reject(error.response))
          .finally(() => commit("SET_LOADING", false));
      });
    },
    deleteTransfer({ commit }, transferUuid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/transfers/${transferUuid}`)
          .then((response) => {
            if (response.status === 204) {
              commit("REMOVE_TRANSFER", transferUuid);
              resolve(response);
            } else
              reject(
                new Error(
                  "The request was successfull but the response was not the expected"
                )
              );
          })
          .catch(() =>
            reject(
              new Error(
                "An error occurred while trying to delete de accommodation"
              )
            )
          );
      });
    },
  },
};
