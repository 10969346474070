import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loadingAccommodations: false,
    accommodations: [],
  },
  getters: {
    loadingAccommodations(state) {
      return state.loadingAccommodations;
    },
    accommodations(state) {
      return state.accommodations;
    },
    onSaleAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => accommodation.status === "FOR_SALE"
      );
    },
    forRentAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => accommodation.status === "FOR_RENT"
      );
    },
    forRentSaleAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => accommodation.status === "FOR_RENT_SALE"
      );
    },
    soldAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => accommodation.status === "SOLD"
      );
    },
    soldByOthersAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => accommodation.status === "SOLD_BY_OTHERS"
      );
    },
    rentedAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => accommodation.status === "RENTED"
      );
    },
    rentedByOthersAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => accommodation.status === "RENTED_BY_OTHERS"
      );
    },
    disabledAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => accommodation.status === "DISABLED"
      );
    },
    draftAccommodations(state) {
      return state.accommodations.filter(
        (accommodation) => accommodation.status === "DRAFT"
      );
    },
  },
  mutations: {
    RESET(state) {
      state.loadingAccommodations = false;
      state.accommodations = [];
    },
    SET_LOADING_ACCOMMODATIONS(state, payload) {
      state.loadingAccommodations = payload;
    },
    SET_ACCOMMODATIONS(state, payload) {
      state.accommodations = payload;
    },
    SORT_ACCOMMODATIONS_BY_POST_DATE(state, order) {
      if (!order) return;
      switch (order.toUpperCase()) {
        case "DESC":
          state.accommodations.sort((a, b) => {
            if (new Date(a.createdAt) > new Date(b.createdAt)) return -1;
            if (new Date(b.createdAt) > new Date(a.createdAt)) return 1;
            return 0;
          });
          break;
        default:
          // ASC
          state.accommodations.sort((a, b) => {
            if (new Date(a.createdAt) > new Date(b.createdAt)) return 1;
            if (new Date(b.createdAt) > new Date(a.createdAt)) return -1;
            return 0;
          });
      }
    },
    UPDATE_ACCOMMODATION(state, accommodation) {
      if (state.accommodations.length > 0) {
        const accommodationIndex = state.accommodations.findIndex(
          (a) => a.uuid === accommodation.uuid
        );
        state.accommodations.splice(accommodationIndex, 1, accommodation);
      }
    },
    ADD_ACCOMMODATION(state, accommodation) {
      state.accommodations.push(accommodation);
    },
    REMOVE_ACCOMMODATION(state, accommodationUuid) {
      const accommodaitonIndex = state.accommodations.findIndex(
        (a) => a.uuid === accommodationUuid
      );
      state.accommodations.splice(accommodaitonIndex, 1);
    },
    UPDATE_ACCOMMODATION_LOCATION(state, payload) {
      if (state.accommodations.length > 0) {
        const accommodationIndex = state.accommodations.findIndex(
          (a) => a.uuid === payload.accommodation.uuid
        );
        state.accommodations[accommodationIndex].location = payload.location;
      }
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchAccommodations({ commit }, payload = {}) {
      commit("SET_LOADING_ACCOMMODATIONS", true);

      let url = "/real_estate_accommodations?";
      if ("pagination" in payload) url += `pagination=${payload.pagination}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_ACCOMMODATIONS", response.data["hydra:member"]);
              resolve(response);
            } else {
              reject(
                new Error({
                  status: response.status,
                  message:
                    "Error while fetching all the real estate accommodations",
                })
              );
            }
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(() => commit("SET_LOADING_ACCOMMODATIONS", false));
      });
    },
    async getUnusedReferenceNumber() {
      let found = true;
      let reference = null;

      while (found) {
        reference = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
        // eslint-disable-next-line no-await-in-loop
        found = await axios
          .get(`/real_estate_accommodations?reference=${reference}`)
          .then((response) => {
            if (response.data["hydra:totalItems"] > 0) return true;
            return false;
          })
          .catch(() => true);
      }
      return reference.toString();
    },
  },
};
